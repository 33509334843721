import React from "react";
import "./HeroSection.css";
import mindyHello from "../../Images/AdobeStock_224927508 (2).mov";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <video className="background-video" autoPlay loop muted>
        <source src={mindyHello} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="gradient-overlay"></div>
      <div className="content-overlay">
        <h1>Your Gateway to Accessible Healthcare Imaging</h1>
        <p>
          Explore neuro42’s innovative services designed to elevate patient care
          and research possibilities globally.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
