import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav } from "react-bootstrap";
import AdminDashboard from "./AdminDashboard";
import AdminOperatortList from "./AdminOperatorList";

import { FaBars } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { PiUserListThin } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import "./Admin.css";
import InternalFooter from "../Footer/InternalFooter";
import { useNavigate } from "react-router-dom";
import useIdleTimer from "../../useIdleTimer";

function Admin() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedContent, setSelectedContent] = useState("dashboard");
  // const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isOpen, setIsOpen] = useState(false);
  const source = axios.CancelToken.source();
  const navigate = useNavigate();

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/adminPage';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth < 600);
  };

  const getToken = async () => {
    const token = sessionStorage.getItem("admin_token");
    return token;
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await axios.get("/admin/verify_token/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            console.log("");
          }
        } catch (error) {
          if (error.request.status === 401) {
            try {
              const refreshResponse = await axios.post(
                "/admin/refresh/",
                {},
                { withCredentials: true }
              );

              if (refreshResponse.status === 200) {
                sessionStorage.setItem(
                  "admin_token",
                  refreshResponse.data.access_token
                );
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error("Error refreshing token:", error);
            }
          }
        }
      }
      else {
        navigate("/adminPage");
      }
    };

    checkTokenValidity();
    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const handleLogout = async () => {
    await axios.post("/admin/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    navigate("/adminPage/");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [
    { key: "dashboard", label: "Dashboard" },
    { key: "operator-list", label: "Operator List" },
  ];

  return (
    <div className="admin-main">
      <React.Fragment>
        {isMobile ? (
          <Navbar className="mobile-admin-nav">
            <div className="flex-admin-nav">
              <FaBars className="menu-icon" onClick={toggleMenu} />
            </div>
            <Navbar.Collapse className="vertical-admin-nav">
              <Nav className={`navbar-buttons ${isOpen ? "show" : ""}`}>
                {menuItems.map((item) => (
                  <Nav.Link
                    key={item.key}
                    active={selectedContent === item.key}
                    onClick={() => {
                      setSelectedContent(item.key);
                      setIsOpen(false);
                    }}
                  >
                    {item.label}
                  </Nav.Link>
                ))}
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <Navbar className="admin-nav">
            <Nav className="mr-auto navbar-buttons">
              {menuItems.map((item) => (
                <Nav.Link
                  key={item.key}
                  active={selectedContent === item.key}
                  onClick={() => setSelectedContent(item.key)}
                >
                  <div className="nav-item">
                    {item.key === "dashboard" && (
                      <MdOutlineDashboardCustomize />
                    )}
                    {item.key === "operator-list" && <PiUserListThin />}
                    {item.label}
                  </div>
                </Nav.Link>
              ))}
            </Nav>

            <Nav className="navbar-buttons-logout">
              <Nav.Link onClick={handleLogout}>
                <div className="nav-item">
                  <BiLogOutCircle />
                  Logout
                </div>
              </Nav.Link>
            </Nav>
          </Navbar>
        )}
        <div className="admin-content">
          {selectedContent === "dashboard" && <AdminDashboard />}
          {selectedContent === "operator-list" && <AdminOperatortList />}
        </div>
        <InternalFooter />
      </React.Fragment>
    </div>
  );
}

export default Admin;
