import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PatientReimbursement.css";
import useIdleTimer from "../../useIdleTimer";
const PatientReimbursement = ({ patient }) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("Transportation");
  const [comment, setComment] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState({
    amount: "",
    description: "",
    files: "",
  });
  const [errors, setErrors] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const fileInputRef = React.createRef();
  const source = axios.CancelToken.source();

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/patients';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  useEffect(() => {
    fetchSubmittedRequests();
  }, [patient, isLoggedIn]);

  useEffect(() => { }, [submittedRequests]);

  const getToken = () => {
    const token = sessionStorage.getItem("patient_token");
    return token;
  };

  const handleDocumentSelect = (documentName) => {
    setSelectedDocument(documentName);
  };

  const handleDoubleClick = (documentName) => {
    setSelectedDocument(documentName);
    downloadDocument();
  };

  const downloadDocument = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);

    if (selectedDoc) {
      try {
        const response = await axios.get(
          `/patient/download_reimbursement_document/${selectedDoc.file_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.data.type });

        // Create a download link and trigger a click event to start the download
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = selectedDoc.file_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/patient/refresh/", {}, { withCredentials: true }
          );
          if (refreshResponse.status === 200) {
            sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
            await downloadDocument();
          } else {
            console.log("Refresh failed");
            handleLogout();
          }
        } else {
          setError("Error downloading file");
        }
      }
    } else {
      setError("Please select a document");
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      setErrors(
        "Invalid file type. Please upload a PDF, PNG, JPEG, JPG, or SVG file."
      );
      setSelectedFiles(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    if (file.size > 1 * 1024 * 1024) {
      setErrors("File size exceeds 1MB limit.");
      return;
    }

    setSelectedFiles(file);
    setErrors("");
  };

  const handleSubmit = async () => {
    if (!amount || !description || selectedFiles.length === 0) {
      setError({
        amount: !amount ? "Amount is required" : "",
        description: !description ? "Description is required" : "",
        files: selectedFiles.length === 0 ? "File is required" : "",
      });
      return;
    }
    try {
      const token = getToken();
      const formData = new FormData();

      // Append form data
      const amount_value = parseFloat(amount).toFixed(2);
      formData.append("amount", amount_value);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("status", "Submitted");
      formData.append("comment", comment);
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());
      // Append files
      for (const file of selectedFiles) {
        formData.append("files", file);
      }

      const response = await axios.post(
        "/patient/add_reimbursements/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setAmount("");
        setDescription("");
        setCategory("Transportation");
        setComment("");
        setSelectedFiles([]);
        fetchSubmittedRequests();
      } else {
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  const fetchSubmittedRequests = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `/patient/fetch_reimbursements/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const reimbursements = response.data.map((reimbursement) => ({
          ...reimbursement,
          timestamp: new Date(reimbursement.date).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
        }));
        setSubmittedRequests(reimbursements);
        const documentsData = response.data.map((doc) => ({
          file_name: doc.file_name,
          file_id: doc.file_id,
          file_data: doc.file_data,
        }));
        setDocuments(documentsData);
      } else {
        console.error(
          "Failed to fetch reimbursement requests. Status:",
          response.status
        );
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/patient/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
          setIsLoggedIn(true);
          await fetchSubmittedRequests();
        } else {
          console.log("Refresh token failed");
          setIsLoggedIn(false);
          handleLogout();
        }
        setIsLoggedIn(false);
      }
    }
  };

  const handleCancel = () => {
    setAmount("");
    setDescription("");
    setCategory("Transportation");
    setComment("");
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const calculateTotalAmount = () => {
    const totalAmount = submittedRequests.reduce(
      (total, request) => total + parseFloat(request.amount),
      0
    );

    if (isNaN(totalAmount) || typeof totalAmount !== "number") {
      console.error("Total amount is not a valid number:", totalAmount);
      return 0;
    }

    return totalAmount.toFixed(2);
  };

  const calculateTotalAmountDue = () => {
    const totalAmountDue = submittedRequests.reduce(
      (total, request) => total + parseFloat(request.paid_amount),
      0
    );

    if (isNaN(totalAmountDue) || typeof totalAmountDue !== "number") {
      return 0;
    }

    return totalAmountDue.toFixed(2);
  };

  const handleLogout = async () => {
    await axios.post("/patient/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <div className="patient-reimbursement-container-2">
      <div className="reimbursement-container-form">
        <input
          type="text"
          name="to_email"
          value={amount}
          onChange={handleAmountChange}
          placeholder={error.amount ? error.amount : "Amount ($)*"}
          required
          style={{ borderColor: error.amount ? "red" : "" }}
        />

        <input
          id="description"
          type="text"
          value={description}
          onChange={handleDescriptionChange}
          placeholder={error.description ? error.description : "Description*"}
          required
          style={{ borderColor: error.description ? "red" : "" }}
        />

        <textarea
          id="comment"
          value={comment}
          onChange={handleCommentChange}
          placeholder="Additional Comments"
        />

        <select
          id="category"
          name="category"
          value={category}
          onChange={handleCategoryChange}
        >
          <option value="Transportation">Transportation</option>
          <option value="Hotel">Hotel</option>
          <option value="Food">Food</option>
          <option value="Other">Other</option>
        </select>

        <input
          type="file"
          name="new_attachments"
          ref={fileInputRef}
          id="files"
          onChange={handleFileChange}
          required
        />
        {error.files && (
          <span className="reimbursement-error-message">{error.files}</span>
        )}
        {errors && <p className="error">{errors}</p>}
      </div>

      <div className="button-reimbursement">
        <button onClick={handleSubmit} className="submit-reimbursement">
          Submit
        </button>
        <button onClick={handleCancel} className="cancel-reimbursement">
          Cancel
        </button>
      </div>
      {submittedRequests.length > 0 ? (
        <div className="total-reimbursement">
          <div>
            <h3 className="title-reimbursement">Submitted Requests</h3>
          </div>
          <div className="total-2-reimbursement">
            <div className="total-1">
              <h3>Total Amount ($):</h3>
              <h4>${calculateTotalAmount()}</h4>
            </div>
            <div className="total-2">
              <h3>Total Amount Due ($):</h3>
              <h4>
                $
                {(calculateTotalAmount() - calculateTotalAmountDue()).toFixed(
                  2
                )}
              </h4>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3>No previous history</h3>
        </div>
      )}
      {submittedRequests.length > 0 && (
        <ul className="reimbursement-responsive-table">
          <li className="reimbursement-table-header">
            <div className="reimbursement-col reimbursement-col-1">Amount</div>
            <div className="reimbursement-col reimbursement-col-2">
              Description
            </div>
            <div className="reimbursement-col reimbursement-col-3">
              Category
            </div>
            <div className="reimbursement-col reimbursement-col-4">
              Document
            </div>
            <div className="reimbursement-col reimbursement-col-5">Status</div>
            <div className="reimbursement-col reimbursement-col-6">Comment</div>
            <div className="reimbursement-col reimbursement-col-7">
              Timestamp
            </div>
          </li>
          {submittedRequests.map((request) => (
            <li className="reimbursement-table-row">
              <div
                className="reimbursement-col reimbursement-col-1"
                data-label="Amount"
              >
                {request.amount}
              </div>
              <div
                className="reimbursement-col reimbursement-col-2"
                data-label="Description"
              >
                {request.description}
              </div>
              <div
                className="reimbursement-col reimbursement-col-3"
                data-label="Category"
              >
                {request.category}
              </div>
              <div
                className="reimbursement-col reimbursement-col-4"
                data-label="Document"
              >
                <span
                  onClick={() => handleDocumentSelect(request.file_name)}
                  onDoubleClick={() => handleDoubleClick(request.file_name)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {request.file_name}
                </span>
              </div>
              <div
                className="reimbursement-col reimbursement-col-5"
                data-label="Status"
              >
                {request.status}
              </div>
              <div
                className="reimbursement-col reimbursement-col-6"
                data-label="Comment"
              >
                {request.comment}
              </div>
              <div
                className="reimbursement-col reimbursement-col-7"
                data-label="Timestamp"
              >
                {request.timestamp}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PatientReimbursement;
