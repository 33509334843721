export default{
    subject: 'Confirmation – neuro42 MR Study Session',
    message: `Hello Mr./ Ms. XYZ, 

Thank you for scheduling an MR scan session with neuro42.  

Your session has been confirmed for [Date and Time].  

Please try to arrive 10-15 minutes prior to the scheduled time to complete paperwork. 

Here are a few details and hyperlinks that might assist you with directions to reach the neuro42 office. Attachments of the map are also provided at the end of this email. 

  Our office address is 2 Bryant St, San Francisco, California 94105. https://goo.gl/maps/VptRkRgpd9unyAZj9 

  In case you are driving, you could park at Bayside Lot #26, which is across the street from the office. The address is 1 Bryant St, San Francisco, CA 94110. https://goo.gl/maps/ueXiMKWxdrwRmKZf7.  

  The parking lot is at the intersection of the Embarcadero and Bryant St.  

  If you’re coming from the Bay Bridge, use the left lane to take exit 2B for Harrison St toward Embarcadero. Turn right on to Harrison St. Turn right again on to Main St. Subsequently, turn right onto Bryant St, and the parking lot entrance will be on your left.  

  Please call us at (628) 231-8123 before your arrival. A company representative will help you with parking payment and entering the building. 

If you have questions or concerns before your session, kindly reply to this email and we will be more than happy to help.  

To reschedule or cancel your appointment before the scheduled time, please call us at (628) 231-8123 or reply to this email. 

We are looking forward to seeing you on [Date and Time].  

Best regards, 

XYZ 
Study Coordinator 
neuro42, Inc.  
(628) 231-8123 | study@neuro42.com 
www.neuro42.ai 

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto. `,
      attachments: [{
          filename: 'image1.png',
          path: '../emailTemplateImages/image1.png',
          cid: 'image1', // Content ID for referencing the image in the template
        },
    
        {
          filename: 'image2.png',
          path: '../emailTemplateImages/image2.png',
          cid: 'image2', // Content ID for referencing the image in the template
        },]
}