import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./OperatorPatientList.css";
import OperatorPatientDetails from "./OperatorPatientDetails";
import Pagination from "./Pagination";
import "./LoadingSpinner.css";
import useIdleTimer from "../../useIdleTimer";
import { useNavigate } from "react-router-dom";

import { IoPersonAddSharp } from "react-icons/io5";
import {
  FcOk,
  FcExpired,
  FcFolder,
  FcHighPriority,
  FcTodoList,
  FcCancel,
  FcMediumPriority,
  FcClock,
} from "react-icons/fc";
function PatientListEntry({ patient, selected, onSelect, onDetailsClick }) {
  const { _id, first_name, last_name, email, status, patient_id } = patient;

  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/physicians";
  };

  useIdleTimer(handleIdle, 1.2e6); // 20 minutes timeout

  const handleClick = () => {
    onSelect(_id);
    onDetailsClick(_id);
  };
  const renderStatus = () => {
    switch (status) {
      case "Accepted":
        return <FcOk />;
      case "Rejected":
        return <FcCancel />;
      case "On Hold":
        return <FcExpired />;
      case "Withdrawn":
        return <FcHighPriority />;
      case "Other":
        return <FcMediumPriority />;
      case "Scanned":
        return <FcFolder />;
      case "Screened":
        return <FcTodoList />;
      case "Pending Invite":
        return <FcClock />;
      default:
        return <strong>{status}</strong>;
    }
  };

  return (
    <li
      className={selected ? "table-row selected" : "table-row"}
      onClick={handleClick}
    >
      <div className="col col-0" data-label="Patient Id">
        {patient_id}
      </div>
      <div className="col col-1" data-label="First Name">
        {first_name}
      </div>
      <div className="col col-2" data-label="Last Name">
        {last_name}
      </div>
      <div className="col col-3" data-label="Email Address">
        {email}
      </div>
      <div className="col col-4" data-label="Status">
        <div>
          {status} {renderStatus()}
        </div>
      </div>
    </li>
  );
}

function OperatorPatientList() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    email: "",
    res: "",
    first_name: "",
    last_name: "",
  });
  const [newPatient, setNewPatient] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleGetPatients = async () => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      console.log("\n\n project \n\n", project);
      if (!token) {
        navigate("/physicians");
      }

      const response = await axios.get("/operator/get_all_patients/", {
        headers: {
          Authorization: `Bearer ${token}`,
          project: project,
        },
        params: {
          page: currentPage, // Use currentPage to initialize originalPage
          page_size: pageSize,
          searchQuery: searchQuery,
        },
      });

      if (response.status === 200) {
        setPatients(response.data.patients);
        setTotalPages(response.data.total_pages);
      } else {
        setError({
          res: "Failed to fetch patients. Status: " + response.status,
        });
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/",
          {},
          { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem(
            "operator_token",
            refreshResponse.data.access_token
          );
          await handleGetPatients();
        } else {
          console.error("Refresh token failed", error);
          handleLogout();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPatients();
  }, [currentPage, pageSize, searchQuery]);

  const handleSearch = (value) => {
    if (value.trim() === "") {
      setSearchQuery(null);
      handleGetPatients();
    } else {
      setSearchQuery(value.trim());
    }
    handleGetPatients();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    handleGetPatients();
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleDetailsClick = (_id) => {
    const selectedPatient = patients.find((patient) => patient._id === _id);
    setSelectedPatientDetails(selectedPatient);
  };

  const handleCloseDetails = () => {
    setSelectedPatientDetails(null);
    handleGetPatients();
  };

  const handleSendInvitationEmail = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    try {
      const token = getToken();
      const response = await axios.post(
        `/operator/send_invitation_email/${newPatient.email}`,
        newPatient,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setError("");
        setNewPatient({
          first_name: "",
          last_name: "",
          email: "",
        });

        const confirmBox = window.confirm(
          "Sent invitation email to patient successfully!"
        );
        if (confirmBox === true) {
          closePopup();
        }

        handleGetPatients();
      } else {
        setError({ res: "Failed to add patient. Status: " + response.status });
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/",
          {},
          { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem(
            "operator_token",
            refreshResponse.data.access_token
          );
          await handleSendInvitationEmail(e);
        } else {
          console.error("Refresh token failed", error);
          handleLogout();
        }
      }
      console.error("Error adding patient:", error);
    }
  };

  const closePopup = () => {
    handleHideAddForm();
  };

  const handleShowAddForm = () => {
    setShowAddForm(true);
    setIsClicked(false);
  };

  const handleHideAddForm = () => {
    setShowAddForm(false);
  };

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    localStorage.clear();
    sessionStorage.clear();
  };
  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex =
      /^[^\s@]+@(?!example\.com|email\.com|domain\.com|test\.com)[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateName = (name) => {
    // Regular expression for name validation
    const regex = /^[a-zA-Z]+$/;
    return regex.test(name);
  };

  const handleFirstNameChange = (e) => {
    const first_name = e.target.value;
    setNewPatient({ ...newPatient, first_name });
    if (!validateName(first_name)) {
      setError({
        ...error,
        first_name: "First name must contain only letters",
      });
    } else {
      setError({ ...error, first_name: "" });
    }
  };

  const handleLastNameChange = (e) => {
    const last_name = e.target.value;
    setNewPatient({ ...newPatient, last_name });
    if (!validateName(last_name)) {
      setError({ ...error, last_name: "Last name must contain only letters" });
    } else {
      setError({ ...error, last_name: "" });
    }
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewPatient({ ...newPatient, email });
    if (!validateEmail(email)) {
      setError({ ...error, email: "Invalid email format" });
    } else {
      setError({ ...error, email: "" });
    }
  };

  return (
    <div className="patient-list">
      {showAddForm ? (
        <div className="add-patient-form">
          <h2>Enter contact information for new patient</h2>
          <form
            onSubmit={handleSendInvitationEmail}
            className="patient-list-add-patient-form"
          >
            <input
              type="text"
              placeholder="First Name*"
              value={newPatient.first_name}
              onChange={handleFirstNameChange}
              required
            />
            {error.first_name && (
              <p className="error-message">{error.first_name}</p>
            )}
            <input
              type="text"
              placeholder="Last Name*"
              value={newPatient.last_name}
              onChange={handleLastNameChange}
              required
            />
            {error.last_name && (
              <p className="error-message">{error.last_name}</p>
            )}
            <input
              type="text"
              placeholder={error.email ? error.email : "Email*"}
              value={newPatient.email}
              onChange={handleEmailChange}
              required
            />
            {error.email && <p className="error-message">{error.email}</p>}
            {/* Button to add a new patient */}
            <div className="button-direction-inline">
              <button
                className="patient-list-add-button"
                onClick={handleSendInvitationEmail}
                disabled={isClicked}
              >
                {" "}
                Invite{" "}
              </button>
              <button
                className="patient-list-cancel-button"
                onClick={handleHideAddForm}
              >
                Back to Patient List
              </button>
            </div>
          </form>
        </div>
      ) : (
        <React.Fragment>
          {selectedPatientDetails ? (
            <OperatorPatientDetails
              patient={selectedPatientDetails}
              onClose={handleCloseDetails}
            />
          ) : (
            <div className="left-align">
              <div className="patient-list-search-container">
                <div className="input-group">
                  <input
                    type="search"
                    placeholder="Search Patients"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    onInput={(e) => handleSearch(e.target.value)}
                    className="form-control"
                  />
                </div>
                <button
                  id="search-button"
                  type="button"
                  className="add-user"
                  onClick={handleShowAddForm}
                >
                  <IoPersonAddSharp />
                </button>
                {/* {error && <p className="error-message">{error}</p>} */}
              </div>
              <div className="patient-list-responsive-table">
                {loading && (
                  <div className="loading-spinner-overlay">
                    {/* <span className="loading-text">Loading Documents</span> */}
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div className="patient-list-container">
                  <ul className="patient-list-responsive-table">
                    <li className="patient-list-table-header">
                      <div className="col col-0">Patient Id</div>
                      <div className="col col-1">First Name</div>
                      <div className="col col-2">Last Name</div>
                      <div className="col col-3">Email Address</div>
                      <div className="col col-4">Status</div>
                    </li>
                    {patients.map((patient) => (
                      <PatientListEntry
                        key={patient.email}
                        patient={patient}
                        selected={
                          selectedPatient && selectedPatient._id === patient._id
                        }
                        onSelect={setSelectedPatient}
                        onDetailsClick={handleDetailsClick}
                      />
                    ))}
                  </ul>
                </div>
                <div className="pagination-container">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                  <select
                    value={pageSize}
                    onChange={(e) =>
                      handlePageSizeChange(Number(e.target.value))
                    }
                  >
                    <option value={10}>10 per page</option>
                    <option value={25}>25 per page</option>
                    <option value={50}>50 per page</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default OperatorPatientList;
