import React from "react";
import "./NavbarNew.css";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";

const NavbarAuthForm = () => {
    return (
        <nav className="navbar-landing">
            <Link to="/">
                <div className="navbar-landing-logo">
                    <img src={logo} alt="Neuro42 Logo" className="logo-image" />
                </div>
            </Link>
        </nav>
    );
};

export default NavbarAuthForm;
