import React, { useState, useEffect } from "react";
import OperatorPatientProfile from "./OperatorPatientProfile";
import OperatorPatientScan from "./OperatorPatientScan";
import OperatorPatientFiles from "./OperatorPatientFiles";
import OperatorPatientAppointment from "./OperatorPatientAppointment";
import OperatorPatientEmails from "./OperatorPatientEmails";
import OperatorPatientFeedback from "./OperatorPatientFeedback";
import OperatorPatientReimbursement from "./OperatorPatientReimbursement";
import { PiBackspaceBold } from "react-icons/pi";
import "./OperatorPatientDetails.css";
import useIdleTimer from "../../useIdleTimer";

function OperatorPatientDetails({ patient, onClose }) {
  const [activeSection, setActiveSection] = useState("profile");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1150);
  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth < 1150);
  };
  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout
  useEffect(() => {
    try {
      updateWindowDimensions();
      window.addEventListener("resize", updateWindowDimensions);
    } catch (error) {
      console.error("Error in window dimensions(Patient Details) : ", error);
    }
  });
  const sections = [
    { id: "profile", label: "Profile" },
    { id: "scan", label: "Scan" },
    { id: "file", label: "File" },
    { id: "appointment", label: "Appointment" },
    { id: "email", label: "Email" },
    { id: "feedback", label: "Feedback" },
    { id: "reimbursement", label: "Reimbursement" },
  ];

  return (
    <div className="patient-details">
      {isMobile ? (
        <div>
          <select
            value={activeSection}
            onChange={(e) => setActiveSection(e.target.value)}
            className="select-patient-list-menu"
          >
            {sections.map((section) => (
              <option key={section.id} value={section.id}>
                {section.label}
              </option>
            ))}
            <option className="close-button" onClick={onClose}>
              Back to Patient Table
            </option>
          </select>
        </div>
      ) : (
        <div className="patient-list-menu">
          {sections.map((section) => (
            <button
              key={section.id}
              className={activeSection === section.id ? "active" : ""}
              onClick={() => setActiveSection(section.id)}
            >
              {section.label}
            </button>
          ))}
          <button
            className="close-button"
            onClick={onClose}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="arrow-icon">
              <PiBackspaceBold
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>{" "}
            Back to Patient Table
          </button>
        </div>
      )}
      <div className="section-content">
        {/* Render different sections based on the activeSection value */}
        {activeSection === "profile" && (
          <OperatorPatientProfile patient={patient} />
        )}
        {activeSection === "scan" && <OperatorPatientScan patient={patient} />}
        {activeSection === "file" && <OperatorPatientFiles patient={patient} />}
        {activeSection === "appointment" && (
          <OperatorPatientAppointment patient={patient} />
        )}
        {activeSection === "email" && (
          <OperatorPatientEmails patient={patient} />
        )}
        {activeSection === "feedback" && (
          <OperatorPatientFeedback patient={patient} />
        )}
        {activeSection === "reimbursement" && (
          <OperatorPatientReimbursement patient={patient} />
        )}
      </div>
    </div>
  );
}

export default OperatorPatientDetails;
