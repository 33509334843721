import React, { useState } from "react";
import axios from "axios";
import useIdleTimer from "../../useIdleTimer";

const OperatorResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  const handleResetPassword = async () => {
    try {
      setIsResetting(true);

      // Make an API call to request a password reset
      const response = await axios.post(`/operator/reset_password/${email}`);

      if (response.status === 200) {
        setResetSuccess(true);
        setResetPasswordError("");
      } else if (response.status === 400 && response.data.error) {
        // Check if the 'error' field is present in the response data
        console.error("Error resetting the password: " + response);

        // Handle the case where the email address does not exist
        setResetSuccess(false);
        setResetPasswordError(
          "Email address does not exist. Please enter a valid email."
        );
      } else {
        // Handle other error cases
        setResetSuccess(false);
        setResetPasswordError(
          "Failed to initiate password reset. Please try again."
        );
      }
    } catch (error) {
      console.error("Error initiating password reset:", error);
      setResetSuccess(false);
      setResetPasswordError(
        "An error occurred while initiating the password reset."
      );
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleResetPassword} disabled={isResetting}>
        {isResetting ? "Sending Email..." : "Send Reset Email"}
      </button>
      {resetPasswordError && <p>{resetPasswordError}</p>}
      {resetSuccess && (
        <p>Password reset email sent successfully. Check your inbox.</p>
      )}
    </div>
  );
};

export default OperatorResetPassword;
