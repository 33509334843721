import React from "react";
import "./OperatorModal.css"

const OperatorModal = ({ show, handleClose, message }) => {
    if (!show) {
        return null;
    }

    return (<div className="modal-backdrop" onClick={(e) => e.stopPropagation}>
        <div className="modal" onClick={(e) => e.stopPropagation}>
            <h2>Error!</h2>
            <p>{message}</p>
            <button onClick={handleClose}>Close</button>
        </div>
    </div>)
}

export default OperatorModal