import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "./OperatorPatientEmails.css";
import emailTemplate1 from "./emailTemplates/template1";
import emailTemplate2 from "./emailTemplates/template2";
import emailTemplate3 from "./emailTemplates/template3";
import emailTemplate4 from "./emailTemplates/template4";
import emailTemplate5 from "./emailTemplates/template5";
import emailTemplate6 from "./emailTemplates/template6";
import emailTemplate7 from "./emailTemplates/template7";
import emailTemplate8 from "./emailTemplates/template8";
import "./LoadingSpinner.css"
import useIdleTimer from "../../useIdleTimer"

export const emailTemplates = [
  emailTemplate1,
  emailTemplate2,
  emailTemplate3,
  emailTemplate4,
  emailTemplate5,
  emailTemplate6,
  emailTemplate7,
  emailTemplate8,
];

const OperatorPatientEmails = ({ patient }) => {
  const [emailData, setEmailData] = useState({
    to_email: [patient.email],
    cc: [],
    bcc: [],
    subject: "",
    message: "",
    attachments: [],
    new_attachments: [],
  });
  const [previousEmails, setPreviousEmails] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [error, setError] = useState({
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  useEffect(() => {
    try {
      fetchPreviousEmails();
    } catch (error) {
      console.error("Error in fetching the emails for operator : ", error);
    }
  }, [patient]);

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "to_email" || name === "cc" || name === "bcc") {
      setEmailData({ ...emailData, [name]: value.split(",") });
    } else if (name === "template") {
      const selectedTemplate = emailTemplates[value];
      const attachments = selectedTemplate.attachments || [];
      setEmailData({
        ...emailData,
        subject: selectedTemplate.subject,
        message: selectedTemplate.message,
        attachments: attachments.map((attachment) => ({
          ...attachment,
          path: `cid:${attachment.cid}`,
        })),
      });
    } else if (name === "new_attachments") {
      const file = e.target.files;
      if (!file) return;

      const maxSize = 2 * 1024 * 1024;
      let current_size = 0;
      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/svg+xml",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      for (let i = 0; i < file.length; i++) {
        if (!allowedTypes.includes(file[i].type)) {
          setErrors(
            "Invalid file type. Please upload a PDF, PNG, JPEG, or SVG file."
          );
          setSelectedFiles(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          return;
        }
        if (file[i].size > maxSize) {
          setErrors("File size exceeds 2MB limit.");
          return;
        }
        current_size += file[i].size;
      }
      if (current_size > 20 * 1024 * 1024) {
        setErrors("Total file size exceeds 20MB limit.");
        return;
      }

      setSelectedFiles(file);
      setErrors("");
    } else {
      setEmailData({ ...emailData, [name]: value });
    }
  };

  const sendEmail = async () => {
    if (!emailData.subject.trim() || !emailData.message.trim()) {
      setError({
        subject: !emailData.subject.trim() ? "Subject is required" : "",
        message: !emailData.message.trim() ? "Message is required" : "",
      });
      return;
    }

    try {
      const token = getToken();
      const formData = new FormData();
      formData.append("to_email", emailData.to_email);
      formData.append("cc", emailData.cc);
      formData.append("bcc", emailData.bcc);
      formData.append("subject", emailData.subject);
      formData.append("message", emailData.message);
      formData.append("sent_date", new Date().toISOString());
      formData.append("attachments", JSON.stringify(emailData.attachments));

      if (selectedFiles) {
        console.log("Here is the selectedFiles", selectedFiles);
        console.log("Here is the selectedFiles length", selectedFiles.length);
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("new_attachments", selectedFiles[i]);
        }
      }

      console.log("formData", formData)

      const response = await axios.post(
        `/operator/send_email/${patient.email}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        fetchPreviousEmails();
        setSelectedFiles(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        console.error("Failed to send email. Status:", response);
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          await sendEmail();
        } else {
          console.error("Refresh failed, logging out", error);
          handleLogout();
        }
      }
    }
  };

  const fetchPreviousEmails = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `/operator/retrieve_email_info/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const emailsWithTimestamp = response.data.map((email) => {
          return {
            ...email,
            sent_date: new Date(email.sent_date).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
          };
        });
        setPreviousEmails(emailsWithTimestamp);
      } else {
        console.error(
          "Failed to fetch previous emails. Status:",
          response.status
        );
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          await fetchPreviousEmails();
        } else {
          console.error("Refresh failed, logging out", error);
          handleLogout();
        }
      }
    }
    finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateEmailList = (emailList) => {
      const emails = emailList.split(",");
      for (let email of emails) {
        if (
          !email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
          email.trim() !== ""
        ) {
          return false;
        }
      }
      return true;
    };

    const isValidToEmail = validateEmailList(emailData.to_email.join(","));
    const isValidCc = validateEmailList(emailData.cc.join(","));

    if (!isValidToEmail) {
      setError({
        ...error,
        to_email: "Invalid email(s) in To field",
      });
      return;
    }

    if (!isValidCc) {
      setError({
        ...error,
        cc: "Invalid email(s) in CC field",
      });
      return;
    }

    if (!emailData.subject.trim() || !emailData.message.trim()) {
      setError({
        ...error,
        subject: !emailData.subject.trim() ? "Subject is required" : "",
        message: !emailData.message.trim() ? "Message is required" : "",
        to_email: "",
        cc: "",
      });
      return;
    }

    sendEmail();
  };

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className="operator-email-form">
        <div className="email-fields">
          <select
            name="template"
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "none") {
                setEmailData({
                  ...emailData,
                  subject: "",
                  message: "",
                  attachments: [],
                });
              } else {
                const selectedTemplate = emailTemplates[selectedValue];
                setEmailData({
                  ...emailData,
                  subject: selectedTemplate.subject,
                  message: selectedTemplate.message,
                  attachments: selectedTemplate.attachments || [],
                });
              }
            }}
            value={""}
          >
            <option value="" disabled>
              Select a Template
            </option>
            <option value="none">None</option>
            {emailTemplates.map((template, index) => (
              <option key={index} value={index}>
                {template.subject}
              </option>
            ))}
          </select>

          <input
            type="text"
            name="to_email"
            value={emailData.to_email.join(",")}
            onChange={handleInputChange}
            placeholder="Recipient Emails (comma-separated)"
            style={{ borderColor: error.to_email ? "red" : "" }}
          />
          {error.to_email && (
            <p
              className={styles.errorMessage}
              style={{ color: error.to_email ? "red" : "" }}
            >
              {error.to_email}
            </p>
          )}

          <input
            type="text"
            name="cc"
            value={emailData.cc.join(",")}
            onChange={handleInputChange}
            placeholder="CC (comma-separated)"
            className={error.cc ? styles.errorInput : ""}
            style={{ borderColor: error.cc ? "red" : "" }}
          />
          {error.cc && (
            <p
              className={styles.errorMessage}
              style={{ color: error.cc ? "red" : "" }}
            >
              {error.cc}
            </p>
          )}

          <input
            type="text"
            name="subject"
            value={emailData.subject}
            onChange={handleInputChange}
            placeholder={error.subject ? error.subject : "Subject*"}
            style={{ borderColor: error.subject ? "red" : "" }}
          />

          <textarea
            name="message"
            value={emailData.message}
            onChange={handleInputChange}
            placeholder={error.message ? error.message : "Message*"}
            style={{ borderColor: error.message ? "red" : "" }}
          />

          <input
            type="file"
            name="new_attachments"
            ref={fileInputRef}
            onChange={handleInputChange}
            multiple
          />
          {errors && <p className="error">{errors}</p>}
          {emailData.attachments.length > 0 && (
            <div>
              <h4>Attachments</h4>
              {emailData.attachments.map((attachment) => (
                <div key={attachment.filename}>
                  <img src={attachment.path} alt={attachment.filename} />
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="email-send" type="submit">
          Send Email
        </button>
      </form>

      <div className="responsive-table">
        {loading && (
          <div className="loading-spinner-overlay">
            {/* <span className="loading-text">Loading Documents</span> */}
            <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {previousEmails.length > 0 ? (
          <ul className="responsive-table">
            <li className="email-table-header">
              <div className="col col-1">Subject</div>
              <div className="col col-2">Date Sent</div>
              <div className="col col-3">To Email</div>
              <div className="col col-4">Cc</div>
            </li>
            {previousEmails.map((email, index) => (
              <li className={"table-row"} key={index}>
                <div className="col col-1" data-label="Subject">
                  {email.subject}
                </div>
                <div className="col col-2" data-label="Date Sent">
                  {email.sent_date}
                </div>
                <div className="col col-3" data-label="To Email">
                  {email.to_email}
                </div>
                <div className="col col-4" data-label="Cc">
                  {email.cc.length > 0 ? email.cc : "N/A"}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-prev-email">No previous emails</p>
        )}
      </div>
    </div>
  );
};

export default OperatorPatientEmails;
