import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminOperatorList.styles.css";
import { FiUserPlus } from "react-icons/fi";
import { RiDeleteBin2Fill } from "react-icons/ri";
import Pagination from "../Operator/Pagination";
import useIdleTimer from "../../useIdleTimer";

function OperatorListEntry({ operator, onSelect, selected, onDeleteClick }) {
  const { _id, first_name, last_name, email } = operator;

  const handleClick = () => {
    onSelect(_id);
  };

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/adminPage';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const handleDelete = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${email}? `
    );
    if (confirmDelete) {
      onDeleteClick(email);
    }
  };

  return (
    <li
      className={
        selected
          ? "operator-list-table-row selected"
          : "operator-list-table-row"
      }
      onClick={handleClick}
    >
      <div className="col col-1" data-label="First Name">
        {first_name}
      </div>
      <div className="col col-2" data-label="Last Name">
        {last_name}
      </div>
      <div className="col col-3" data-label="Email Address">
        {email}
      </div>
      <div className="col col-4" data-label="Actions">
        <button className="operator-delete-button" onClick={handleDelete}>
          <RiDeleteBin2Fill />
        </button>
      </div>
    </li>
  );
}

function AdminOperatorList() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [newOperatorEmail, setNewOperatorEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    handleGetOperators();
  }, [currentPage, pageSize, searchQuery]);

  const getToken = () => {
    const token = sessionStorage.getItem("admin_token");
    return token;
  };

  const handleGetOperators = async () => {
    try {
      const token = getToken();

      const response = await axios.get("/admin/get_all_operators/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: currentPage,
          page_size: pageSize,
          searchQuery: searchQuery,
        },
      });

      if (response.status === 200) {
        setOperators(response.data.operators);
        setTotalPages(response.data.total_pages);
      } else {
        setError("Failed to fetch operators. Status: " + response.status);
      }
    } catch (error) {
      if (error.request.status === 401) {
        try {
          const refreshResponse = await axios.post("/admin/refresh/", {
          }, { withCredentials: true });

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
            await handleGetOperators();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleShowAddForm = () => {
    setShowAddForm(true);
  };

  const handleHideAddForm = () => {
    setShowAddForm(false);
  };

  const handleSendEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(newOperatorEmail)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    try {
      handleHideAddForm();
      const token = getToken();
      const response = await axios.post(
        `/admin/send_email/${newOperatorEmail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Email sent successfully!");
        handleHideAddForm();
        setNewOperatorEmail("");
        handleGetOperators();
      } else {
        setError("Failed to send email. Status: " + response.status);
      }
    } catch (error) {
      if (error.request.status === 401) {
        try {
          const refreshResponse = await axios.post("/admin/refresh/", {
          }, { withCredentials: true });

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
            await handleSendEmail();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }
  };

  const handleDeleteOperator = async (email) => {
    try {
      const token = getToken();
      const response = await axios.delete(`/admin/delete_operator/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log("Operator deleted successfully!");
        handleGetOperators();
      } else {
        setError("Failed to delete operator. Status: " + response.status);
      }
    } catch (error) {
      if (error.request.status === 401) {
        try {
          const refreshResponse = await axios.post("/admin/refresh/", {
          }, { withCredentials: true });

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
            await handleDeleteOperator();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }
  };

  const handleLogout = async () => {
    await axios.post("/admin/logout/", {
    }, { withCredentials: true });
    sessionStorage.clear();

  };

  const onSelect = (id) => { };

  return (
    <div className="admin-operator-list">
      {showAddForm ? (
        <div>
          <h2 className="operator-email-invite">
            Please enter the email to invite a new operator
          </h2>
          <input
            type="email"
            placeholder="Enter Email"
            value={newOperatorEmail}
            onChange={(e) => setNewOperatorEmail(e.target.value)}
            className="operator-invite-input"
          />

          <button onClick={handleSendEmail} className="operator-invite-button">
            Invite
          </button>
          {emailError && <p className="error-message">{emailError}</p>}
        </div>
      ) : (
        <React.Fragment>
          <div>
            <div className="operator-list-search-container">
              <div className="input-group">
                <input
                  type="search"
                  placeholder="Search Operators"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="operator-search-form-control"
                />
                <i className="fas fa-search"></i>
              </div>
              <button
                id="add-button"
                type="button"
                className="add-operator"
                onClick={handleShowAddForm}
              >
                <FiUserPlus />
              </button>
              {error && <p className="error-message">{error}</p>}
            </div>
            <ul className="responsive-table">
              <li className="table-header">
                <div className="col col-1">First Name</div>
                <div className="col col-2">Last Name</div>
                <div className="col col-3">Email Address</div>
                <div className="col col-4">Actions</div>
              </li>
              {operators.map((operator) => (
                <OperatorListEntry
                  key={operator.email}
                  operator={operator}
                  onSelect={onSelect}
                  onDeleteClick={handleDeleteOperator}
                />
              ))}
            </ul>
          </div>
          <div className="pagination-container">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              <option value={10}>10 per page</option>
              <option value={25}>25 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AdminOperatorList;
