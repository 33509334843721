import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PatientProfile.styles.css";
import useIdleTimer from "../../useIdleTimer";
import { useNavigate } from "react-router-dom";

const PatientProfile = ({ patient }) => {
  const [editedPatient, setEditedPatient] = useState({ patient });
  const [editedMedicalInfo, setEditedMedicalInfo] = useState({});
  const [prevPatient, setPrevPatient] = useState({ patient });
  const [prevMedicalInfo, setPrevMedicalInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const source = axios.CancelToken.source();
  const [reimbursementAmount, setReimbursementAmount] = useState(null);
  const [reimbursementPaidAmount, setReimbursementPaidAmount] = useState(null);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    mobile_number: "",
    last_name: "",
    first_name: "",
    address_1: "",
    city: "",
    state: "",
    zip_code: "",
  });

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/patients';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const getToken = () => {
    const token = sessionStorage.getItem("patient_token");
    return token;
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await axios.get("/patient/verify_token/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            handleGetPatientInfo();
          } else {
          }
        } catch (error) {
          if (error.request.status === 401) {
            const refreshResponse = await axios.post(
              "/patient/refresh/", {}, { withCredentials: true });

            if (refreshResponse.status === 200) {
              sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
              await handleGetPatientInfo();
            } else {
              handleLogout();
            }
          }
        }
      }
      else {
        navigate("/patients")
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [patient]);

  useEffect(() => {
    const fetchReimbursementAmount = async () => {
      try {
        const response = await axios.post(
          `/patient/get_reimbursement_amount/${patient.email}`
        );
        setReimbursementAmount(response.data.reimbursement_amount);
        setReimbursementPaidAmount(response.data.paid_reimbursement_amount);
      } catch (error) {
        console.error("Error fetching patient reimbursement number");
      }
    };

    fetchReimbursementAmount();
  }, []);
  const handleGetPatientInfo = async () => {
    const token = getToken();

    if (token) {
      try {
        const response = await axios.get(
          `/patient/get_patient_info/${patient.email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setEditedPatient(response.data.patient);
          setEditedMedicalInfo(response.data.medicalInformation);
          setPrevPatient(response.data.patient);
          setPrevMedicalInfo(response.data.medicalInformation);
          setIsLoading(false);
        } else {
          console.error("Error loging in the patient portal");
        }
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/patient/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
            await handleGetPatientInfo();
          } else {
            handleLogout();
          }
        }
      }
    } else {
      console.log("No token found");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    switch (name) {
      case "mobile_number":
        if (!isValidMobileNumber(value)) {
          error = "Invalid mobile number";
        }
        break;
      case "last_name":
        if (value.trim() === "") {
          error = "Last name is required";
        }
        break;
      case "first_name":
        if (value.trim() === "") {
          error = "First name is required";
        }
        break;
      case "address_1":
        if (value.trim() === "") {
          error = "Address is required";
        }
        break;
      case "city":
        if (value.trim() === "") {
          error = "City is required";
        }
        break;
      case "state":
        if (value === "State") {
          error = "State is required";
        }
        break;
      case "zip_code":
        if (value.trim() === "") {
          error = "Zip code is required";
        } else if (!isValidZipCode(value)) {
          error = "Invalid Zip code";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setEditedPatient((editedPatient) => ({
      ...editedPatient,
      [name]: value,
    }));

    setEditedMedicalInfo((editedMedicalInfo) => ({
      ...editedMedicalInfo,
      [name]: value,
    }));
  };

  const isValidMobileNumber = (number) => {
    return /^\d{10}$/.test(number);
  };
  const isValidZipCode = (number) => {
    return /^\d{5}$/.test(number);
  };
  const handleSave = async () => {
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      setEditedPatient((prevPatient) => ({
        ...prevPatient,
        first_name: errors.first_name ? "" : editedPatient.first_name,
        last_name: errors.last_name ? "" : editedPatient.last_name,
        mobile_number: errors.mobile_number ? "" : editedPatient.mobile_number,
      }));
      return;
    }
    try {
      const token = getToken();
      const updateResponsePatient = await axios.post(
        `/patient/update_patient/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          editedPatient,
        }
      );

      const updateResponseMedicalInfo = await axios.post(
        `/patient/update_patient_medical_info/${editedPatient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          editedMedicalInfo,
        }
      );

      if (
        updateResponsePatient.status === 200 &&
        updateResponseMedicalInfo.status === 200
      ) {
        setIsEditing(false);

        const updatedResponse = await axios.get(
          `/patient/get_patient_info/${patient.email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (updatedResponse.status === 200) {
          setEditedPatient(updatedResponse.data.patient);
          setPrevPatient(updatedResponse.data.patient);
          setEditedMedicalInfo(updatedResponse.data.medicalInformation);
          setPrevMedicalInfo(updatedResponse.data.medicalInformation);
        } else {
          console.error("Error loging in the patient portal");
        }
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/patient/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
          await handleSave();
        } else {
          handleLogout();
        }
      }
    }
  };

  const handleCancel = () => {
    setEditedPatient(prevPatient);
    setEditedMedicalInfo(prevMedicalInfo);
    setIsEditing(false);
  };

  const handleLogout = async () => {
    await axios.post("/patient/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  return isLoading ? (
    <div></div>
  ) : (
    <div className="dashboard-patient">
      <div className="patient-profile-dashboard">
        <div className="infoBox">
          <p className="patientNumber">{reimbursementAmount}</p>
          <p className="infoText">Total Amount</p>
        </div>
        <div className="infoBox">
          <p className="patientNumber">{reimbursementPaidAmount}</p>
          <p className="infoText">Total Amount Due</p>
        </div>
      </div>

      <div className="profile-details-container">
        <div className="profile-button-status">
          {isEditing ? (
            <div className="form-buttons">
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="save-button"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="form-buttons-edit">
              <button
                onClick={() => setIsEditing(true)}
                className="edit-button"
              >
                Edit
              </button>
            </div>
          )}
        </div>
        <div className="profile-table-view-1">
          <div className="patient-profile-table-container-1">
            <div className="profile-table-container-1">
              <div className="box">
                <div className="row">
                  <div className="cell cell-1">
                    <div className="title">First Name</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <>
                          <input
                            type="text"
                            name="first_name"
                            value={editedPatient.first_name}
                            onChange={handleInputChange}
                            className="form-input"
                            required
                          />
                          {errors.first_name && (
                            <div className="error-message">
                              {errors.first_name}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="profile-value">
                            {prevPatient.first_name || "N/A"}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-1">
                    <div className="title">Last Name</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <>
                          <input
                            type="text"
                            name="last_name"
                            value={editedPatient.last_name}
                            onChange={handleInputChange}
                            className="form-input"
                          />
                          {errors.last_name && (
                            <div className="error-message">
                              {errors.last_name}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="profile-value">
                          {prevPatient.last_name || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-1">
                    <div className="title">Mobile Number</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <>
                          <input
                            type="text"
                            name="mobile_number"
                            value={editedPatient.mobile_number}
                            onChange={handleInputChange}
                            className="form-input"
                          />
                          {errors.mobile_number && (
                            <div className="error-message">
                              {errors.mobile_number}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="profile-value">
                          {prevPatient.mobile_number || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-1">
                    <div className="title">Email</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <div className="profile-value">
                          {prevPatient.email || "N/A"}
                        </div>
                      ) : (
                        <div className="profile-value">
                          {prevPatient.email || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-1">
                    <div className="title">Address</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <>
                          <div className="address-lines">
                            <input
                              type="text"
                              name="address_1"
                              placeholder="Address line-1"
                              value={editedPatient.address_1}
                              onChange={handleInputChange}
                              className="form-input"
                            />

                            <input
                              type="text"
                              name="address_2"
                              placeholder="Optional"
                              value={editedPatient.address_2}
                              onChange={handleInputChange}
                              className="form-input"
                            />
                            <div className="address-line-last">
                              <input
                                type="text"
                                name="city"
                                placeholder="City"
                                value={editedPatient.city}
                                onChange={handleInputChange}
                                className="form-input-city"
                              />

                              <select
                                name="state"
                                onChange={handleInputChange}
                                value={editedPatient.state || "State"}
                                className="form-input"
                              >
                                <option>state</option>
                                <option>AL</option>
                                <option>AK</option>
                                <option>AZ</option>
                                <option>AR</option>
                                <option>CA</option>
                                <option>CO</option>
                                <option>CT</option>
                                <option>DE</option>
                                <option>FL</option>
                                <option>GA</option>
                                <option>HI</option>
                                <option>ID</option>
                                <option>IL</option>
                                <option>IN</option>
                                <option>IA</option>
                                <option>KS</option>
                                <option>KY</option>
                                <option>LA</option>
                                <option>ME</option>
                                <option>MD</option>
                                <option>MA</option>
                                <option>MI</option>
                                <option>MN</option>
                                <option>MS</option>
                                <option>MO</option>
                                <option>MT</option>
                                <option>NE</option>
                                <option>NV</option>
                                <option>NH</option>
                                <option>NJ</option>
                                <option>NM</option>
                                <option>NY</option>
                                <option>NC</option>
                                <option>ND</option>
                                <option>OH</option>
                                <option>OK</option>
                                <option>OR</option>
                                <option>PA</option>
                                <option>RI</option>
                                <option>SC</option>
                                <option>SD</option>
                                <option>TN</option>
                                <option>TX</option>
                                <option>UT</option>
                                <option>VT</option>
                                <option>VA</option>
                                <option>WA</option>
                                <option>WV</option>
                                <option>WI</option>
                                <option>WY</option>
                              </select>

                              <input
                                type="text"
                                name="zip_code"
                                placeholder="Zip"
                                value={editedPatient.zip_code}
                                onChange={handleInputChange}
                                className="form-input-zipcode"
                              />
                            </div>
                          </div>
                          {errors.address_1 && (
                            <div className="error-message">
                              {errors.address_1}
                            </div>
                          )}
                          {errors.city && (
                            <div className="error-message">{errors.city}</div>
                          )}
                          {errors.state && (
                            <div className="error-message">{errors.state}</div>
                          )}
                          {errors.zip_code && (
                            <div className="error-message">
                              {errors.zip_code}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="address-lines">
                          <div className="profile-value-1">
                            {prevPatient.address_1 || "N/A"}
                          </div>
                          <div className="profile-value-1">
                            {prevPatient.address_2 || "  "}
                          </div>
                          <div className="address-line-last">
                            <div className="profile-value-2">
                              {prevPatient.city || "N/A"}
                            </div>

                            <div className="profile-value-3">
                              {prevPatient.state || "N/A"}
                            </div>

                            <div className="profile-value-4">
                              {prevPatient.zip_code || "N/A"}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-table-container-1">
              <div className="box">
                <div className="row">
                  <div className="cell cell-5">
                    <div className="title">Neck Length</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-4">
                    <div className="value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="neck_length"
                          value={editedMedicalInfo.neck_length}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.neck_length || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-5">
                    <div className="title">Circumference of Head</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-4">
                    <div className="value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="head_circumference"
                          value={editedMedicalInfo.head_circumference}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.head_circumference || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-5">
                    <div className="title">Pregnant or Nursing?</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-4">
                    <div className="value">
                      {isEditing ? (
                        <select
                          name="pregnant_or_nursing"
                          value={
                            editedMedicalInfo.pregnant_or_nursing ||
                            "please select"
                          }
                          onChange={handleInputChange}
                          className="form-input"
                        >
                          <option value="">Select</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </select>
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.pregnant_or_nursing || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-5">
                    <div className="title">Birth Control Pills?</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-4">
                    <div className="value">
                      {isEditing ? (
                        <select
                          name="birth_control_pills"
                          value={
                            editedMedicalInfo.birth_control_pills ||
                            "please select"
                          }
                          onChange={handleInputChange}
                          className="form-input"
                        >
                          <option value="">Select</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </select>
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.birth_control_pills || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell cell-5">
                    <div className="title">Travel Restrictions</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-4">
                    <div className="value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="travel_restrictions"
                          value={editedMedicalInfo.travel_restrictions}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.travel_restrictions || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-view-2">
            <div className="patient-profile-table-container">
              <div className="row-2">
                <div className="column-2">
                  <div className="cell cell-1">
                    <div className="title">Age</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3 border-end-view-2">
                    <div className="value">
                      {isEditing ? (
                        <input
                          type="number"
                          name="age"
                          value={editedMedicalInfo.age}
                          onChange={handleInputChange}
                          min="0"
                          max="100"
                          className="form-input"
                        />
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.age || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="cell cell-8">
                    <div className="title">Gender</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-9 border-end-view-2">
                    <div className="value">
                      {isEditing ? (
                        <select
                          name="gender"
                          value={editedMedicalInfo.gender || "please select"}
                          onChange={handleInputChange}
                          className="form-input"
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.gender || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="cell cell-1">
                    <div className="title">Race</div>
                  </div>
                  <div className="cell cell-2">
                    <div className="value">:</div>
                  </div>
                  <div className="cell cell-3">
                    <div className="value">
                      {isEditing ? (
                        <input
                          type="text"
                          name="race"
                          value={editedMedicalInfo.race}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      ) : (
                        <div className="profile-value">
                          {prevMedicalInfo.race || "N/A"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="patient-profile-table-view-2">
              <div className="row">
                <div className="cell cell-6">
                  <div className="title">
                    Participating in any other research study?
                  </div>
                </div>
                <div className="cell cell-2">
                  <div className="value">:</div>
                </div>
                <div className="cell cell-7">
                  <div className="value">
                    {isEditing ? (
                      <textarea
                        name="participating_in_research"
                        value={editedMedicalInfo.participating_in_research}
                        onChange={handleInputChange}
                        className="form-input-textarea-1"
                      />
                    ) : (
                      <div className="textarea-value-2">
                        {prevMedicalInfo.participating_in_research || "N/A"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cell cell-6">
                  <div className="title">Previous History</div>
                </div>
                <div className="cell cell-2">
                  <div className="value">:</div>
                </div>
                <div className="cell cell-7">
                  <div className="value">
                    {isEditing ? (
                      <textarea
                        type="text"
                        name="previous_history"
                        value={editedMedicalInfo.previous_history}
                        onChange={handleInputChange}
                        className="form-input-textarea-1"
                      />
                    ) : (
                      <div className="textarea-value-2">
                        {prevMedicalInfo.previous_history || "N/A"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
