import React, { useState, useEffect } from "react";
import "./MindyHello.css";
import mindyHello from "../../Images/physician_talking_to_patient.png";
const MindyHello = () => {
  const [text, setText] = useState("");
  const message = "Hi, Welcome to neuro42 Health";

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      setText(message.substring(0, i));
      i++;
      if (i > message.length) clearInterval(typingInterval);
    }, 50);

    return () => clearInterval(typingInterval);
  }, []);
  return (
    <div className="mindy-hello-background">
      <div className="mindy-hello-image">
        <img src={mindyHello} alt="mindyhello" />
      </div>
      <div className="cloud-message">
        <p>{text}</p>
        <div></div>
      </div>
    </div>
  );
};

export default MindyHello;
