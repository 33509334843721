import React, { useState } from 'react';
import "../Operator/InvitePatientModal.css";
import patient_physician_image from "../Images/mindy_clipboard_physician 5.png";
import check_image from "../Images/simple-line-icons_check.png";
import cross from "../Images/cross.png";
import axios from "axios";

const InviteProviderModal = ({ open, onClose }) => {
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: ''
    });
    const [isClicked, setIsClicked] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const roles = ['Physician', 'MRI-Tech', 'Nurse'];

    const getToken = () => {
        const role = sessionStorage.getItem("role");
        const token = sessionStorage.getItem(role === "Admin" ? "admin_token" : "operator_token");
        return token;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const resetForm = () => {
        setFormValues({
            firstName: '',
            lastName: '',
            email: '',
            role: ''
        });
        setSelectedRole(''); // Reset the selected role
    };

    const handleSendInvitationEmail = async (e) => {
        e.preventDefault();
        setIsClicked(true);
        try {
            const token = getToken();
            const project = sessionStorage.getItem("project");
            const response = await axios.post(
                `/admin/invite_and_create_operator/${formValues.email}`,
                formValues,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        project: project,
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                setIsConfirmationOpen(true);
                resetForm();
            } else {
                setErrorMessage(`Failed to invite provider. Status: ${response.status}`);
                setIsErrorOpen(true);
            }
        } catch (error) {
            setErrorMessage("An error occurred while sending the invitation. Please try again.");
            setIsErrorOpen(true);
            console.error("Error inviting provider:", error);
        }
    };

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    const handleConfirmationClose = () => {
        resetForm();
        setIsConfirmationOpen(false);
        onClose();
    };

    const handleErrorClose = () => {
        setIsErrorOpen(false);
    };

    const handleRoleSelect = (role) => {
        setSelectedRole(role);
        setFormValues({ ...formValues, role: role });
        setIsDropdownOpen(false);
    };

    if (!open) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-button" onClick={handleCancel}>
                    &times;
                </button>
                <div className="invite-patient-form">
                    <h2>Invite New Provider</h2>
                    <p>Enter the provider's details below to send an invitation.</p>
                    <form onSubmit={handleSendInvitationEmail}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleChange}
                                placeholder="First name"
                                className="firstname-form"
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleChange}
                                placeholder="Last name"
                                className="firstname-form"
                                required
                            />
                        </div>
                        <input
                            type="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                            placeholder="Email"
                            className="firstname-form"
                            required
                        />
                        <div className="custom-dropdown">
                            <div className="dropdown-header-invite-patient" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                {selectedRole || 'Select Role'}
                                <span className={`arrow ${isDropdownOpen ? 'open' : ''}`}>▼</span>
                            </div>
                            {isDropdownOpen && (
                                <ul className="dropdown-list">
                                    {roles.map((role, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleRoleSelect(role)}
                                            className={`dropdown-item ${selectedRole === role ? 'selected' : ''}`}
                                        >
                                            {role}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {/* Hidden native select to store the form value */}
                            <select
                                name="role"
                                value={formValues.role}
                                onChange={handleChange}
                                style={{ display: 'none' }}
                            >
                                {roles.map((role, index) => (
                                    <option key={index} value={role}>
                                        {role}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="invite-button">
                            Invite
                        </button>
                    </form>
                </div>
                <img
                    src={patient_physician_image}
                    alt="Character"
                    className="corner-image"
                />
            </div>

            {/* Confirmation Modal */}
            {isConfirmationOpen && (
                <div className="confirmation-modal-overlay">
                    <div className="confirmation-modal-content">
                        <div className="confirmation-modal-header">
                            <img
                                src={check_image}
                                alt="Character"
                                className="check-image"
                            />
                        </div>
                        <h2>Great!</h2>
                        <p>Invite sent successfully!</p>
                        <button onClick={handleConfirmationClose}>Okay</button>
                    </div>
                </div>
            )}

            {/* Error Modal */}
            {isErrorOpen && (
                <div className="error-modal-overlay">
                    <div className="error-modal-content">
                        <div className="error-modal-header">
                            <img
                                src={cross}
                                alt="Character"
                                className="cross-image"
                            />
                        </div>
                        <h2>Oh Snap!</h2>
                        <p>{errorMessage}</p>
                        <div className="error-modal-actions">
                            <button className="error-ok-button" onClick={handleErrorClose}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InviteProviderModal;
