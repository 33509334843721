import React from "react";
import NavBarNew from "./NavBarNew";
import Footer from "./footer";
import "./TermsAndConditions.css";

const PrivacyPolicy = () => {
  return (
    <div className="main-page">
      <NavBarNew />

      <div className="terms-and-conditions">
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is critically important to us. This Privacy Policy
          explains how we collect, use, share, and protect your personal
          information while you use our Health Portal.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect the following types of information to provide and
          enhance our services:
        </p>
        <ul>
          <li>
            <b>Personal Information</b>: Name, email address, phone number, date
            of birth, and any other details you provide during registration.
          </li>
          <li>
            <b>Health Data</b>: Medical history, health records, and other data
            uploaded to the platform by you or authorized third parties.
          </li>
          <li>
            <b>Usage Information</b>: IP address, browser type, device
            information, and browsing behavior on the platform.
          </li>
        </ul>
        <p>
          This information helps us create a personalized and secure experience
          for our users.
        </p>

        <h2>2. How We Use Your Information</h2>
        <p>Your information is used to:</p>
        <ul>
          <li>Provide and improve the platform's services.</li>
          <li>Offer personalized health insights and recommendations.</li>
          <li>Ensure compliance with legal and regulatory requirements.</li>
          <li>
            Communicate with you regarding account updates, new features, or
            support requests.
          </li>
        </ul>
        <p>
          We will not use your information for purposes unrelated to the
          services offered without your explicit consent.
        </p>

        <h2>3. Sharing of Information</h2>
        <p>
          We do not sell or rent your personal data to third parties. However,
          your information may be shared with:
        </p>
        <ul>
          <li>
            <b>Authorized Healthcare Providers</b>: To facilitate your medical
            care.
          </li>
          <li>
            <b>Third-Party Service Providers</b>: For functions like data
            storage, analytics, or payment processing, under strict
            confidentiality agreements.
          </li>
          <li>
            <b>Legal Authorities</b>: When required by law, such as for
            investigations or compliance with legal obligations.
          </li>
        </ul>
        <p>
          We ensure that any third parties involved uphold the same level of
          data protection as outlined in this policy.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We take data security seriously and implement industry-standard
          measures, including:
        </p>
        <ul>
          <li>Encryption of sensitive data both in transit and at rest.</li>
          <li>Regular security assessments and vulnerability testing.</li>
          <li>Access control mechanisms to restrict unauthorized access.</li>
        </ul>
        <p>
          Despite these efforts, no system is completely secure, and we cannot
          guarantee the absolute safety of your information.
        </p>

        <h2>5. Your Rights</h2>
        <p>
          You have the following rights concerning your personal information:
        </p>
        <ul>
          <li>
            <b>Access</b>: Request a copy of your data stored on the platform.
          </li>
          <li>
            <b>Correction</b>: Update inaccurate or incomplete information.
          </li>
          <li>
            <b>Deletion</b>: Request the removal of your data, subject to legal
            or contractual obligations.
          </li>
          <li>
            <b>Restriction</b>: Limit the processing of your data under certain
            conditions.
          </li>
          <li>
            <b>Portability</b>: Receive your data in a commonly used,
            machine-readable format.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the details
          provided below.
        </p>

        <h2>6. Retention of Data</h2>
        <p>
          Your data will be retained for as long as necessary to fulfill the
          purposes outlined in this policy, comply with legal obligations, or
          resolve disputes.
        </p>
        <p>
          Upon request, your data can be anonymized or securely deleted,
          provided there are no overriding legal or operational requirements to
          retain it.
        </p>

        <h2>7. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar technologies to enhance your experience on
          the platform. These may include:
        </p>
        <ul>
          <li>
            <b>Essential Cookies</b>: Required for the platform's core
            functionality.
          </li>
          <li>
            <b>Performance Cookies</b>: Help us analyze usage patterns to
            improve our services.
          </li>
          <li>
            <b>Marketing Cookies</b>: Used for personalized advertisements and
            promotional messages.
          </li>
        </ul>
        <p>
          You can manage your cookie preferences through your browser settings.
        </p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. When changes are made, the updated policy will be posted on
          this page with a revised "last updated" date.
        </p>
        <p>
          Continued use of the platform after changes are made constitutes
          acceptance of the updated policy.
        </p>

        <h2>9. Contact Information</h2>
        <p>
          If you have questions, concerns, or requests regarding this Privacy
          Policy, please contact us at:
        </p>
        <p>
          Email: <a href="mailto:hello@neuro42.com">hello@neuro42.com</a>
        </p>
        <p>Phone: +1-(480) 241-3211</p>
        <p>Mailing Address: 2 Bryant St, Suite 240 San Francisco, CA 94105</p>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
