import React, { useEffect, useState } from "react";
import "../Operator/OperatorScanListNew.css";
import "./AdminScanRequestList.css";
import NavbarNew from "../Navbar/NavbarNew";
import Footer from "../Operator/Footer";
import Pagination from "@mui/material/Pagination";
import questionMark from "../Images/Vector.png";
import whiteTick from "../Images/White-tick.png";
import Tick from "../Images/mdi_approve.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Sidebar from "../Operator/components/Sidebar";
import axios from "axios";
import SidebarNew from "../Operator/components/SidebarNew";

const AdminScanRequestList = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const role = sessionStorage.getItem("role");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;

  const [pendingRequests, setPendingRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);

  useEffect(() => {
    loadPendingRequests();
  }, [currentPage, pageSize]);

  const getToken = () => sessionStorage.getItem("admin_token");

  const loadPendingRequests = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");

      const response = await axios.post(
        `/admin/get_admin_profile/`,
        { email: storedUserObject.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (response.status === 200) {
        const allRequests = response.data.admin.pending_requests || [];
        const startIndex = (currentPage - 1) * pageSize;
        const currentRequests = allRequests.slice(
          startIndex,
          startIndex + pageSize
        );
        console.log("Data HH", response.data);
        setPendingRequests(currentRequests);
        setTotalPages(Math.ceil(allRequests.length / pageSize));
      }
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    } finally {
      setLoading(false);
    }
  };

  function parseDate(dateString) {
    console.log(dateString);
    if (typeof dateString !== "string" || dateString.length !== 8) {
      throw new Error("Invalid date format. Expected format: YYYYMMDD.");
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error(
        "Invalid date components. Ensure the input is in YYYYMMDD format."
      );
    }

    return `${month}/${day}/${year}`;
  }

  const handleApproveRequest = async (request) => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");

      const response = await axios.post(
        `/admin/approve_scan_request/`,
        {
          email: request.email,
          firstname: request.firstname,
          lastname: request.lastname,
          studyId: request.studyId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (response.status === 200) {
        setPendingRequests((prevRequests) =>
          prevRequests.filter((r) => r.studyId !== request.studyId)
        );
      }
    } catch (error) {
      console.error("Error approving scan request:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        <SidebarNew />
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line">
              <div className="title-container">
                <h2 className="inner-page-title">Scan Request List</h2>
                <div className="tooltip-container">
                  <img
                    src={questionMark}
                    alt="info-icon"
                    className="info-icon"
                  />
                  <span className="tooltip-text">
                    View the scan requests here
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner-overlay">
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="scan-table-container">
              <div className="scan-table-header-admin">
                <div className="header-cell">First Name</div>
                <div className="header-cell">Last Name</div>
                <div className="header-cell">Email</div>
                <div className="header-cell">Scan Date</div>
                <div className="header-cell">Approve</div>
              </div>
              <div className="table-body">
                {pendingRequests.map((request, index) => (
                  <div key={index} className="admin-user-row">
                    <div className="user-cell">{request.firstname}</div>
                    <div className="user-cell">{request.lastname}</div>
                    <div className="user-cell">{request.email}</div>
                    <div className="user-cell">
                      {parseDate(request.studyDate)}
                    </div>
                    <div className="user-cell">
                      <button
                        className="request-access-button"
                        onClick={() => handleApproveRequest(request)}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination-container">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    siblingCount={0}
                    boundaryCount={1}
                    page={currentPage}
                    onChange={handlePageChange}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    sx={{
                      "& .MuiPaginationItem-root": { color: "#999999" },
                      "& .Mui-selected": {
                        backgroundColor: "#F0F6F5 !important",
                        color: "#999999",
                        fontSize: "1vw",
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#F0F6F5",
                      },
                    }}
                  />
                </Stack>

                <Select
                  value={pageSize}
                  onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 60 }}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#f0f0f0",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#999999",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#999999",
                    },
                    ".MuiSelect-select": {
                      padding: "8px 10px",
                      fontSize: "1vw",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#999999",
                    },
                  }}
                >
                  <MenuItem value={10}>10 per page</MenuItem>
                  <MenuItem value={25}>25 per page</MenuItem>
                  <MenuItem value={50}>50 per page</MenuItem>
                </Select>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminScanRequestList;
