import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Operator from "./components/Operator/Operator";
import Patient from "./components/Patient/Patient";
import Admin from "./components/Admin/Admin";
import OperatorList from "./components/Admin/OperatorList";
import AdminAddOperator from "./components/Admin/AdminAddOperator";
import PatientResetPassword from "./components/Patient/PatientResetPassword";
import PatientChangePassword from "./components/Patient/PatientChangePassword";
import OperatorResetPassword from "./components/Operator/OperatorResetPassword";
import OperatorChangePassword from "./components/Operator/OperatorChangePassword";
import OperatorAddPatient from "./components/Operator/OperatorAddPatient";
// import Physicians from "./components/Landing/NewLanding Page/Physician";
import Patients from "./components/Landing/NewLanding Page/PatientLandingNew";
import Physicians from "./components/Landing/NewLanding Page/PhysicianLandingNew";
import LandingPageHome from "./components/Landing/NewLanding Page/LandingPageHome";
import LandingPageHomeNew from "./components/Landing/NewLanding Page/LandingPageHomeNew";
// import Patients from "./components/Landing/NewLanding Page/Patients";
import AdminPage from "./components/Landing/NewLanding Page/Admin";
import AdminScanRequestList from "./components/Admin/AdminScanRequestList";
import AboutUs from "./components/Landing/NewLanding Page/AboutUs";
import PatientLogin from "./components/Patient/PatientLogin";
import PatientRegistration from "./components/Patient/PatientRegistration";
import OperatorPatientListNew from "./components/Operator/OperatorPatientListNew";
import NavbarNew from "./components/Navbar/NavbarNew";
import OperatorScanListNew from "./components/Operator/OperatorScanListNew";
import OperatorPatientList from "./components/Operator/OperatorPatientList";
import Example from "./components/Operator/example";
import Notification from "./components/Operator/Notification";

import PatientProfile from "./components/Operator/PatientProfileNew";
import OperatorProfile from "./components/Operator/OperatorProfile";
import AdminProfile from "./components/Admin/AdminProfile";
import OperatorPatientFilesNew from "./components/Operator/OperatorPatientFilesNew";
import OperatorPatientFiles from "./components/Operator/OperatorPatientFiles";
import OperatorPatientRegister from "./components/Operator/OperatorPatientRegister";
import FeedbackOperator from "./components/Operator/FeedbackOperator";
import ContactUs from "./components/Landing/NewLanding Page/ContactUs";
import AdminOperatorRegister from "./components/Admin/AdminOperatorRegister";
import PatientAuthorizationForm from "./components/Patient/PatientAuthorizationForm";
import TermsAndConditions from "./components/Landing/NewLanding Page/TermsAndConditions";
import PrivacyPolicy from "./components/Landing/NewLanding Page/PrivacyPolicy";
// import OperatorPatientRegister from "./components/Operator/OperatorPatientRegister";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPageHomeNew />} />
        <Route exact path="/example" element={<Example />} />
        <Route path="/physicians" element={<Physicians />} />
        <Route path="/patients" element={<Patients />} />
        <Route path="/adminPage" element={<AdminPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/operator" element={<OperatorPatientList />} />
        {/* <Route path="/operator/:next" element={<Operator />} /> */}
        <Route path="/patient" element={<PatientLogin />} />
        <Route path="/patient-register" element={<PatientRegistration />} />
        <Route path="/patient/:next" element={<Patient />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/navbar" element={<NavbarNew />} />
        <Route exact path="/notifications" element={<Notification />} />
        {/* <Route path="/patientRegister" element={<OperatorPatientRegister />} /> */}
        <Route
          path="/operatorPatientlist"
          element={<OperatorPatientListNew />}
        />
        <Route path="/scan" element={<OperatorScanListNew />} />
        <Route path="/files" element={<OperatorPatientFilesNew />} />
        <Route path="/profile" element={<PatientProfile />} />
        <Route path="/operatorProfile" element={<OperatorProfile />} />
        <Route path="/adminProfile" element={<AdminProfile />} />
        <Route path="/feedback" element={<FeedbackOperator />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/operatorList" element={<OperatorList />} />
        <Route path="/authForm" element={<PatientAuthorizationForm />} />
        <Route path="/adminScanRequests" element={<AdminScanRequestList />} />
        <Route
          path="/register-operator/:token"
          element={<AdminAddOperator />}
        />
        <Route
          path="/patient/reset-password"
          element={<PatientResetPassword />}
        />
        <Route
          path="/patient/change-password/:token"
          element={<PatientChangePassword />}
        />
        <Route
          path="/operator/reset-password"
          element={<OperatorResetPassword />}
        />
        <Route
          path="/operator/change-password/:token"
          element={<OperatorChangePassword />}
        />
        <Route path="/invite/:token" element={<OperatorPatientRegister />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route
          path="/provider/invite/:token"
          element={<AdminOperatorRegister />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
