import React from "react";

import MindyHello from "./MindyHello";
import LandingContent from "./LandingContent";
import NavBar from "./NavBar";

const LandingPageHome = () => {
  return (
    <div>
      <NavBar />
      <MindyHello />
      <LandingContent />
    </div>
  );
};

export default LandingPageHome;
