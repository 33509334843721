export default{
    subject: 'neuro42 MR Study Scheduling',
    message: `Hello Mr./ Ms. XYZ,  

My name is XYZ. On behalf of neuro42, I’d like to thank you for choosing to volunteer in our MR research study. We are a medical device company located in the Bay Area. Our research focuses on MR scans of the brain using a portable MR scanner.  

I would love to help you schedule a scan session at our office. We have sessions available from 10:00 am to 3:00 pm, Monday to Friday, excluding holidays.  

Please provide your availability by replying to this email or calling the number (628) 231-8123.   

Once your session is booked, a confirmation email will be sent to you with the details and directions to our office.  

Thank you very much for your time. We look forward to hearing from you soon.  

Best regards, 
XYZ 
Study Coordinator 
neuro42, Inc.  
(628) 231-8123 | study@neuro42.com 
www.neuro42.ai 

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto.`,
  }