import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import AdminImage from "../../Images/mindy_pointing_admin.png";
import MRI from "../../Images/MRI.png";

import "./Admin.css";
import doctorManagement from "../../Images/operation.jpg";
import operations from "../../Images/operations.jpg";
import {
  FaStethoscope,
  FaLock,
  FaTasks,
  FaChartLine,
  FaUserShield,
} from "react-icons/fa";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
const AdminPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const source = axios.CancelToken.source();


  const handleLogin = async () => {
    try {
      const response = await axios.post("/admin/login/", {
        username: username,
        password: password,
      });

      if (response.status === 400) {
        setError("Failed to authenticate. Check credentials.");
      } else {
        const token = response.data.access_token;
        sessionStorage.setItem("admin_token", token);
        navigate("/admin/");
      }
    } catch (error) {
      if (error.request.status === 401) {
        try {
          const refreshResponse = await axios.post(
            "/admin/refresh/",
            {},
            { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem(
              "admin_token",
              refreshResponse.data.access_token
            );
            navigate("/admin/");
            await handleLogin();
          }
        } catch (error) {
          console.error("Error refreshing token at login :", error);
        }
      }
    }
  };
  return (
    <>
      <NavBar />
      <div className="physician-background">
        <div className="physician-image">
          <img src={AdminImage} alt="mindyPhysician" />
        </div>
        <div className="physician-banner-container">
          <div className="physician-banner-message">
            <p>Welcome to neuro42 Admin portal</p>
          </div>
          <div className="physician-banner-message-2">
            <p>Empowering Minds, Simplifying Administration</p>
          </div>
        </div>
        <div className="physician-banner-separator"></div>
        <div className="landing-login-container">
          <div className="landing-login-form-container">
            <h2 style={{ textAlign: "center" }}>Admin Log In</h2>
            <input
              type="username"
              placeholder="Email"
              value={username}
              className="landing-login-input"
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              className="landing-login-input"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button onClick={handleLogin}>Login</button>
          {error && <p>{error}</p>}
          <p className="terms-conditions">
            By logging in, you agree to our{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            .
          </p>
          <p className="forgot-password">
            {/* onClick={navigateToResetPassword}*/}
            <span className="toggle-link">Reset Password</span>
          </p>
        </div>
      </div>
      <div className="physician-content">
        <div className="physician-content-box-1">
          <h2>neuro42 Health Control Center</h2>
          <img src={MRI} alt="Product with Patient" />
          <p>
            Discover unparalleled precision with our cutting-edge Portal MRI
            technology.
          </p>
        </div>
        <div className="box-2-3-alignment">
          <div className="admin-content-box-2">
            <div className="admin-content-box-2-title">
              <h2>Operations</h2>
              <p>
                neuro42Health's platform streamlines operations, making it
                easier to manage physicians, appointments, and administrative
                tasks. With our advanced tools and automation features, you can
                efficiently organize schedules, allocate resources, and oversee
                the day-to-day operations of your healthcare facility. By
                streamlining workflows, neuro42Health empowers administrators to
                optimize productivity and focus on delivering exceptional
                patient care.
              </p>
            </div>
            <img src={operations} alt="operations" />
          </div>
          <div className="admin-content-box-3">
            <img src={doctorManagement} alt="doctorManagement" />
            <div className="admin-content-box-3-title">
              <h2>Physician Access Management</h2>
              <p>
                neuro42Health's access management feature puts administrators in
                control of physician access with ease. From inviting physicians
                to the platform to approving their access requests and managing
                permissions, our system simplifies the entire process.
                Administrators can efficiently handle access permissions, revoke
                access when necessary, and maintain a secure environment. With
                neuro42Health, managing physician access is simple, secure, and
                hassle-free.
              </p>
            </div>
          </div>
        </div>

        <div className="benefits-container">
          <h2>Benefits for Admins</h2>
          <div className="grid-container">
            <div className="benefit">
              <FaStethoscope className="icon" />
              <span>Manage Physicians</span>
            </div>
            <div className="benefit">
              <FaTasks className="icon" />
              <span>Streamline Workflow</span>
            </div>
            <div className="benefit">
              <FaLock className="icon" />
              <span>Secure Compliance</span>
            </div>
            <div className="benefit">
              <FaChartLine className="icon" />
              <span>Analytics</span>
            </div>
            <div className="benefit">
              <FaUserShield className="icon" />
              <span>Access Management</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPage;
