import React from "react";

import MindyHello from "./MindyHello";
import LandingContent from "./LandingContent";
import NavBarNew from "./NavBarNew";
import HeroSectionPatient from "./HeroSectionPatient";
import "./PatientLandingNew.css";
import logo from "../../Images/MRI2-removebg-preview.png";
import CurvedLine from "./CurvedLinePatient";
import WhoWeServe from "./WhoWeServe";
import Footer from "./footer";

const PatientLandingNew = () => {
  const handleRedirect = () => {
    window.open("https://neuro42.ai/", "_blank");
  };

  return (
    <div className="main-page">
      <NavBarNew />
      <HeroSectionPatient />

      <div className="shapes">
        <div className="shape rectangle"></div>
        <div className="shape circle"></div>
      </div>

      <div class="styled-line-main">
        <div class="line1-main"></div>
        <div class="line2-main"></div>
        <div class="line3-main"></div>
      </div>

      <div class="info-section">
        <div class="info-content">
          <h2>Seamless Access to Your MRI Insights</h2>
          <p>
            Experience the dual advantages of precision and portability with our
            cutting-edge Portal MRI technology. Enjoy faster scans and clearer
            images, providing both patients and physicians with comfort,
            convenience, and confidence in diagnoses and treatment plans. Step
            into the future of MRI innovation with us.
          </p>
          <button class="learn-more-button" onClick={handleRedirect}>
            Learn More
          </button>
        </div>
        <div class="info-image">
          <img src={logo} alt="MRI Device" />
        </div>
      </div>

      <div className="shapes-2">
        <div className="rectangle2">
          <div class="telehealth-header">
            <div class="styled-line-TeleHealth">
              <div class="line1-TeleHealth"></div>
              <div class="line2-TeleHealth"></div>
              <div class="line3-TeleHealth"></div>
            </div>
            <h2>Health Portal Benefits</h2>
            <p>
              Transform the way you deliver care with our comprehensive health
              portal. Experience the future of healthcare today.
            </p>
          </div>
        </div>
      </div>
      <div className="curved-line-container">
        <CurvedLine />
      </div>
      <Footer />
    </div>
  );
};

export default PatientLandingNew;
