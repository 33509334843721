import React from "react";
import "./HeroSectionPatient.css";

import mindyHello from "../../Images/6010752-uhd_3840_2160_25fps.mp4";

const HeroSectionPatient = () => {
  return (
    <div className="hero-section">
      <video className="background-video" autoPlay loop muted>
        <source src={mindyHello} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="gradient-overlay"></div>
      <div className="content-overlay">
        <h1>Your Path to Accessible Healthcare Imaging </h1>
        <p>
          Discover neuro42’s cutting-edge services crafted to enhance your care
          and make advanced imaging accessible, wherever you are.
        </p>
      </div>
    </div>
  );
};

export default HeroSectionPatient;
