import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1240);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1240);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="navbar-landing-page">
      {isMobile ? (
        <nav className="landing-page-navbar">
          <>
            <div className="landing-navbar-Links">
              <FaBars className="menu-icon" onClick={toggleMenu} />
              <div className="landing-page-logo">
                <p>
                  <Link to="/" className="logo-link">
                    <span className="neuro">neuro</span>
                    <span className="fortytwo">42</span>{" "}
                    <span className="health">Health</span>
                  </Link>
                </p>
              </div>
            </div>
          </>
          <Navbar.Collapse className="vertical-operator-nav">
            <nav className={`navbar-buttons-landing ${isOpen ? "show" : ""}`}>
              <Link to="/physicians">Physicians</Link>
              <Link to="/patients">Patients</Link>
              <Link to="/adminPage">Admin</Link>
              <Link to="/aboutus">About us</Link>
            </nav>
          </Navbar.Collapse>
        </nav>
      ) : (
        <nav className="landing-page-navbar">
          <div className="landing-page-logo">
            <p>
              <Link to="/" className="logo-link">
                <span className="neuro">neuro</span>
                <span className="fortytwo">42</span>{" "}
                <span className="health">Health</span>
              </Link>
            </p>
          </div>
          <div className="landing-navbar-Links">
            <Link to="/physicians">Physicians</Link>
            <Link to="/patients">Patients</Link>
            <Link to="/adminPage">Admin</Link>
            <Link to="/aboutus">About us</Link>
          </div>
        </nav>
      )}
    </div>
  );
};

export default NavBar;
