export default{
    subject: 'neuro42 MR Study ReScheduling',
    message: `Hello Mr./ Ms. XYZ,

On behalf of neuro42 I would like to thank you again for being interested in our project and for considering another visit to allow us to pursue our latest goals. Your enthusiasm is allowing us to make really rapid progress! This time the visit would only include the neuro42 scan at 2 Bryant St., there will not be any UCSF/ 3T scan. As last time we'll cover travel expenses and give you a $50 gift certificate. 

To arrange your next visit, please let us know what days/dates would work for you and we can arrange the scans accordingly. 

If you'd like more information or have any questions, please don't hesitate to contact us on the contact information provided in the signature. 

 

XYZ  
Study Coordinator  
neuro42, Inc.   
(628) 231-8123 | study@neuro42.com  
www.neuro42.ai  

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto.  `,
    }