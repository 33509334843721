import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import patient from "../SVGs/Patient.svg";
import scan from "../SVGs/Scan.svg";
import file from "../SVGs/File.svg";
import exit from "../SVGs/Exit.svg";
import vector from "../SVGs/Vector.svg";

const SidebarNew = () => {
  const location = useLocation();
  const getIconStyle = (path) => {
    return location.pathname === path
      ? {
          backgroundColor: "#C5ECE5",
        }
      : {};
  };
  return (
    <div className="sidebar">
      <Link to="/operatorPatientlist">
        <div className="sidebar-icon-container">
          <img src={exit} alt="Back to Patient List" className="sidebar-icon" />
          <span className="side-tooltip">Back to Patient List</span>
        </div>
      </Link>
    </div>
  );
};

export default SidebarNew;
