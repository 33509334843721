import React, { useState } from "react";
import axios from "axios";
import "./Patient.styles.css";
import logo from "../Images/fulllogo.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIdleTimer from "../../useIdleTimer";
const PatientRegistration = ({ toggleForm }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip_code: "",
    mobile_number: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirmPassword: false,
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
  const [passwordStrength, setPasswordStrength] = useState({
    " (8)-minlength": false,
    " (1)-lowercase": false,
    " (1)-uppercase": false,
    " (1)-digit": false,
    " (1)-specialChar": false,
  });

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/patients';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    // Real-time validation
    if (name === "email" && !validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
    } else if (name === "mobile_number" && !validateMobileNumber(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile_number: "Mobile number must be 10 digits",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setTouchedFields((prevState) => ({ ...prevState, [name]: true }));

    if (name === "password") {
      const strength = checkPasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const checkPasswordStrength = (password) => {
    const strength = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*()]/.test(password),
    };
    return strength;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isChecked) {
      try {
        const response = await axios.post("/patient/register/", formData);

        if (response.status === 201) {
          setSuccess(true);
          setError("");
          setFormData({
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip_code: "",
            mobile_number: "",
          });
          setErrors({});
        } else {
          setError("Failed to register.");
        }
      } catch (err) {
        if (err.response && err.response.status === 400) {
          setError("Registration error. Please check your details.");
        } else {
          setError("Something went wrong in registration. Please try again.");
        }
      }
    }
    else {
      alert("Please accept the terms and conditions!")
    }
  };

  return (
    <div className="patient-container">
      <div className="operator-invite-logoimg">
        <img src={logo} alt="neuro42" />
      </div>
      <div>
        <h2 className="register-page-content">
          Welcome to neuro42. Register as a Patient
        </h2>
        <p className="register-instructions">
          Please fill in the information below to register as a Patient:
        </p>
        <p className="password-requirements">
          (Password must be at least 8 characters long and contain at least one
          lowercase letter, one uppercase letter, one digit, and one special
          character)
        </p>
      </div>
      <form onSubmit={handleSubmit} className="patient-form">
        <input
          type="email"
          name="email"
          placeholder="Email*"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && <p className="error-text">{errors.email}</p>}

        <input
          className="admin-operator-list-input"
          type="text"
          placeholder="First Name*"
          value={formData.first_name}
          onChange={(e) =>
            setFormData({ ...formData, first_name: e.target.value })
          }
          required
        />
        <input
          className="admin-operator-list-input"
          type="text"
          placeholder="Last Name*"
          value={formData.last_name}
          onChange={(e) =>
            setFormData({ ...formData, last_name: e.target.value })
          }
          required
        />
        <div className="pass-wrapper-2">
          <input
            placeholder="Password*"
            name="password"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={handleInputChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            required
          />
          <i className="eye-icon" onClick={togglePasswordVisiblity}>
            {showPassword ? eye : eyeSlash}
          </i>
        </div>
        {isPasswordFocused &&
          Object.entries(passwordStrength).map(([key, value]) => (
            <p key={key} style={{ color: value ? "green" : "red" }}>
              {value ? "✓" : "✗"}:{key}
            </p>
          ))}
        <div className="pass-wrapper-2">
          <input
            className="operator-patient-list-input"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password*"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            onFocus={handleConfirmPasswordFocus}
            onBlur={handleConfirmPasswordBlur}
            required
          />
          <i className="eye-icon" onClick={toggleConfirmPasswordVisiblity}>
            {showConfirmPassword ? eye : eyeSlash}
          </i>
        </div>
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          formData.password === formData.confirmPassword ? (
          <p style={{ color: "green" }}>Passwords match</p>
        ) : null}
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          formData.password !== formData.confirmPassword ? (
          <p className="password-match-error">Passwords do not match</p>
        ) : null}

        <input
          type="text"
          name="address_1"
          placeholder="Address Line 1*"
          value={formData.address_1}
          onChange={handleChange}
          required
        />

        <input
          type="text"
          name="address_2"
          placeholder="Address Line 2"
          value={formData.address_2}
          onChange={handleChange}
        />

        <input
          type="text"
          name="city"
          placeholder="City*"
          value={formData.city}
          onChange={handleChange}
          required
        />

        <select
          name="state"
          onChange={handleChange}
          value={formData.state || "State"}
          className="patient-register-select"
        >
          <option>AL</option>
          <option>AK</option>
          <option>AZ</option>
          <option>AR</option>
          <option>CA</option>
          <option>CO</option>
          <option>CT</option>
          <option>DE</option>
          <option>FL</option>
          <option>GA</option>
          <option>HI</option>
          <option>ID</option>
          <option>IL</option>
          <option>IN</option>
          <option>IA</option>
          <option>KS</option>
          <option>KY</option>
          <option>LA</option>
          <option>ME</option>
          <option>MD</option>
          <option>MA</option>
          <option>MI</option>
          <option>MN</option>
          <option>MS</option>
          <option>MO</option>
          <option>MT</option>
          <option>NE</option>
          <option>NV</option>
          <option>NH</option>
          <option>NJ</option>
          <option>NM</option>
          <option>NY</option>
          <option>NC</option>
          <option>ND</option>
          <option>OH</option>
          <option>OK</option>
          <option>OR</option>
          <option>PA</option>
          <option>RI</option>
          <option>SC</option>
          <option>SD</option>
          <option>TN</option>
          <option>TX</option>
          <option>UT</option>
          <option>VT</option>
          <option>VA</option>
          <option>WA</option>
          <option>WV</option>
          <option>WI</option>
          <option>WY</option>
        </select>
        <input
          type="text"
          name="zip_code"
          placeholder="Zip*"
          value={formData.zip_code}
          onChange={handleChange}
          className="form-input-zipcode-2"
        />

        <input
          type="tel"
          name="mobile_number"
          placeholder="Mobile Number*"
          value={formData.mobile_number}
          onChange={handleChange}
          required
        />

        <label className="forgot-password">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          {' '}
          I accept the{' '}
          <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
        </label>
        <div>
          <button type="submit">Register</button>
        </div>
        <>
          <p>
            Already have an account?{" "}
            <span className="toggle-link" onClick={toggleForm}>
              Sign In
            </span>
          </p>
        </>
      </form>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>Registration successful!</p>}
    </div>
  );
};

export default PatientRegistration;
