import React from "react";
import logo from "../../Images/mindy_thumbsup_physician.png";
import logo_clipboard from "../../Images/mindy_clipboard_physician.png";
import logo_clipboard2 from "../../Images/mindy_clipboard_physician 2.png";
import mindy_sitting_patient from "../../Images/mindy_sitting_patient.png";
import logo_clipboard5 from "../../Images/mindy_clipboard_physician 5.png";
import mindy_waving_admin from "../../Images/mindy_waving_admin.png";
import "./CurvedLine.css"; // Import the CSS file
import curved_line from "../../Images/curved lines.svg";

const CurvedLine = () => {
  return (
    <div className="curved-line-container">
      <div className="large-screen-layout">
        <img
          src={curved_line}
          alt="Curved Line"
          style={{
            position: "relative",
            width: "80%",
            height: "50%",
            left: "10%",
            top: "10vh",
          }}
        />

        <img
          src={logo}
          alt="Mascot"
          style={{
            position: "absolute",
            left: "20%",
            top: "-2vh",
            width: "15vw",
            height: "30vh",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "relative",
            width: "24vw",
            height: "12vh",
            left: "15vw",
            top: "0%",
          }}
        >
          <h3
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              top: "-212vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: "3vw",
              lineHeight: "4vh",
              display: "flex",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Accessible Care
          </h3>
          <p
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              top: "-205vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontWeight: "300",
              fontSize: "1vw",
              lineHeight: "3vh",
              display: "flex",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            From the comfort of your home or anywhere you choose, providers can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>

        <div className="Analytics">
          <div
            className="Rectangle"
            style={{
              position: "absolute",
              width: "80vw",
              height: "50vh",
              left: "50vw",
              top: "30vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "7.5vh 0px 0px 7.5vh",
              zIndex: "0",
            }}
          >
            <img
              src={logo_clipboard2}
              alt="Mascot"
              style={{
                position: "absolute",
                left: "2%",
                top: "-15vh",
                width: "20%",
                height: "50%",
              }}
            />
            <h3
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                left: "5%",
                top: "15%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontStyle: "italic",
                fontWeight: "400",
                fontSize: "3vw",
                lineHeight: "4vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.9)",
              }}
            >
              Timely Insights
            </h3>
            <p
              style={{
                position: "absolute",
                width: "40%",
                height: "auto",
                left: "5%",
                top: "20vh",
                fontFamily: "Lato",
                fontStyle: "italic",
                fontWeight: "300",
                fontSize: "1vw",
                lineHeight: "3vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.6)",
              }}
            >
              Access MRI scan results faster than ever before. Our portal
              ensures that patients and providers receive critical diagnostic
              information promptly, supporting faster decisions and better
              outcomes.
            </p>
          </div>
        </div>

        <div className="Scheduling">
          <img
            src={logo_clipboard}
            alt="Mascot"
            style={{
              position: "absolute",
              left: "20%",
              top: "80vh",
              width: "15vw",
              height: "30vh",
            }}
          />
          <h3
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              left: "35%",
              top: "85vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: "3vw",
              lineHeight: "4vh",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Efficient Workflows
          </h3>
          <p
            style={{
              position: "absolute",
              width: "30%",
              height: "auto",
              left: "35%",
              top: "97vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontWeight: "300",
              fontSize: "1vw",
              lineHeight: "3vh",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            Revolutionizing healthcare efficiency with seamless access and
            streamlined processes, empowering patients and providers to save
            time and focus on better outcomes.
          </p>
        </div>

        <div className="Reimbursements">
          <div
            className="Rectangle"
            style={{
              position: "absolute",
              width: "100%",
              height: "50vh",
              left: "20vw",
              top: "130vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "7.5vh 0px 0px 7.5vh",
              zIndex: "0",
            }}
          >
            <img
              src={mindy_sitting_patient}
              alt="Mascot"
              style={{
                position: "absolute",
                left: "55%",
                top: "10%",
                width: "13vw",
                height: "30vh",
              }}
            />
            <h3
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                left: "8%",
                top: "15%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontStyle: "italic",
                fontWeight: "10",
                fontSize: "3vw",
                lineHeight: "4vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.9)",
              }}
            >
              Simplified Healthcare Solutions
            </h3>
            <p
              style={{
                position: "absolute",
                width: "45%",
                height: "auto",
                left: "8%",
                top: "20vh",
                fontFamily: "Lato",
                fontStyle: "italic",
                fontWeight: "300",
                fontSize: "1vw",
                lineHeight: "3vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.6)",
              }}
            >
              Transforming diagnostics with a user-friendly portal designed to
              reduce complexity, enhance care delivery, and save valuable time
              for everyone involved.
            </p>
          </div>
        </div>

        <div className="Patient Management">
          <div
            className="Circle"
            style={{
              position: "absolute",
              width: "90vw",
              height: "90vw",
              left: "-30vw",
              top: "150vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "50%",
              zIndex: "0",
            }}
          ></div>
          <img
            src={logo_clipboard5}
            alt="Mascot"
            style={{
              position: "absolute",
              left: "27%",
              top: "170vh",
              width: "18vw",
              height: "30vh",
            }}
          />
          <h3
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              left: "15%",
              top: "195vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: "3vw",
              lineHeight: "4vh",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Access Management
          </h3>
          <p
            style={{
              position: "absolute",
              width: "30%",
              height: "auto",
              left: "15%",
              top: "208vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontWeight: "10",
              fontSize: "1vw",
              lineHeight: "3vh",
              display: "flex",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            Our role-based access system ensures that only authorized
            individuals can view and manage MRI scans, enabling secure
            collaboration and maintaining privacy.
          </p>
        </div>

        <div className="Secure Compliance">
          <img
            src={mindy_waving_admin}
            alt="Mascot"
            style={{
              position: "absolute",
              left: "70%",
              top: "225vh",
              width: "15vw",
              height: "30vh",
              zIndex: 1,
            }}
          />
          <h3
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              left: "60%",
              top: "200vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "180",
              fontSize: "3vw",
              lineHeight: "4vh",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Secure Compliance
          </h3>
          <p
            style={{
              position: "absolute",
              width: "30%",
              height: "auto",
              left: "60%",
              top: "212vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontSize: "1vw",
              lineHeight: "3vh",
              display: "flex",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            Your health information is safe with us. Built with advanced
            encryption and strict data privacy protocols, the neuro42 Health
            Portal ensures your medical records are protected at all times.
          </p>
        </div>
      </div>

      <div className="small-screen-layout">
        <div className="card">
          <img src={logo} alt="Mascot" className="card-image" />
          <h3 className="card-title">Accessible Care</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard2} alt="Mascot" className="card-image" />
          <h3 className="card-title">Analytics</h3>
          <p className="card-text">
            Leverage powerful analytics to track patient progress and outcomes.
            Make informed decisions based on data-driven insights, ensuring
            better patient care and operational efficiency.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard} alt="Mascot" className="card-image" />
          <h3 className="card-title">Scheduling</h3>
          <p className="card-text">
            Manage appointments with ease. Our intuitive scheduling tools ensure
            that your time is well-organized, reducing no-shows and maximizing
            efficiency.
          </p>
        </div>

        <div className="card">
          <img
            src={mindy_sitting_patient}
            alt="Mascot"
            className="card-image"
          />
          <h3 className="card-title">Reimbursements</h3>
          <p className="card-text">
            Streamline the reimbursement process, ensuring that you are
            compensated promptly and accurately for the care you provide. Focus
            on patient care, not paperwork.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard5} alt="Mascot" className="card-image" />
          <h3 className="card-title">Patient Management</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>

        <div className="card">
          <img src={mindy_waving_admin} alt="Mascot" className="card-image" />
          <h3 className="card-title">Secure Compliance</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurvedLine;
