import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import logo from "../Images/fulllogo.png";
import "./OperatorAddPatient.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useIdleTimer from "../../useIdleTimer";

const OperatorAddPatient = () => {
  const [newPatient, setNewPatient] = useState({
    password: "",
    confirmPassword: "",
  });
  const [operatorEmail, setOperatorEmail] = useState("");
  const [error, setError] = useState("");
  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirmPassword: false,
  });

  const [passwordStrength, setPasswordStrength] = useState({
    " (8)-minlength": false,
    " (1)-lowercase": false,
    " (1)-uppercase": false,
    " (1)-digit": false,
    " (1)-specialChar": false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const { token } = useParams();
  const navigate = useNavigate();

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/operator/get_email_address/${token}`,
          {}
        );
        if (response.status === 200) {
          setOperatorEmail(response.data.email);
        }
      } catch (error) {
        console.error("Error fetching operator email:", error);
      }
    };
    fetchData();
  }, [token]);

  const checkPasswordStrength = (password) => {
    const strength = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*()]/.test(password),
    };
    return strength;
  };

  const handleAddPatient = async (e) => {
    e.preventDefault();

    // Check if both password and confirm password are provided
    if (!newPatient.password || !newPatient.confirmPassword) {
      setError("Both password and confirm password are required");
      return;
    }

    // Check if passwords match
    if (newPatient.password !== newPatient.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // console.log("new Patient: ", newPatient);

    try {
      const response = await axios.post(
        `/operator/create_patient/${token}`,
        newPatient
      );
      if (response.status === 201) {
        console.log("Patient added!");

        setError("");

        setNewPatient({
          password: "",
          confirmPassword: "",
        });

        const confirmBox = window.confirm(
          "Your registration has been successfully completed!"
        );
        if (confirmBox === true) {
          closePopup();
          navigate("/patient/");
        }
      } else {
        setError("Failed to add patient. Status: " + response.status);
      }
    } catch (error) {
      console.error("Error adding patient:", error);
      setError("An error occurred while adding patient.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPatient((prevState) => ({ ...prevState, [name]: value }));
    setTouchedFields((prevState) => ({ ...prevState, [name]: true }));

    if (name === "password") {
      const strength = checkPasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const closePopup = () => {
    // navigate("/patient/");
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
    setSubmitDisabled(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
    setSubmitDisabled(false);
  };

  return (
    <div className="alineRegisterPageItem">
      <div className="patient-invite-logoimg">
        <img src={logo} alt="neuro42" />
      </div>
      <div>
        <h2 className="register-page-content">
          Welcome to neuro42 Health. Register as a patient
        </h2>
        <p className="register-instructions">
          Please fill in the information below to register as a patient:
        </p>
        <p className="password-requirements">
          (Password must be at least 8 characters long and contain at least one
          lowercase letter, one uppercase letter, one digit, and one special
          character)
        </p>
      </div>
      <form onSubmit={handleAddPatient} className="invite-add-patient-form">
        <input
          className="operator-patient-list-input"
          style={{ backgroundColor: "lightgrey", cursor: "not-allowed" }}
          type="text"
          placeholder="Email"
          value={operatorEmail}
          readOnly
        />

        <div className="pass-wrapper">
          <input
            placeholder="Password*"
            name="password"
            type={showPassword ? "text" : "password"}
            value={newPatient.password}
            onChange={handleInputChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            required
          />
          <i className="eye-icon" onClick={togglePasswordVisiblity}>
            {showPassword ? eye : eyeSlash}
          </i>
        </div>
        {isPasswordFocused &&
          Object.entries(passwordStrength).map(([key, value]) => (
            <p key={key} style={{ color: value ? "green" : "red" }}>
              {value ? "✓" : "✗"}:{key}
            </p>
          ))}
        <div className="pass-wrapper">
          <input
            className="operator-patient-list-input"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password*"
            name="confirmPassword"
            value={newPatient.confirmPassword}
            onChange={handleInputChange}
            onFocus={handleConfirmPasswordFocus}
            onBlur={handleConfirmPasswordBlur}
            required
          />
          <i className="eye-icon" onClick={toggleConfirmPasswordVisiblity}>
            {showConfirmPassword ? eye : eyeSlash}
          </i>
        </div>
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          newPatient.password === newPatient.confirmPassword ? (
          <p style={{ color: "green" }}>Passwords match</p>
        ) : null}
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          newPatient.password !== newPatient.confirmPassword ? (
          <p className="password-match-error">Passwords do not match</p>
        ) : null}
        <button type="submit" className="add-patient-button" disabled={submitDisabled}>
          Register
        </button>
      </form>
      <p className="error-message">{error}</p>
    </div>
  );
};

export default OperatorAddPatient;
