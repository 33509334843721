import React from "react";
import NavBarNew from "./NavBarNew";
import Footer from "./footer";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="main-page">
      <NavBarNew />

      <div className="terms-and-conditions">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to our neuro42 Health Portal. By using our platform, you agree
          to comply with the following terms and conditions. Please read them
          carefully before proceeding.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Health Portal, you confirm your agreement to
          these Terms and Conditions, as well as any other policies referenced
          herein. If you do not agree, you must immediately stop using the
          platform.
        </p>
        <p>
          This agreement is a legally binding contract between you and neuro42,
          governing your use of the platform and its services.
        </p>

        <h2>2. User Responsibilities</h2>
        <p>
          As a user, you are responsible for ensuring that the information you
          provide is accurate, complete, and up-to-date. You must:
        </p>
        <ul>
          <li>Keep your login credentials confidential and secure.</li>
          <li>
            Ensure that any content uploaded complies with applicable laws and
            regulations.
          </li>
          <li>
            Avoid using the platform for unlawful, fraudulent, or harmful
            purposes.
          </li>
          <li>Report any unauthorized use of your account immediately.</li>
        </ul>
        <p>
          Failure to adhere to these responsibilities may result in account
          suspension or termination.
        </p>

        <h2>3. Privacy Policy</h2>
        <p>
          We value your privacy and are committed to protecting your personal
          data. Information collected from you is used to enhance your
          experience on the platform, including providing personalized
          health-related insights.
        </p>
        <p>
          Details about how we collect, use, and store your data can be found in
          our <a href="/privacy-policy">Privacy Policy</a>. By using our
          platform, you consent to our data practices as outlined therein.
        </p>

        <h2>4. Medical Disclaimer</h2>
        <p>
          The Health Portal is designed to provide general health information
          and tools to assist in managing your wellness. However, it does not
          replace professional medical advice, diagnosis, or treatment.
        </p>
        <p>
          You should always consult a licensed healthcare provider for specific
          medical concerns or conditions. Relying solely on the information
          provided by the platform is at your own risk.
        </p>

        <h2>5. Intellectual Property</h2>
        <p>
          All materials on this platform, including text, graphics, images, and
          software, are the property of [Health Portal Name] or its licensors.
          These are protected under copyright, trademark, and other intellectual
          property laws.
        </p>
        <p>
          Users are prohibited from reproducing, distributing, or modifying any
          content without prior written permission.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          We strive to provide accurate and reliable information; however, we do
          not guarantee that the content on the platform is error-free or
          up-to-date. We are not liable for any:
        </p>
        <ul>
          <li>
            Direct, indirect, incidental, or consequential damages arising from
            your use of the platform.
          </li>
          <li>Losses resulting from reliance on the content provided.</li>
          <li>
            Service interruptions, viruses, or technical issues beyond our
            control.
          </li>
        </ul>

        <h2>7. Third-Party Links</h2>
        <p>
          Our platform may include links to third-party websites or services for
          your convenience. While we strive to ensure these links are reliable,
          we do not endorse or take responsibility for their content, privacy
          policies, or practices.
        </p>
        <p>
          Users are advised to review the terms and privacy policies of any
          third-party websites they visit.
        </p>

        <h2>8. Amendments</h2>
        <p>
          We reserve the right to update or revise these Terms and Conditions at
          any time to reflect changes in our policies, services, or legal
          requirements. Updated terms will be posted on the platform with a
          revised "last updated" date.
        </p>
        <p>
          Continued use of the platform after changes are made indicates your
          acceptance of the updated terms.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Your Country/State]. Any disputes arising from or
          related to these terms shall be resolved exclusively in the courts of
          [Your Location].
        </p>

        <h2>10. Termination</h2>
        <p>
          We may suspend or terminate your account if you breach these Terms and
          Conditions or engage in activities that harm other users, the
          platform, or our reputation. Upon termination:
        </p>
        <ul>
          <li>Your access to the platform will be revoked immediately.</li>
          <li>
            Any remaining obligations under these terms will survive
            termination.
          </li>
        </ul>

        <h2>11. Contact Information</h2>
        <p>
          If you have any questions, feedback, or concerns about these Terms and
          Conditions, please reach out to us at:
        </p>
        <p>
          Email: <a href="mailto:hello@neuro42.com">hello@neuro42.com</a>
        </p>
        <p>Phone: +1-(480) 241-3211</p>
        <p>Mailing Address: 2 Bryant St, Suite 240 San Francisco, CA 94105</p>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
