import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { CALENDLY_URL } from '../../constants';


const InlineAppointmentComponent = () => {
  useCalendlyEventListener({
    onEventScheduled: (e) => console.log(e.data.payload)
  });

  return (
    <div className="inline-widget">
        <InlineWidget
            url={CALENDLY_URL}
            styles={{ height: '1000px' }}
        />
    </div>
  );
};

export default InlineAppointmentComponent;
