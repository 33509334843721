import React from "react";
import "./Footer.styles.css";

function InternalFooter() {
  return (
    <div className="footer">
      <p style={{ display: "inline-block" }}>
        &copy; 2024 neuro42 Health All rights reserved.
      </p>
      {/* {false && <div className="social-icons" style={{ display: 'inline-block', float: 'right' }}>
                <a href="https://www.facebook.com/"><FaFacebook size={24} color="black" /></a>
                <a href="https://www.linkedin.com/"><FaLinkedin size={24} color="black" /></a>
                <a href="https://twitter.com/"><FaTwitter size={24} color="black" /></a>
            </div>}  */}
    </div>
  );
}

export default InternalFooter;
