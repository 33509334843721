import React from "react";
import "./OperatorPatientListNew2.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import search from "../Images/Search.png";
import { FaArrowUp } from "react-icons/fa";
import PatientRow from "./PatientRow";
import AddIcon from "../Images/Add Icon.png";
import AddIconWhite from "../Images/Add Icon white.png";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InvitePatientModal from "./InvitePatientModal";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Pagination from './Pagination';
import axios from "axios";

const OperatorPatientListNew = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const role = sessionStorage.getItem("role")
  console.log("userObject", storedUserObjectString)
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;

  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState({
    email: "",
    res: "",
    first_name: "",
    last_name: "",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const getToken = () => {
    const token = role === "Physician" ? sessionStorage.getItem("operator_token") : sessionStorage.getItem("admin_token");
    return token;
  };

  const handleGetPatients = async () => {
    try {
      const token = getToken();
      if (!token) {
        navigate("/physicians");
      }
      const project = sessionStorage.getItem("project");
      console.log("\n\n project print at patient list \n\n", project);

      const response = await axios.get("/operator/get_all_patients/", {
        headers: {
          Authorization: `Bearer ${token}`,
          project: project,
        },
        params: {
          page: currentPage,
          page_size: pageSize,
          searchQuery: searchQuery.trim(),
        },
      });

      if (response.status === 200) {
        setPatients(response.data.patients);
        setTotalPages(response.data.total_pages);
      } else {
        setError({
          res: "Failed to fetch patients. Status: " + response.status,
        });
      }
    } catch (error) {
      // if (error.request.status === 401) {
      //   const refreshResponse = await axios.post(
      //     "/operator/refresh/",
      //     {},
      //     { withCredentials: true }
      //   );

      //   if (refreshResponse.status === 200) {
      //     sessionStorage.setItem(
      //       "operator_token",
      //       refreshResponse.data.access_token
      //     );
      //     await handleGetPatients();
      //   } else {
      //     console.error("Refresh token failed", error);
      //     handleLogout();
      //   }
      // }
      console.log("Error fetching patients:", error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPatients();
  }, [currentPage, pageSize, searchQuery]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    handleGetPatients();
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    localStorage.clear();
    sessionStorage.clear();
  };

  const [icon, setIcon] = useState(AddIcon);
  const [textColor, setTextColor] = useState("#409E8D");
  const [backgroundColor, setBackgroundColor] = useState("#C5ECE5");

  const handleMouseEnter = () => {
    setIcon(AddIconWhite);
    setTextColor("#FFFFFF");
    setBackgroundColor("#409E8D");
  };

  const handleMouseLeave = () => {
    setIcon(AddIcon);
    setTextColor("#409E8D");
    setBackgroundColor("#C5ECE5");
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    height: "4vh",
    justifyContent: "center",
    width: "auto",
    minWidth: "10vw",
    padding: "1px 5px",
    fontSize: "var(--font-size-xl)",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "0%",
    background: backgroundColor,
    mixBlendMode: "normal",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2vw",
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "2vw",
    color: textColor,
    border: "none",
    transition: "color 0.3s ease",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    boxSizing: "border-box",
  };

  const iconStyle = {
    width: "2vh",
    height: "2vh",
    marginLeft: "0.5vw",
    transition: "opacity 0.3s ease",
  };

  return (
    <>
      <NavbarNew username={username} />
      <div className="main-content">
        <div className="patient-list-container-main">
          <div class="patient-list-title-container">
            <h2 className="title-patient-list">Patient List</h2>
            <div class="patient-list-tooltip-container">
              <img
                src={questionMark}
                alt="info-icon"
                class="patient-list-info-icon"
              />
              <span class="patient-list-tooltip-text">
                View the patient list here
              </span>
            </div>
          </div>

          <div className="patient-list-search-container">
            <img
              src={search}
              alt="search"
              className="patient-list-search-icon"
            />
            <input
              type="search"
              className="patient-list-search-input"
              placeholder="Search Patients..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <button
            className="add-patient-button-new"
            onClick={handleOpenModal}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={buttonStyle}
          >
            Add Patient
            <img
              src={icon}
              alt="AddIcon"
              className="add-icon"
              style={iconStyle}
            />
          </button>
        </div>

        <div className="patient-list-patient-table-container">
          {loading && (
            <div className="loading-spinner-overlay">
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <div className="patient-list-table-header">
            <div className="patient-list-header-cell">MRN Number</div>
            <div className="patient-list-header-cell">First Name</div>
            <div className="patient-list-header-cell">Last Name</div>
            <div className="patient-list-header-cell">Email Address</div>
            <div className="patient-list-header-cell">Indication</div>
          </div>
          <div className="patient-list-table-body">
            {patients.map((patient, index) => (
              <PatientRow key={index} patient={patient} />
            ))}
          </div>

          {/* Pagination Section */}
          <div className="pagination-container">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                siblingCount={0}
                boundaryCount={1}
                page={currentPage}
                onChange={(event, page) => handlePageChange(page)}
                shape="rounded"
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#999999",
                    fontSize: "1vw",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#F0F6F5 !important",
                    color: "#999999",
                    fontSize: "1vw",
                  },
                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#F0F6F5",
                  },
                }}
              />
            </Stack>

            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              // className="items-per-page"
              variant="outlined"
              size="small"
              style={{ minWidth: 60 }}
              sx={{
                minHeight: 5,
                minHeight: 5,
                borderRadius: "10px",
                backgroundColor: "#f0f0f0",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#999999",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#999999",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#999999",
                },
                ".MuiSelect-select": {
                  padding: "8px 10px",
                  fontSize: "1vw",
                },
                ".MuiSelect-root": {
                  minHeight: "unset",
                },
              }}
            >
              <MenuItem value={10}>10 per page</MenuItem>
              <MenuItem value={25}>25 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
            </Select>
          </div>
        </div>
        <InvitePatientModal open={isModalOpen} onClose={handleCloseModal} />
      </div>
      <Footer />
    </>
  );
};

export default OperatorPatientListNew;
