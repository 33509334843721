import React, { useState, useEffect } from "react";
import NavbarNew from "../../Navbar/NavbarNew";
import axios from "axios";
import "./ContactUs.css";
import { useNavigate, Link } from "react-router-dom";
import patient_physician_image from "../../Images/mindy_clipboard_physician 5.png";
import physician_thumbsup_image from "../../Images/simple-line-icons_check.png";
import cross from "../../Images/cross.png";
import Footer from "../../Operator/Footer";
import tick from "../../Operator/SVGs/tick.svg";
import insta from "../../Operator/SVGs/insta.svg";
import twitter from "../../Operator/SVGs/twitter.svg";
import linkedin from "../../Operator/SVGs/linkedin.svg";
import Mail from "../../Operator/SVGs/Mail.svg";
import Call from "../../Operator/SVGs/Call.svg";
import Location from "../../Operator/SVGs/Location.svg";

const ContactUs = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const navigate = useNavigate();
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.operator.first_name;
  const last_name = storedUserObject.operator.last_name;
  const email = storedUserObject.operator.email;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    helpMessage: "",
  });
  const handleConfirmationClose = () => {
    setFormValues({ ...formValues });
    setIsConfirmationOpen(false);
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
    // onClose();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleCancel = () => {
    const { firstName, lastName, email, subject, helpMessage } = formValues;

    if (firstName || lastName || email || subject || helpMessage) {
      setFormValues({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        helpMessage: "",
      });
    } else {
      navigate("/operatorPatientlist");
    }
  };
  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleSendContactEmail = async (e) => {
    e.preventDefault();
    // setIsClicked(true);
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      const response = await axios.post(
        `/operator/send_contactus/`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project: project,
          },
        }
      );
      if (response.status === 200) {
        setIsConfirmationOpen(true);
        setFormValues({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          helpMessage: "",
        });
      } else {
        setErrorMessage(`Failed to add patient. Status: ${response.status}`);
        setIsErrorOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while sending the invitation. Please try again."
      );
      setIsErrorOpen(true);
      console.error("Error adding patient:", error);
    }
  };
  return (
    <div className="page-body">
      <NavbarNew username={username} />

      <div className="contact-us-main">
        <div className="center-rectangle">
          <div className="contact-form">
            <h2>Send Us A Message</h2>
            <form
              className="form-group-contact"
              onSubmit={handleSendContactEmail}
            >
              <div className="form-group-line-1">
                <input
                  type="text"
                  name="firstName"
                  value={(formValues.firstName = username)}
                  placeholder={username}
                  className="firstname-form"
                  disabled
                />
                <input
                  type="text"
                  name="lastName"
                  value={(formValues.lastName = last_name)}
                  placeholder={last_name}
                  className="secondname-form "
                  disabled
                />
              </div>

              <input
                type="email"
                name="email"
                value={(formValues.email = email)}
                placeholder={email}
                className="email-form"
                disabled
              />
              <input
                type="text"
                name="subject"
                value={formValues.subject}
                onChange={handleChange}
                placeholder="Subject"
                className="mrn-form"
                required
              />

              <textarea
                name="helpMessage"
                className="textarea-contact"
                value={formValues.helpMessage}
                onChange={handleChange}
                placeholder="How can we help?"
                required
              ></textarea>
              <div className="contactus-buttons">
                <button type="submit" className="contact-button">
                  Send
                </button>
                <button
                  type="cancel"
                  className="contact-button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <div className="contact-info">
            <div className="contact-title">Contact Information</div>
            <div className="map-contact">
              <img src={Location} alt="Location" />
              <p>2 Bryant St, Suite 240, San Francisco CA, 94105</p>
            </div>
            <div className="map-contact">
              <img src={Mail} alt="Mail" />
              <p>hello@neuro42.com</p>
            </div>
            <div className="map-contact">
              <img src={Call} alt="Call" />
              <p>(480)-241-3211</p>
            </div>
            <div className="social-media">
              <a
                href="https://www.instagram.com/neuro42inc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="insta" />
              </a>
              <a
                href="https://x.com/i/flow/login?redirect_after_login=%2Fneuro42inc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/neuro42"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      {isConfirmationOpen && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal-content">
            <div className="confirmation-modal-header">
              <img src={tick} alt="Character" className="check-image" />
            </div>
            <h2>Thank You!</h2>
            <p>
              Your message has been sent successfully. We will review your
              request soon.
            </p>
            <button onClick={handleConfirmationClose}>Okay</button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Character" className="cross-image" />
              {/* <button className="modal-close-button" onClick={handleErrorClose}>
                                &times;
                            </button> */}
            </div>
            <h2>Oh Snap!</h2>
            <p>{errorMessage}</p>
            <div className="error-modal-actions">
              <button className="error-ok-button" onClick={handleErrorClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
      {isConfirmationOpen && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal-content">
            <div className="confirmation-modal-header">
              <img src={tick} alt="Character" className="check-image" />
            </div>
            <h2>Thank You!</h2>
            <p>
              Your message has been sent successfully. We will review your
              request soon.
            </p>
            <button onClick={handleConfirmationClose}>Okay</button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Character" className="cross-image" />
              {/* <button className="modal-close-button" onClick={handleErrorClose}>
                                &times;
                            </button> */}
            </div>
            <h2>Oh Snap!</h2>
            <p>{errorMessage}</p>
            <div className="error-modal-actions">
              <button className="error-ok-button" onClick={handleErrorClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default ContactUs;
