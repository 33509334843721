import log from 'loglevel';
import axios from 'axios';

log.setLevel('debug');

const sendLogToBackend = async (level, message) => {
  try {
    const response = await axios.post('/operator/log/', {
      level: level,
      message: message,
    });
    if (response.status !== 200) {
      console.error('Failed to send log to backend:', response);
    }
  } catch (error) {
    console.error('Error sending log to backend:', error);
  }
};

// Override log methods to send logs to backend
const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) => {
    rawMethod(...args);
    sendLogToBackend(methodName, args.join(' '));
  };
};

log.setLevel(log.getLevel());

export default log;
