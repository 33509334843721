import React, { useState } from "react";
import PatientRegistration from "./PatientRegistration";
import PatientLogin from "./PatientLogin";
import "./App.styles.css";

function Patient() {
  const [isRegistration, setIsRegistration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleForm = () => {
    setIsRegistration(!isRegistration);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="app-container">
          {isRegistration ? (
            <PatientRegistration toggleForm={toggleForm} />
          ) : (
            <PatientLogin toggleForm={toggleForm} />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default Patient;
