import React from "react";
import logo from "../../Images/mindy_thumbsup_physician.png";
import logo_clipboard from "../../Images/mindy_thumbsup_patient.png";
import logo_clipboard2 from "../../Images/mindy_clipboard_physician 2.png";
import mindy_sitting_patient from "../../Images/mindy_sitting_patient.png";
import logo_clipboard5 from "../../Images/mindy_clipboard_physician 5.png";
import mindy_waving_admin from "../../Images/mindy_waving_admin.png";
import "./CurvedLinePhysician.css"; // Import the CSS file
import curved_line from "../../Images/Mask group.svg";

const CurvedLinePhysician = () => {
  return (
    <div className="curved-line-container">
      <div className="large-screen-layout">
        <img
          src={curved_line}
          alt="Curved Line"
          style={{
            position: "relative",
            width: "80%",
            height: "50%",
            left: "10%",
            top: "10vh",
          }}
        />

        <img
          src={logo}
          alt="Mascot"
          style={{
            position: "absolute",
            left: "20%",
            top: "-2vh",
            width: "15vw",
            height: "30vh",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "relative",
            width: "35vw",
            height: "12vh",
            left: "10vw",
            top: "0%",
          }}
        >
          <h3
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              top: "-148vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: "3vw",
              lineHeight: "4vh",
              display: "flex",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Enhanced Efficiency
          </h3>
          <p
            style={{
              position: "absolute",
              width: "90%",
              height: "auto",
              top: "-140vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontWeight: "300",
              fontSize: "1vw",
              lineHeight: "3vh",
              display: "flex",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            Simplify your workflow with streamlined tools for managing patients,
            viewing scans, and sharing data with your team, saving time and
            enhancing productivity and quality of care.
          </p>
        </div>

        <div className="Analytics">
          <div
            className="Rectangle"
            style={{
              position: "absolute",
              width: "80vw",
              height: "50vh",
              left: "50vw",
              top: "30vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "7.5vh 0px 0px 7.5vh",
              zIndex: "0",
            }}
          >
            <img
              src={logo_clipboard2}
              alt="Mascot"
              style={{
                position: "absolute",
                left: "2%",
                top: "-10vh",
                width: "20%",
                height: "50%",
              }}
            />
            <h3
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                left: "5%",
                top: "10vh",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontStyle: "italic",
                fontWeight: "400",
                fontSize: "3vw",
                lineHeight: "4vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.9)",
              }}
            >
              Centralized Patient Data
            </h3>
            <p
              style={{
                position: "absolute",
                width: "50%",
                height: "auto",
                left: "5%",
                top: "24vh",
                fontFamily: "Lato",
                fontStyle: "italic",
                fontWeight: "300",
                fontSize: "1vw",
                lineHeight: "3vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.6)",
              }}
            >
              Access all patient imaging and diagnostic records in one secure,
              organized location. Simplify access to critical information,
              reduce administrative tasks, and ensure accurate, efficient care
              delivery.
            </p>
          </div>
        </div>

        <div className="Scheduling">
          <img
            src={logo_clipboard}
            alt="Mascot"
            style={{
              position: "absolute",
              left: "20%",
              top: "78vh",
              width: "15vw",
              height: "30vh",
            }}
          />
          <h3
            style={{
              position: "absolute",
              width: "100%",
              height: "auto",
              left: "35%",
              top: "85vh",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "italic",
              fontWeight: "400",
              fontSize: "3vw",
              lineHeight: "4vh",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.9)",
            }}
          >
            Anywhere, Anytime Access
          </h3>
          <p
            style={{
              position: "absolute",
              width: "30%",
              height: "auto",
              left: "35%",
              top: "98vh",
              fontFamily: "Lato",
              fontStyle: "italic",
              fontWeight: "300",
              fontSize: "1vw",
              lineHeight: "3vh",
              alignItems: "center",
              color: "rgba(102, 102, 102, 0.6)",
            }}
          >
            Conveniently review patient scans on any device, whether you're at
            your desk, in the clinic, or on the move.
          </p>
        </div>

        <div className="Reimbursements">
          <div
            className="Rectangle"
            style={{
              position: "absolute",
              width: "100%",
              height: "50vh",
              left: "35vw",
              top: "140vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "7.5vh 0px 0px 7.5vh",
              zIndex: "0",
            }}
          >
            <img
              src={mindy_sitting_patient}
              alt="Mascot"
              style={{
                position: "absolute",
                left: "38%",
                top: "10%",
                width: "13vw",
                height: "30vh",
              }}
            />
            <h3
              style={{
                position: "absolute",
                width: "100%",
                height: "auto",
                left: "8%",
                top: "10%",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontStyle: "italic",
                fontWeight: "10",
                fontSize: "3vw",
                lineHeight: "4vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.9)",
              }}
            >
              Realtime Updates
            </h3>
            <p
              style={{
                position: "absolute",
                width: "30%",
                height: "auto",
                left: "8%",
                top: "20vh",
                fontFamily: "Lato",
                fontStyle: "italic",
                fontWeight: "300",
                fontSize: "1vw",
                lineHeight: "3vh",
                display: "flex",
                alignItems: "center",
                color: "rgba(102, 102, 102, 0.6)",
              }}
            >
              Never miss important updates—receive instant notifications for new
              imaging results or shared data, keeping you informed and ready to
              act.
            </p>
          </div>
        </div>

        <div className="Patient Management">
          <div
            className="Circle"
            style={{
              position: "absolute",
              width: "90vw",
              height: "90vw",
              left: "-30vw",
              top: "150vh",
              background: "rgba(163, 226, 215, 0.25)",
              borderRadius: "50%",
              zIndex: "0",
            }}
          />
        </div>
      </div>

      <div className="small-screen-layout">
        <div className="card">
          <img src={logo} alt="Mascot" className="card-image" />
          <h3 className="card-title">Accessible Care</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard2} alt="Mascot" className="card-image" />
          <h3 className="card-title">Analytics</h3>
          <p className="card-text">
            Leverage powerful analytics to track patient progress and outcomes.
            Make informed decisions based on data-driven insights, ensuring
            better patient care and operational efficiency.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard} alt="Mascot" className="card-image" />
          <h3 className="card-title">Scheduling</h3>
          <p className="card-text">
            Manage appointments with ease. Our intuitive scheduling tools ensure
            that your time is well-organized, reducing no-shows and maximizing
            efficiency.
          </p>
        </div>

        <div className="card">
          <img
            src={mindy_sitting_patient}
            alt="Mascot"
            className="card-image"
          />
          <h3 className="card-title">Reimbursements</h3>
          <p className="card-text">
            Streamline the reimbursement process, ensuring that you are
            compensated promptly and accurately for the care you provide. Focus
            on patient care, not paperwork.
          </p>
        </div>

        <div className="card">
          <img src={logo_clipboard5} alt="Mascot" className="card-image" />
          <h3 className="card-title">Patient Management</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>

        <div className="card">
          <img src={mindy_waving_admin} alt="Mascot" className="card-image" />
          <h3 className="card-title">Secure Compliance</h3>
          <p className="card-text">
            From the comfort of your home or anywhere you choose, physicians can
            connect seamlessly with their patients. Enjoy timely, personalized
            care, ensuring your health needs are met with ease and efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurvedLinePhysician;
