import React from "react";
import NavbarNew from "../Navbar/NavbarNew";

const Example = () => {
  const username = "vatsal";
  return (
    <>
      <NavbarNew username={username} />
    </>
  );
};

export default Example;
