export default{
    subject: 'neuro42 MR Study Session',
    message: `Hello Mr./ Ms. XYZ,

I hope you are doing fine.  

I am reaching out to you on behalf of neuro42. neuro42 is a medical device company located in the Bay Area. Our research focuses on MR scans of the brain using a portable MR scanner. 

I would like to thank you for choosing to volunteer in our MRI study.  

To enroll into our clinical study please fill this form.  

https://neuro42.ai/study.html 

Once the form is submitted, our clinical team will get back to you for further screening. If you have any questions, do not hesitate to contact us by replying to this email or calling on (628) 231- 8123.  
    

Thank you very much for your time. We look forward to hearing from you soon. 

Best regards, 
XYZ  
Study Coordinator 
neuro42, Inc. 
(628) 231- 8123  
study@neuro42.com 
www.neuro42.ai 
This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient. Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited. If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto.
`, 
}