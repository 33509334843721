import React from "react";
import InlineAppointmentComponent from "./InlineAppointmentComponent";

const OperatorPatientAppointment = ({ patient }) => {
  return (
    <div className="Operator-appointment-container">
      <InlineAppointmentComponent />
    </div>
  );
};

export default OperatorPatientAppointment;
