import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 1.2e+6) => { // default timeout of 20 minutes
    const timerRef = useRef(null);

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(onIdle, timeout);
    };

    const handleEvents = () => {
        resetTimer();
    };

    useEffect(() => {
        const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
        events.forEach(event => window.addEventListener(event, handleEvents));
        resetTimer();

        return () => {
            events.forEach(event => window.removeEventListener(event, handleEvents));
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return { resetTimer };
};

export default useIdleTimer;
