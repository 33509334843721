import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import patient from "../SVGs/Patient.svg";
import scan from "../SVGs/Scan.svg";
import file from "../SVGs/File.svg";
import exit from "../SVGs/Exit.svg";
import vector from "../SVGs/Vector.svg";

const SidebarPatient = () => {
    const location = useLocation();
    const getIconStyle = (path) => {
        return location.pathname === path
            ? {
                backgroundColor: "#C5ECE5",
            }
            : {};
    };
    return (
        <div className="sidebar">

            <Link to="/profile">
                <div
                    className="sidebar-icon-container"
                    style={getIconStyle("/profile")}
                >
                    <img src={patient} alt="Patient" className="sidebar-icon" />
                    <span className="side-tooltip">Patient</span>
                </div>
            </Link>

            <Link to="/scan">
                <div className="sidebar-icon-container" style={getIconStyle("/scan")}>
                    <img src={scan} alt="Scan" className="sidebar-icon" />
                    <span className="side-tooltip">Scan</span>
                </div>
            </Link>

            <Link to="/files">
                <div className="sidebar-icon-container" style={getIconStyle("/files")}>
                    <img src={file} alt="File" className="sidebar-icon" />
                    <span className="side-tooltip">File</span>
                </div>
            </Link>
        </div>
    );
};

export default SidebarPatient;
