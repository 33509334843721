export default{
    subject: 'neuro42 MR study session',
    message: `Hello Mr./ Ms. XYZ,

We appreciate your interest in participating as a volunteer for the brain MRI study at neuro42. After careful consideration of study requirements, we would like to notify you that we will not be able to enroll you into the study at this time. If our requirements change in the future, we will notify you to reevaluate your interest. 

Best regards, 

XYZ 
Study Coordinator 
neuro42, Inc.  
(628) 231-8123| study@neuro42.com 
www.neuro42.ai 

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto. `,
}