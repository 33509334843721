export default{
    subject: 'Thank you - neuro42 MR Study Session',
    message: `Hello Mr./ Ms. XYZ,

Thank you for participating in the neuro42 MRI study. We deeply appreciate the time and effort you expended getting to us. Without volunteers like you, it would be extremely difficult for us to make the rapid advances we are making in our next generation medical devices. 

I have attached a scanned copy of the consent form signed by you for your reference.  

If you would like to receive the MRI scans from a conventional 3T scanner scanned at UCSF in a standard medical format called DICOM, please provide us your mailing address and we shall mail it to you. These images can be forwarded to your primary doctor, neurologist, or neurosurgeon to become part of your permanent medical records. Since these images are yours, you may use them as you would a photograph of yourself. For example, you might put them in a journal or blog documenting your journey or include them in a talk or presentation to a support group. The subject name in these images is given as your neuro42 subject i.d. number, not your actual name, to protect your identity. We recommend using caution when sharing personal health information like MRI scans online. A good rule of thumb is to think of your MRI scans as like your address and telephone number. You don’t necessarily want everyone to know where you live. 

If you took photographs while at neuro42 and you would like to share pictures that include the neuro42 premises, employees, or equipment, we ask that you please first contact us to review them for possible privacy issues and proprietary information. Most likely we will be happy to agree to your proposed use, but we would like to protect the company and our fabulous team of people, just in case. 

Thank you again for joining the neuro42 family. Please expect future emails detailing our progress, announcing new research projects and presentations, and other newsworthy topics. If these get too much there will always be a way to opt out. But we hope you will join us for the long-term journey we are on. 

Best regards,  

Parimal Joshi   

Study Coordinator  

neuro42, Inc.   

(628) 231-8123 | study@neuro42.com  

www.neuro42.ai  



This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto. `,
}