import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OperatorPatientFeedback.css";
import useIdleTimer from "../../useIdleTimer"

const OperatorPatientFeedback = ({ patient }) => {
  const [responses, setResponses] = useState([]);
  const source = axios.CancelToken.source();

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
        const response = await axios.get(
          `/operator/fetch_responses/${patient.email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResponses(response.data.data);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/operator/refresh/",
            {},
            { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            await fetchData();
          } else {
            console.error("Refresh failed at feedback", error);
            handleLogout();
          }
        } else {
          console.error("Error fetching data at feedback ", error);
        }
      }
    };

    fetchData();
  }, [patient.email]);

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div>
      <div>
        <h2 className="section-h2">Patient Information</h2>
        <div className="response-item">
          <li>Name : {responses["Name"]}</li>
          <li>Email Address : {responses["Email Address"]}</li>
        </div>
      </div>
      <div>
        <h2 className="section-h2">Scan Satisfaction</h2>
        <div className="response-item">
          <li>
            How satisfied were you with the scan? * : {responses["question1"]}
          </li>
        </div>
      </div>
      <div>
        <h2 className="section-h2">Logistics Satisfaction</h2>
        <div className="response-item">
          <li>How satisfied were you with the logistics? *</li>
          <ul>
            <li>Accommodation : {responses["question2"]}</li>
            <li>Welcome kit : {responses["question3"]}</li>
            <li>Communication emails : {responses["question4"]}</li>
            <li>Transportation : {responses["question5"]}</li>
            <li>Comfort with Staff : {responses["question6"]}</li>
            <li>Duration of the scan : {responses["question7"]}</li>
          </ul>
        </div>
      </div>
      <div>
        <h2 className="section-h2">Enrollment Process</h2>
        <div className="response-item">
          <li>
            How would you rate the overall enrollment process? :{" "}
            {responses["question8"]}
          </li>
          {/* Add other questions related to the enrollment process */}
          <li>
            Any additional comments/feedback regarding the overall event? :{" "}
            {responses["question9"]}
          </li>
        </div>
      </div>
    </div>
  );
};

export default OperatorPatientFeedback;
