import React from "react";
import NavBar from "./NavBar";
import prodImage from "../../Images/prodImage3.png";
import AdminMindy from "../../Images/AboutUs.png";
import "./AboutUs.css";
import Footer from "../../Footer/Footer";
const AboutUs = () => {
  return (
    <>
      <NavBar />
      <div className="aboutus-background">
        <div className="aboutus-image">
          <img src={AdminMindy} alt="AdminMindy" />
        </div>
        <div className="combine-banner">
          <div className="aboutus-page-logo">
            <p>
              <span className="neuro-aboutus">neuro</span>
              <span className="fortytwo-aboutus">42</span>{" "}
              {/* <span className="health-aboutus">Health</span> */}
            </p>
          </div>
          <div className="tag-line-aboutus">
            <p>The Next Evolution of MRI and Robotics</p>
          </div>
        </div>
      </div>
      <div className="about-us-container">
        <section className="about-section">
          <h2>Welcome to neuro42, Inc.!</h2>
          <p>
            neuro42, Inc. is a cutting-edge medtech company dedicated to
            advancing futuristic technologies for screening, diagnosis, and
            targeted treatment of neurological conditions. Our innovative
            solutions aim to revolutionize healthcare by providing accessible
            and efficient point-of-care imaging and interventions.
          </p>
          <br />
          <p>
            It is founded by a team of serial entrepreneurs and innovators with
            a shared vision of transforming healthcare. Our diverse team brings
            together expertise from various disciplines to drive innovation and
            make a positive impact in the field of neurology.
          </p>
        </section>

        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            Our mission at neuro42 is to improve lives through our
            patient-centric solutions. We are committed to leveraging our
            expertise in MRI technology, robotics, and artificial intelligence
            to develop groundbreaking innovations that empower healthcare
            professionals and enhance patient outcomes.
          </p>
        </section>

        <section className="team-section">
          {/* <h2>Meet Our Team</h2>
          <p>
            neuro42 is founded by a team of serial entrepreneurs and innovators
            with a shared vision of transforming healthcare. Our diverse team
            brings together expertise from various disciplines to drive
            innovation and make a positive impact in the field of neurology.
          </p> */}
        </section>

        <section className="products-section">
          <h2>Our Products & Services</h2>
          <p>
            We are developing and commercializing a portable MRI, robotic, and
            AI platform that is backed by over 30 patents. This platform enables
            point-of-care imaging and interventions under live image guidance,
            providing healthcare professionals with the tools they need to
            deliver personalized care to patients.
          </p>
          <img src={prodImage} alt="Product neuro42" />

          {/* Product/service descriptions can be inserted here */}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
