import React from "react";
import "./PatientProfileNew.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import AddIcon from "../Images/Add Icon.png";
import AddIconWhite from "../Images/Add Icon white.png";
import Footer from "./Footer";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import edit from "../Operator/SVGs/edit.svg";
import Sidebar from "./components/Sidebar";
import SidebarPatient from "./components/SidebarPatient";
import cross from "../Images/fluent_warning-32-regular.png";

const patient_id = "n42-00003";

const PatientProfile = () => {
  const project = sessionStorage.getItem("project");
  const [physicians, setPhysicians] = useState([]);
  const role = sessionStorage.getItem("role");

  const storedUserObjectString = sessionStorage.getItem("userObject");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;
  const patient =
    role === "Patient"
      ? storedUserObject
      : JSON.parse(sessionStorage.getItem("Patient")) || {};

  const [editedPatient, setEditedPatient] = useState({ patient });
  const [prevPatient, setPrevPatient] = useState({ patient });
  const [editedMedicalInfo, setEditedMedicalInfo] = useState({});
  const [prevMedicalInfo, setPrevMedicalInfo] = useState({});
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({
    mobile_number: "",
    last_name: "",
    first_name: "",
    address_1: "",
    city: "",
    state: "",
    zip_code: "",
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    physician: false,
    status: false,
    type: false,
    gender: false,
    race: false,
    indication: false,
  });

  const [selectedValues, setSelectedValues] = useState({
    physician: prevPatient.physician_name || "",
    status: prevPatient.register_status || "",
    type: prevPatient.patient_type || "",
    gender: prevPatient.gender || "",
    race: prevPatient.race || "",
    indication: prevPatient.indication || [],
  });

  const states = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ];

  const [selectedState, setSelectedState] = useState(
    editedPatient.state || "Select State"
  );
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);

  const handleStateSelect = (stateCode) => {
    setSelectedState(stateCode);
    setEditedPatient((prevPatient) => ({
      ...prevPatient,
      state: stateCode,
    }));
    setIsStateDropdownOpen(false);
  };

  const statuses = [
    "Accepted",
    "Scanned",
    "Withdrawn",
    "On Hold",
    "Screened",
    "Other",
  ];
  const types = ["Volunteer", "Patient"];
  const genders = ["Male", "Female", "Other"];
  const races = [
    "Asian",
    "Black or African American",
    "Hispanic or Latino",
    "American Indian or Alaska Native",
    "White",
    "Native Hawaiian or Other Pacific Islander",
    "Two or More Races",
    "N/A",
    "Other",
  ];
  const injuryTypes = [
    "Aneurysm",
    "Stroke",
    "Traumatic Brain Injury",
    "Malformation",
    "Brain Tumor",
    "Hemorrhage",
    "Pineal Cyst",
    "Meningioma",
    "Hydrocephalus",
    "Glioblastoma",
    "Cerebral Palsy",
    "Epilepsy",
    "Cavernous hemangioma",
    "Unclear",
    "AVM",
    "Meningitis",
    "Other",
  ];

  const handleDropdownSelect = (key, value) => {
    // If the key is "indication", handle multiple selections
    if (key === "indication") {
      setSelectedValues((prevValues) => {
        const newIndication = prevValues.indication.includes(value)
          ? prevValues.indication.filter((item) => item !== value)
          : [...prevValues.indication, value];

        return {
          ...prevValues,
          [key]: newIndication,
        };
      });

      setPrevPatient((prevPatient) => {
        const newIndication = prevPatient.indication.includes(value)
          ? prevPatient.indication.filter((item) => item !== value)
          : [...prevPatient.indication, value];

        return {
          ...prevPatient,
          [key]: newIndication,
        };
      });

      setEditedPatient((prevPatient) => {
        const newIndication = prevPatient.indication.includes(value)
          ? prevPatient.indication.filter((item) => item !== value)
          : [...prevPatient.indication, value];

        return {
          ...prevPatient,
          indication: newIndication,
        };
      });
    } else {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));

      setPrevPatient((prevPatient) => ({
        ...prevPatient,
        [key]: value,
      }));

      setEditedPatient((prevPatient) => {
        if (key === "physician" || key == "MRI-Tech" || key == "Nurse") {
          return {
            ...prevPatient,
            physician_name: value,
          };
        } else if (key === "type") {
          return {
            ...prevPatient,
            patient_type: value,
          };
        } else {
          return {
            ...prevPatient,
            [key]: value,
          };
        }
      });
    }
  };

  const toggleDropdown = (key) => {
    setIsDropdownOpen((prevOpen) => ({
      ...prevOpen,
      [key]: !prevOpen[key],
    }));
  };

  const getToken = () => {
    return sessionStorage.getItem(
      role === "Admin"
        ? "admin_token"
        : role === "Operator" || role === "Physician"
          ? "operator_token"
          : role === "Patient"
            ? "patient_token"
            : null
    );
  };

  const hasErrors = Object.values(errors).some((error) => error !== "");

  const handleGetPhysicians = async (e) => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      const response = await axios.get(`/operator/get_all_physicians/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          project: project,
        },
      });

      if (response.status === 200) {
        setPhysicians(response.data.operators);
      } else {
        setErrorMessage(
          `Failed to fetch physicians. Status: ${response.status}`
        );
        setIsErrorOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while fetching the physicians. Please try again."
      );
      setIsErrorOpen(true);
      console.error("Error adding patient:", error);
    }
  };

  useEffect(() => {
    if (isEditing) {
      handleGetPhysicians();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      setSelectedValues({
        physician: prevPatient.physician_name || "",
        status: prevPatient.register_status || "",
        type: prevPatient.patient_type || "",
        gender: prevPatient.gender || "",
        race: prevPatient.race || "",
        indication: prevPatient.indication || "",
      });
    }
  }, [isEditing]);

  // const handleIdle = () => {
  //   console.log("User is idle, logging out...");
  //   window.location.href = "/physicians";
  // };

  // useIdleTimer(handleIdle, 1.2e6); // 20 minutes timeout

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     const token = getToken();
  //     const verifyEndpoint =
  //       role === "Patient"
  //         ? "/patient/verify_token/"
  //         : role === "Admin"
  //         ? "/admin/verify_token/"
  //         : "/operator/verify_token/";

  //     const refreshEndpoint =
  //       role === "Patient"
  //         ? "/patient/refresh/"
  //         : role === "Admin"
  //         ? "/admin/refresh/"
  //         : "/operator/refresh/";

  //     if (token) {
  //       try {
  //         const response = await axios.get(verifyEndpoint, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             project,
  //           },
  //         });

  //         if (response.status === 200) {
  //           console.log(`${role} authentication success!`);
  //           handleGetPatientInfo();
  //         } else {
  //           console.error(`Authentication failed for ${role}.`);
  //         }
  //       } catch (error) {
  //         // Handle authentication error, such as expired token
  //         if (error.request.status === 401) {
  //           try {
  //             const refreshResponse = await axios.post(
  //               refreshEndpoint,
  //               {},
  //               { withCredentials: true }
  //             );

  //             if (refreshResponse.status === 200) {
  //               const newToken = refreshResponse.data.access_token;
  //               const tokenKey =
  //                 role === "Patient" ? "patient_token" : "operator_token";
  //               sessionStorage.setItem(tokenKey, newToken);

  //               await handleGetPatientInfo();
  //             } else {
  //               console.error("Token refresh failed:", refreshResponse);
  //               handleLogout();
  //             }
  //           } catch (refreshError) {
  //             console.error("Refresh error:", refreshError);
  //             handleLogout();
  //           }
  //         } else {
  //           console.error("Error verifying token:", error);
  //         }
  //       }
  //     }
  //     setIsLoading(false);
  //   };

  //   checkAuth();
  // }, []);

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  const handleGetPatientInfo = async () => {
    const token = getToken();

    try {
      const response = await axios.get(
        `/operator/get_patient_info/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (response.status === 200) {
        setEditedPatient(response.data.patient);

        setPrevPatient(response.data.patient);
        console.log(prevPatient);
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/",
          {},
          { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem(
            "operator_token",
            refreshResponse.data.access_token
          );
          await handleGetPatientInfo();
        } else {
          console.error("Refresh failed at patient profile");
          handleLogout();
        }
      }
    }
  };

  useEffect(() => {
    handleGetPatientInfo();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    switch (name) {
      case "mobile_number":
        if (!isValidMobileNumber(value)) {
          error = "Invalid mobile number";
        }
        break;
      case "last_name":
        if (value.trim() === "") {
          error = "Last name is required";
        }
        break;
      case "mrn_number":
        if (value.trim() === "") {
          error = "MRN number is required";
        }
        break;
      case "first_name":
        if (value.trim() === "") {
          error = "First name is required";
        }
        break;
      case "address_1":
        if (value.trim() === "") {
          error = "Address is required";
        }
        break;
      case "city":
        if (value.trim() === "") {
          error = "City is required";
        }
        break;
      case "state":
        if (value === "State") {
          error = "State is required";
        }
        break;
      case "email":
        if (!isValidEmail(value)) {
          error = "Invalid Email";
        }
        break;
      case "zip_code":
        if (value.trim() === "") {
          error = "Zip code is required";
        } else if (!isValidZipCode(value)) {
          error = "Invalid Zip code";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setEditedPatient((prevPatient) => ({
      ...prevPatient,
      [name]: value,
    }));
  };

  const isValidMobileNumber = (number) => {
    return /^\d{10}$/.test(number);
  };
  const isValidZipCode = (number) => {
    return /^\d{5}$/.test(number);
  };
  const isValidEmail = (number) => {
    return /^(?!\.)[\w\.-]+(\.[\w\.-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*(\.[a-zA-Z]{2,})$/.test(
      number
    );
  };

  const handleSave = async () => {
    // Check for errors in input fields
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      // Update state to highlight problematic fields and provide feedback to the user
      setEditedPatient((prevPatient) => ({
        ...prevPatient,
        first_name: errors.first_name ? "" : editedPatient.first_name,
        last_name: errors.last_name ? "" : editedPatient.last_name,
        mobile_number: errors.mobile_number ? "" : editedPatient.mobile_number,
        mrn_number: errors.mrn_number ? "" : editedPatient.mobile_number,
      }));
      //return;
    }

    try {
      const token = getToken();
      const updateResponsePatient = await axios.post(
        `/operator/update_patients/`,
        { editedPatient },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (updateResponsePatient.status === 200) {
        setIsEditing(false);
        const updatedResponse = await axios.get(
          `/operator/get_patient_info/${patient.email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              project,
            },
          }
        );
        if (updatedResponse.status === 200) {
          setEditedPatient(updatedResponse.data.patient);
          setPrevPatient(updatedResponse.data.patient);
          // setEditedMedicalInfo(updatedResponse.data.medicalInformation);
          // setPrevMedicalInfo(updatedResponse.data.medicalInformation);
        } else {
          setIsEditing(false);
          setErrorMessage(
            "An error occurred while saving the patient information. Please try again."
          );
          setIsErrorOpen(true);
        }
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while saving the patient information. Please try again."
      );
      setIsErrorOpen(true);
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/",
          {},
          { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem(
            "operator_token",
            refreshResponse.data.access_token
          );
          await handleSave();
        } else {
          console.log("Refresh failed");
          handleLogout();
        }
      }
      if (error.request.status === 400 || error.request.status === 500) {
        console.log(error.response.data);
        setErrors(error.response.data);
        // setShowModal(true);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setEditedPatient(prevPatient);
    setEditedMedicalInfo(prevMedicalInfo);
    setErrors({
      mobile_number: "",
      last_name: "",
      first_name: "",
      address_1: "",
      city: "",
      state: "",
      zip_code: "",
      email: "",
    });
    setIsEditing(false);
  };

  // return isLoading ? (

  //   <div className="loading-spinner-overlay">
  //     <div className="bouncing-loader">
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //     </div>
  //   </div>

  // ) : (
  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        {role === "Patient" ? <SidebarPatient /> : <Sidebar />}
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line">
              <div className="title-container">
                <h2 className="inner-page-title">Profile</h2>
                <div className="tooltip-container">
                  <img
                    src={questionMark}
                    alt="info-icon"
                    className="info-icon"
                  />
                  <span className="tooltip-text">
                    View patient profile here
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="loading-spinner-overlay">
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="patient-profile-container">
              <div className="middle-line">
                <div className="profile-title-container">
                  <h2 className="inner-page-title">
                    {prevPatient.first_name} {prevPatient.last_name}
                  </h2>
                  <h2 className="header-value-2">
                    Patient ID: {prevPatient.patient_id}
                  </h2>
                </div>

                {isEditing ? (
                  <div className="form-buttons-edit-profile">
                    <button
                      type="button"
                      className="edit-profile-cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="edit-profile"
                      onClick={handleSave}
                      disabled={hasErrors}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="form-buttons-edit">
                    <button
                      onClick={() => setIsEditing(true)}
                      className="edit-profile"
                    >
                      <p>Edit</p>{" "}
                      <img src={edit} alt="logo" className="edit-svg" />
                    </button>
                  </div>
                )}
              </div>

              <div className="profile-rectangles">
                {/* MRN Number */}
                <div className="info-wrapper">
                  {role !== "Patient" && (
                    <div className="id-number-container">
                      <span className="id-key">MRN Number</span>
                      <span className="colon">:</span>
                      <span className="id-value">
                        {isEditing && role !== "Patient" ? (
                          <input
                            type="text"
                            name="mrn_number"
                            value={editedPatient.mrn_number}
                            onChange={handleInputChange}
                            className="profile-value-5 input-edit"
                          />
                        ) : (
                          <div className="profile-value-5">
                            {prevPatient.mrn_number || ""}
                          </div>
                        )}
                      </span>
                    </div>
                  )}

                  {/* Physician Name Custom Dropdown */}
                  <div
                    className={
                      role === "Patient"
                        ? "name-container-patient"
                        : "name-container"
                    }
                  >
                    <span
                      className={
                        role === "Patient" ? "name-key-patient" : "name-key"
                      }
                    >
                      Physician Name
                    </span>
                    <span
                      className={role === "Patient" ? "colon-patient" : "colon"}
                    >
                      :
                    </span>
                    <span
                      className={
                        role === "Patient" ? "name-value-patient" : "name-value"
                      }
                    >
                      {isEditing && role !== "Patient" ? (
                        <div className="custom-dropdown-container">
                          <div
                            className="custom-dropdown-header"
                            onClick={() => toggleDropdown("physician")}
                          >
                            {selectedValues.physician || "Select Physician"}
                            <span
                              className={`custome-dropdown-arrow${isDropdownOpen.physician ? "open" : ""
                                }`}
                            >
                              ▼
                            </span>
                          </div>
                          {isDropdownOpen.physician && (
                            <ul className="custom-dropdown-list">
                              {physicians.map((physician) => (
                                <li
                                  key={physician.provider_id}
                                  onClick={() =>
                                    handleDropdownSelect(
                                      "physician",
                                      `${physician.first_name} ${physician.last_name}`
                                    )
                                  }
                                  className={`custom-dropdown-item ${selectedValues.physician ===
                                    `${physician.first_name} ${physician.last_name}`
                                    ? "selected"
                                    : ""
                                    }`}
                                >
                                  {physician.first_name} {physician.last_name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ) : (
                        <div
                          className={
                            role === "Patient"
                              ? "profile-value-5-patient"
                              : "profile-value-5"
                          }
                        >
                          {prevPatient.physician_name || ""}
                        </div>
                      )}
                    </span>
                  </div>
                </div>

                {/* Status Custom Dropdown */}
                {role !== "Patient" && (
                  <div className="info-wrapper">
                    <div className="id-number-container">
                      {/* <div className="id-number-row"> */}
                      <span className="id-key">Status</span>
                      <span className="colon">:</span>
                      <span className="details-value">
                        {isEditing ? (
                          <div className="custom-dropdown-container status-dropdown">
                            <div
                              className="custom-dropdown-header"
                              onClick={() => toggleDropdown("status")}
                            >
                              {selectedValues.status || "Select Status"}
                              <span
                                className={`custom-dropdown-arrow ${isDropdownOpen.status ? "open" : ""
                                  }`}
                              >
                                ▼
                              </span>
                            </div>
                            {isDropdownOpen.status && (
                              <ul className="custom-dropdown-list">
                                {statuses.map((status) => (
                                  <li
                                    key={status}
                                    onClick={() =>
                                      handleDropdownSelect("status", status)
                                    }
                                    className={`custom-dropdown-item ${selectedValues.status === status
                                      ? "selected"
                                      : ""
                                      }`}
                                  >
                                    {status}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          <div className="profile-value-5">
                            {prevPatient.register_status || ""}
                          </div>
                        )}
                      </span>
                      {/* </div> */}
                    </div>

                    {/* Type Custom Dropdown */}
                    <div className="name-container">
                      <span className="name-key">Type</span>
                      <span className="colon">:</span>
                      <span className="name-value">
                        {isEditing ? (
                          <div className="custom-dropdown-container">
                            <div
                              className="custom-dropdown-header"
                              onClick={() => toggleDropdown("type")}
                            >
                              {selectedValues.type || "Select Type"}
                              <span
                                className={`custom-dropdown-arrow ${isDropdownOpen.type ? "open" : ""
                                  }`}
                              >
                                ▼
                              </span>
                            </div>
                            {isDropdownOpen.type && (
                              <ul className="custom-dropdown-list">
                                {types.map((type) => (
                                  <li
                                    key={type}
                                    onClick={() =>
                                      handleDropdownSelect("type", type)
                                    }
                                    className={`custom-dropdown-item ${selectedValues.type === type
                                      ? "selected"
                                      : ""
                                      }`}
                                  >
                                    {type}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          <div className="profile-value-5">
                            {prevPatient.patient_type || ""}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {/* Mobile Number */}
                <div className="container-wrapper">
                  <div className="container-1">
                    <div className="details-container-container1">
                      <div className="details-row">
                        <span className="details-key">Mobile Number</span>
                        <span className="detail-colon">:</span>
                        <span className="details-value">
                          {isEditing ? (
                            <input
                              type="text"
                              name="mobile_number"
                              value={editedPatient.mobile_number}
                              onChange={handleInputChange}
                              className={`details-value-5 input-edit ${errors.mobile_number ? "input-error" : ""
                                }`}
                              required
                            />
                          ) : (
                            <div className="details-value-5">
                              {prevPatient.mobile_number || ""}
                            </div>
                          )}
                          {errors.mobile_number && (
                            <div className="error-message-edit-patient">
                              {errors.mobile_number}
                            </div>
                          )}
                        </span>
                      </div>

                      {/* Email */}
                      <div className="details-row">
                        <span className="details-key">Email</span>
                        <span className="detail-colon">:</span>
                        <span className="details-value">
                          {isEditing ? (
                            <input
                              type="text"
                              name="email"
                              value={editedPatient.email}
                              onChange={handleInputChange}
                              className={`details-value-5 input-edit ${errors.email ? "input-error" : ""
                                }`}
                              required
                            />
                          ) : (
                            <div className="profile-value-5">
                              {prevPatient.email || ""}
                            </div>
                          )}
                          {errors.email && (
                            <div className="error-message-edit-patient">
                              {errors.email}
                            </div>
                          )}
                        </span>
                      </div>

                      {/* Age */}
                      <div className="details-row">
                        <span className="details-key">Age</span>
                        <span className="detail-colon">:</span>
                        <span className="details-value">
                          {isEditing ? (
                            <input
                              type="text"
                              name="age"
                              value={editedPatient.age}
                              onChange={handleInputChange}
                              className="details-value-5 input-edit"
                              required
                            />
                          ) : (
                            <div className="profile-value-5">
                              {prevPatient.age || ""}
                            </div>
                          )}
                        </span>
                      </div>

                      {/* Gender Custom Dropdown */}
                      <div className="details-row">
                        <span className="details-key">Gender</span>
                        <span className="detail-colon">:</span>
                        <span className="details-value">
                          {isEditing ? (
                            <div className="custom-dropdown-container">
                              <div
                                className="custom-dropdown-header"
                                onClick={() => toggleDropdown("gender")}
                              >
                                {selectedValues.gender || "Select Gender"}
                                <span
                                  className={`custom-dropdown-arrow ${isDropdownOpen.gender ? "open" : ""
                                    }`}
                                >
                                  ▼
                                </span>
                              </div>
                              {isDropdownOpen.gender && (
                                <ul className="custom-dropdown-list">
                                  {genders.map((gender) => (
                                    <li
                                      key={gender}
                                      onClick={() =>
                                        handleDropdownSelect("gender", gender)
                                      }
                                      className={`custom-dropdown-item ${selectedValues.gender === gender
                                        ? "selected"
                                        : ""
                                        }`}
                                    >
                                      {gender}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <div className="profile-value-5">
                              {prevPatient.gender || ""}
                            </div>
                          )}
                        </span>
                      </div>

                      {/* Race Custom Dropdown */}
                      <div className="details-row">
                        <span className="details-key">Race</span>
                        <span className="detail-colon">:</span>
                        <span className="details-value">
                          {isEditing ? (
                            <div className="custom-dropdown-container race-dropdown">
                              <div
                                className="custom-dropdown-header"
                                onClick={() => toggleDropdown("race")}
                              >
                                {selectedValues.race || "Select Race"}
                                <span
                                  className={`custom-dropdown-arrow ${isDropdownOpen.race ? "open" : ""
                                    }`}
                                >
                                  ▼
                                </span>
                              </div>
                              {isDropdownOpen.race && (
                                <ul className="custom-dropdown-list race-dropdown">
                                  {races.map((race) => (
                                    <li
                                      key={race}
                                      onClick={() =>
                                        handleDropdownSelect("race", race)
                                      }
                                      className={`custom-dropdown-item ${selectedValues.race === race
                                        ? "selected"
                                        : ""
                                        }`}
                                    >
                                      {race}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <div className="profile-value-5">
                              {prevPatient.race || ""}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="container-2">
                    <div className="details-container">
                      <div className="container2-details">
                        {/* Indication */}
                        <div className="details-row">
                          <span className="details-key">Indication</span>
                          <span className="detail-colon">:</span>
                          <span className="details-value">
                            {isEditing && role !== "Patient" ? (
                              <div className="custom-dropdown-container">
                                <div
                                  className="custom-dropdown-header"
                                  onClick={() => toggleDropdown("indication")}
                                >
                                  {selectedValues.indication.length > 0
                                    ? selectedValues.indication.join(", ")
                                    : "Select Indication"}
                                  <span
                                    className={`custom-dropdown-arrow ${isDropdownOpen.indication ? "open" : ""
                                      }`}
                                  >
                                    ▼
                                  </span>
                                </div>
                                {isDropdownOpen.indication && (
                                  <ul className="custom-dropdown-list">
                                    {injuryTypes.map((injury) => (
                                      <li
                                        key={injury}
                                        onClick={() =>
                                          handleDropdownSelect(
                                            "indication",
                                            injury
                                          )
                                        }
                                        className={`custom-dropdown-item ${selectedValues.indication.includes(
                                          injury
                                        )
                                          ? "selected"
                                          : ""
                                          }`} // Highlight selected items
                                      >
                                        {injury}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            ) : (
                              <div className="profile-value-indication">
                                {Array.isArray(prevPatient.indication) &&
                                  prevPatient.indication.length > 0
                                  ? prevPatient.indication.join(", ")
                                  : ""}
                              </div>
                            )}
                          </span>
                        </div>

                        {/* Address */}
                        <div className="details-row">
                          <span className="details-key">Address</span>
                          <span className="detail-colon">:</span>
                          <span className="details-value">
                            {isEditing ? (
                              <>
                                <div className="address-lines">
                                  <input
                                    type="text"
                                    name="address_1"
                                    placeholder="Address line-1"
                                    value={editedPatient.address_1}
                                    onChange={handleInputChange}
                                    className="details-value-5 input-edit"
                                  />
                                  <input
                                    type="text"
                                    name="address_2"
                                    placeholder="Address line-2"
                                    value={editedPatient.address_2}
                                    onChange={handleInputChange}
                                    className="details-value-5 input-edit"
                                  />
                                  <div className="address-line-last">
                                    <input
                                      type="text"
                                      name="city"
                                      placeholder="City"
                                      value={editedPatient.city}
                                      onChange={handleInputChange}
                                      className="details-value-5 input-edit"
                                    />
                                    <input
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={editedPatient.state}
                                      onChange={handleInputChange}
                                      className="details-value-5 input-edit"
                                    />
                                    <input
                                      type="text"
                                      name="zip_code"
                                      placeholder="Zip"
                                      value={editedPatient.zip_code}
                                      onChange={handleInputChange}
                                      className="details-value-5 input-edit"
                                    />
                                  </div>
                                </div>
                                {errors.address_1 && (
                                  <div className="error-message">
                                    {errors.address_1}
                                  </div>
                                )}
                                {errors.city && (
                                  <div className="error-message">
                                    {errors.city}
                                  </div>
                                )}
                                {errors.state && (
                                  <div className="error-message">
                                    {errors.state}
                                  </div>
                                )}
                                {errors.zip_code && (
                                  <div className="error-message">
                                    {errors.zip_code}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="address-lines">
                                <div className="profile-value-5">
                                  {prevPatient.address_1 || ""}
                                </div>
                                <div className="profile-value-5">
                                  {prevPatient.address_2 || ""}
                                </div>
                                <div className="address-line-last">
                                  <div className="profile-value-5">
                                    {prevPatient.city || ""}
                                  </div>
                                  <div className="profile-value-5">
                                    {prevPatient.state || ""}
                                  </div>
                                  <div className="profile-value-5">
                                    {prevPatient.zip_code || ""}
                                  </div>
                                </div>
                              </div>
                            )}
                          </span>
                        </div>

                        {/* Notes */}
                        <div className="details-row-notes">
                          <span className="details-key">Notes</span>
                          <span className="detail-colon">:</span>
                          <span className="details-value-notes">
                            {isEditing && role !== "Patient" ? (
                              <textarea
                                name="notes"
                                value={editedPatient.notes}
                                onChange={handleInputChange}
                                className="details-value-5-notes input-edit"
                                rows="4"
                                placeholder="Add notes here..."
                              />
                            ) : (
                              <div className="profile-value-5-notes">
                                {prevPatient.notes || "No notes"}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isErrorOpen && (
            <div className="error-modal-overlay">
              <div className="error-modal-content">
                <div className="error-modal-header">
                  <img src={cross} alt="Character" className="cross-image" />
                </div>
                <h2>Oh Snap!</h2>
                <p>{errorMessage}</p>
                <div className="error-modal-actions">
                  <button
                    className="error-ok-button"
                    onClick={() => setIsErrorOpen(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
  //   );
};

export default PatientProfile;
