import React, { useState, useEffect, useRef } from "react";
import "./NavbarNew.css";
import logo from "../Images/logo.png";
import questionMark from "../Images/Vector.png";
import notification from "../Images/Vector (1).png";
// import notifications from "./notificationData.js";
import scan from "../Operator/SVGs/Scan.svg";
import file from "../Operator/SVGs/File.svg";
import downArrow from "../Images/downwardArrow.png";
import upArrow from "../Images/upArrow.png";
import FeedbackOperator from "../Operator/FeedbackOperator";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const NavbarNew = ({ username }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [activeTab, setActiveTab] = useState("All");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const userName = username;
  const role = sessionStorage.getItem("role");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pollingInterval = 60000;
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const storedUserObject = JSON.parse(storedUserObjectString);
  console.log("Stored ", storedUserObject);
  const dropdownRef = useRef(null);

  const provider_id = storedUserObject.provider_id;
  const getToken = () => {
    return sessionStorage.getItem(
      role === "Admin"
        ? "admin_token"
        : role === "Operator" || role === "Physician"
          ? "operator_token"
          : role === "Patient"
            ? "patient_token"
            : null
    );
  };
  const readAllNotification = async () => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      const response = await axios.get(
        `/operator/read_all_notifications/${provider_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project: project,
          },
        }
      );
      if (response.status === 200) {
        setNotificationCount(0);
      } else {
        console.error(
          "Failed to fetch patients'scans. Status: " + response.status
        );
      }
      console.log("All notifications marked as read");
    } catch (error) {
      console.error("Error reading all notifications", error);
    }
  };

  const handleGetNotification = async () => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      const response = await axios.get(
        `/operator/get_all_notifications/${provider_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project: project,
          },
          params: {
            item_type: activeTab !== "All" ? activeTab : null,
          },
        }
      );

      if (response.status === 200) {
        const notificationsData = response.data.notifications;
        const count = response.data.count;
        setNotificationCount(count);
        setNotifications(notificationsData);
        if (count > 0) {
          setNotificationCount(count);
        } else {
          setNotificationCount(0);
        }
      } else {
        console.error(
          "Failed to fetch patients'scans. Status: " + response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Error fetching notifications:",
          error.response.data,
          "Status:",
          error.response.status
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const checkProfilePic = () => {
      const picExists = false;
      if (picExists) {
        setProfilePic("path-to-profile-image.png");
      }
    };
    checkProfilePic();
    const delayStart = setTimeout(() => {
      handleGetNotification();
      const intervalId = setInterval(() => {
        handleGetNotification();
      }, pollingInterval);

      return () => clearInterval(intervalId);
    }, 10000);

    return () => clearTimeout(delayStart);
  }, [activeTab]);

  const handleProfileClick = () => {
    if (role === "Admin") {
      navigate("/adminProfile");
    } else {
      navigate("/operatorProfile");
    }
  };

  const handleManageUsersClick = () => {
    navigate("/operatorList");
  };

  const handleManageScanRequests = () => {
    navigate("/adminScanRequests");
  };
  console.log("\n\n\n notifications : \n\n\n", notifications);

  const renderProfilePic = () => {
    if (profilePic) {
      return <img src={profilePic} alt="Profile" className="profile-pic" />;
    } else {
      return <div className="profile-initial-circle">{userName.charAt(0)}</div>;
    }
  };
  const handleLogout = async () => {
    // await axios.post("/common/logout/", {}, { withCredentials: true });
    localStorage.clear();
    sessionStorage.clear();
    navigate("/physicians");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const toggleNotificationDropdown = (event) => {
    // console.log("Inside notification clicked ", isNotificationOpen);
    // setIsNotificationOpen(!isNotificationOpen);
    event.preventDefault();
    if (isNotificationOpen) {
      document.removeEventListener("mousedown", handleClickOutside);
      setIsNotificationOpen(false);
    } else {
      // Open dropdown and add event listener to detect clicks outside
      setIsNotificationOpen(true);
      document.addEventListener("mousedown", handleClickOutside);
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsNotificationOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
      readAllNotification();
    }
  };
  const handleRedirect = () => {
    navigate("/notifications");
  };
  const handleQuestionmark = () => {
    navigate("/contactUs");
  };

  return (
    <nav className="navbar-landing">
      <Link to="/operatorPatientlist">
        <div className="navbar-landing-logo">
          <img src={logo} alt="logo" className="logo-image" />
        </div>
      </Link>
      <div className="navbar-landing-links">
        <ul>
          <li>
            <a href="#">
              <img
                src={questionMark}
                alt="Notifications"
                className="navbar-landing-icon"
                onClick={handleQuestionmark}
              />
            </a>
          </li>
          <li>
            <a href="#" className="notification-wrapper">
              <img
                src={notification}
                alt="Settings"
                className="navbar-landing-icon-settings"
                onClick={toggleNotificationDropdown}
              />
              {notificationCount > 0 && (
                <span className="notification-count">
                  {notificationCount > 99 ? "99+" : notificationCount}
                </span>
              )}
            </a>
            {isNotificationOpen && (
              <div ref={dropdownRef} className="notification-dropdown">
                <div className="notification-header">
                  <h4>Notification</h4>
                  <div className="notification-tabs">
                    <span
                      className={activeTab === "All" ? "active" : ""}
                      onClick={() => handleTabClick("All")}
                    >
                      All
                    </span>
                    <span
                      className={activeTab === "file" ? "active" : ""}
                      onClick={() => handleTabClick("file")}
                    >
                      Files
                    </span>
                    <span
                      className={activeTab === "image" ? "active" : ""}
                      onClick={() => handleTabClick("scan")}
                    >
                      Scans
                    </span>
                    <span
                      className={activeTab === "image" ? "active" : ""}
                      onClick={() => handleTabClick("scan")}
                    >
                      Requests
                    </span>
                  </div>
                </div>
                <div className="notification-content">
                  {notifications
                    .filter(
                      (notif) =>
                        activeTab === "All" || notif.item_type === activeTab
                    )
                    .map((notif, index) => (
                      <div
                        className={
                          notif.item_type === "file"
                            ? "notification-item-file"
                            : "notification-item"
                        }
                        key={index}
                      >
                        {notif.item_type == "file" ? (
                          <div className="item-file">
                            <img
                              src={file}
                              alt="Scan"
                              className="sidebar-icon"
                            />
                            <div className="notification-text">
                              <p className="notification-title-1">
                                File upload
                              </p>
                              <p className="notification-title-2">
                                File uploaded by{" "}
                                <span
                                  style={{
                                    color: "#8697D2",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {notif.patient_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : notif.item_type == "request" ? (
                          <div className="item-request">
                            <img
                              src={scan}
                              alt="Scan"
                              className="sidebar-icon"
                            />
                            <div className="notification-text">
                              <p className="notification-title-1">
                                Scan Request
                              </p>
                              <p className="notification-title-2">
                                Scan request Access raised by{" "}
                                <span
                                  style={{
                                    color: "#C71585",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {notif.patient_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="item-scan">
                            <img
                              src={scan}
                              alt="Scan"
                              className="sidebar-icon"
                            />
                            <div className="notification-text">
                              <p className="notification-title-1">
                                Scan upload
                              </p>
                              <p className="notification-title-2">
                                Scan uploaded for{" "}
                                <span
                                  style={{
                                    color: "#409E8D",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {notif.patient_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="see-all-notifications">
                  <button onClick={handleRedirect}>
                    See all notifications
                    <svg
                      width="9"
                      height="15"
                      viewBox="0 0 9 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.36104 8.09047C8.75156 7.69994 8.75156 7.06678 8.36104 6.67626L1.99708 0.312294C1.60655 -0.0782304 0.973387 -0.0782304 0.582863 0.312294C0.192338 0.702818 0.192338 1.33598 0.582863 1.72651L6.23972 7.38336L0.582863 13.0402C0.192338 13.4307 0.192338 14.0639 0.582863 14.4544C0.973387 14.845 1.60655 14.845 1.99708 14.4544L8.36104 8.09047ZM7.65393 6.38336H6.91056V8.38336H7.65393V6.38336Z"
                        fill="#999999"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
      <div class="divider"></div>

      <div
        className={`navbar-landing-profile ${isDropdownOpen ? "with-banner" : ""
          }`}
        onClick={toggleDropdown}
      >
        {/* <div className="navbar-landing-profile"> */}
        {renderProfilePic()}
        <div className="profile-info">
          <span className="profile-name">Hi, {userName}</span>
          <span className="profile-manage">Manage your account</span>
        </div>
        <img
          src={isDropdownOpen ? upArrow : downArrow}
          alt="Dropdown Arrow"
          className="dropdown-arrow"
        />

        {isDropdownOpen && (
          <div className="profile-banner">
            <div className="dropdown-menu">
              <ul>
                <li onClick={handleProfileClick}>My Profile</li>
                {role === "Admin" && (
                  <li onClick={handleManageUsersClick}>Manage Users</li>
                )}
                {role === "Admin" && (
                  <li onClick={handleManageScanRequests}>Manage Requests</li>
                )}
                <li onClick={handleOpenModal}>Feedback</li>
                <li>About</li>
                <li className="logout" onClick={handleLogout}>
                  Log Out
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <FeedbackOperator open={isModalOpen} onClose={handleCloseModal} />
    </nav>
  );
};

export default NavbarNew;
