import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./OperatorPatientScan.css"; // Assuming you have a separate CSS file
import useIdleTimer from "../../useIdleTimer";

const OperatorPatientScan = ({ patient }) => {
  const [scans, setScans] = useState([]);
  const [selectedScan, setSelectedScan] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/physicians";
  };

  useIdleTimer(handleIdle, 1.2e6); // 20 minutes timeout

  useEffect(() => {
    handleGetPatientScan();
  }, []);

  const handleScanClick = (scan) => {
    setSelectedScan(scan);
    console.log("Calling handleGetScanData");
    handleGetScanData();
  };

  const handleBackClick = () => {
    setSelectedScan(null);
  };

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleGetPatientScan = async () => {
    try {
      const token = getToken();

      const response = await axios.get(
        `/operator/get_patient_scan_list/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const scansWithTimestamp = response.data.map((scan) => {
          console.log("Original timestamp: ", scan.timestamp);

          const parsedDate = new Date(scan.timestamp);
          if (isNaN(parsedDate)) {
            console.error("Invalid Date for timestamp: ", scan.timestamp);
            return scan; // Return the original scan if date parsing fails
          }

          const formattedDate = parsedDate.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });

          console.log("Formatted date: ", formattedDate);

          return {
            ...scan,
            timestamp: formattedDate,
          };
        });

        setScans(scansWithTimestamp);
        console.error(
          "Failed to fetch patients'scans. Status: " + response.status
        );
      }
    } catch (error) {
      console.error("Error getting patient's scans");
    }
  };

  const handleGetScanData = async () => {
    try {
      const token = getToken();
      console.log("inside the handleGetScanData");
      const response = await axios.get("/operator/get_access_healthcare/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log("Started loding the scan in the public file");
      } else {
        console.error(
          "Failed to fetch patients'scans. Status: " + response.status
        );
      }
    } catch (error) {
      console.error("Error getting patient's scans");
    }
  };

  const handleLoadProgress = (event) => {
    const iframe = event.target;
    if (iframe && iframe.contentWindow) {
      const contentWindow = iframe.contentWindow;
      if (
        contentWindow.document &&
        contentWindow.document.readyState === "complete"
      ) {
        setLoadingProgress(100);
      } else {
        setLoadingProgress(50);
      }
    }
  };

  // const ScanData = {
  //   urls: [
  //     {
  //       id: "A1B2C3D4",
  //       name: "scan-1",
  //       url: "https://neuro42-dicom-viewer.web.app/projects/san-data-upload/locations/us-west1/datasets/neuro42-developers/dicomStores/still_trying/study/1.2.826.0.1.3680043.10.1113.4575166",
  //       timestamp: "2024-04-17T12:00:00Z",
  //     },
  //     {
  //       id: "E5F6G7H8",
  //       name: "scan-2",
  //       url: "https://viewer.ohif.org/viewer/dicomjson?url=https://ohif-dicom-json-example.s3.amazonaws.com/LIDC-IDRI-0001.json",
  //       timestamp: "2024-04-17T12:05:00Z",
  //     },
  //     {
  //       id: "I9J0K1L2",
  //       name: "scan-3",
  //       url: "https://viewer.ohif.org/viewer/dicomjson?url=https://ohif-dicom-json-example.s3.amazonaws.com/LIDC-IDRI-0001.json",
  //       timestamp: "2024-04-17T12:10:00Z",
  //     },
  //     {
  //       id: "M3N4O5P6",
  //       name: "scan-4",
  //       url: "https://viewer.ohif.org/viewer/dicomjson?url=https://ohif-dicom-json-example.s3.amazonaws.com/LIDC-IDRI-0001.json",
  //       timestamp: "2024-04-17T12:15:00Z",
  //     },
  //     {
  //       id: "Q7R8S9T0",
  //       name: "scan-5",
  //       url: "https://viewer.ohif.org/viewer/dicomjson?url=https://ohif-dicom-json-example.s3.amazonaws.com/LIDC-IDRI-0001.json",
  //       timestamp: "2024-04-17T12:20:00Z",
  //     },
  //   ],
  // };

  // if (selectedScan) {
  //   return (
  //     <div className="scan-container">
  //       <button className="view-button" onClick={handleBackClick}>
  //         Back
  //       </button>
  //       <div className="scan-details">
  //         <iframe
  //           id="myFrame"
  //           title="Embedded Page"
  //           src={`https://neuro42-dicom-viewer.web.app/projects/patient-portal-data/locations/us-west1/datasets/TestData/dicomStores/test/study/${selectedScan.seriesId}`}
  //           style={{ width: "100%", height: "800px", border: "none" }}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  if (selectedScan) {
    const url = `https://neuro42-dicom-viewer.web.app/projects/patient-portal-data/locations/us-west1/datasets/TestData/dicomStores/test/study/${selectedScan.seriesId}`;
    window.open(url);
  }

  return (
    <div className="scan-container">
      <ul className="scan-responsive-table">
        <li className="scan-table-header">
          <div className="scan-col scan-col-1">ID</div>
          <div className="scan-col scan-col-3">Timestamp</div>
        </li>
        {scans.map((doc) => (
          <li
            className="scan-table-row"
            key={doc}
            onClick={() => setSelectedScan(doc)}
          >
            <div className="scan-col scan-col-1" data-label="ID">
              {doc.seriesId}
            </div>
            <div className="scan-col scan-col-3" data-label="Timestamp">
              {doc.timestamp}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OperatorPatientScan;
