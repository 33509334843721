import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer-box">
      <div className="Footer-header">
        <h1 className="Footer-text-gray">neruo</h1>
        <h1 className="Footer-text-aqua">42</h1>
        <h1 className="Footer-text-gray">Health</h1>
      </div>
      <div className="Footer-social">
        <a
          href="https://www.facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="Footer-icon" />
        </a>
        <a
          href="https://twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="Footer-icon" />
        </a>
        <a
          href="https://www.linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn className="Footer-icon" />
        </a>
      </div>
      <div className="Footer-links">
        <a href="/privacy-policy" className="Footer-link">
          Privacy Policy
        </a>
        <span className="Footer-link-separator">|</span>
        <a href="/terms-and-conditions" className="Footer-link">
          Terms and Conditions
        </a>
      </div>
      <p className="Footer-copy">
        &copy; 2024 neuro42 Health All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
