import React from "react";
import InlineAppointmentComponent from "../Operator/InlineAppointmentComponent";

function PatientAppointment({ patient }) {
  return (
    <div>
      <InlineAppointmentComponent />
    </div>
  );
}

export default PatientAppointment;
