import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useIdleTimer from "../../useIdleTimer";

const OperatorChangePassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const handleChange = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `/operator/complete_reset_password/${token}`,
        {
          password: password,
        }
      );

      if (response.data) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.error || "Password reset failed");
      }
    } catch (error) {
      console.error("(Operator) Error resetting password:", error);
      setMessage("An unexpected error occurred", error);
    }
  };

  return (
    <div className="change-password-container">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <label>New Password:</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          required
        />

        <label>Confirm Password:</label>
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          required
        />
        <button type="submit">Reset Password</button>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default OperatorChangePassword;
