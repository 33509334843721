export default{
    subject: 'Criteria check email',
    message: `Hello Mr./ Ms. XYZ,

My name is [   ]. On behalf of neuro42, I’d like to thank you for choosing to volunteer in our MR research study. We are a medical device company located in the Bay Area. Our research focuses on MR scans of the brain using a portable MR scanner. 

Thank you for filling up the initial screening form through the Link provided. Moving forward we would like some further screening information,  




Please measure distance from top of the ear to shoulder and send it to us.  
This information will be used to determine the correct accessories required during the scans.  

I have attached a picture for your reference. Measure the distance marked by red line.  



Thank you very much for your time. We look forward to hearing from you soon.   

Best regards,  

XYZ  
Study Coordinator  
neuro42, Inc.   
(628) 231-8123 | study@neuro42.com  
www.neuro42.ai  

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto.  `,
    attachments: [{
        filename: 'image3.png',
        path: '/home/joyce/Desktop/pm_app/patient_management_webapp/frontend/patient_management/src/components/Operator/emailTemplateImages/image3.png',
        cid: 'image3', // Content ID for referencing the image in the template
      },
 ]
}