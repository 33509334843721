import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import "./PatientAuthorizationForm.css";
import axios from "axios";
import successIcon from "../Images/simple-line-icons_check.png";
import errorIcon from "../Images/fluent_warning-32-regular.png";
import NavbarAuthForm from '../Navbar/NavbarAuthForm';
import Footer from '../Operator/Footer';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const PatientAuthorizationForm = () => {
    const location = useLocation();
    const [studyId, setStudyId] = useState(null);
    const [studyDate, setStudyDate] = useState(null);
    const [studyTime, setStudyTime] = useState(null);
    const [patient, setPatient] = useState(null);
    const [project, setProject] = useState(null);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        scanDate: '',
        purpose: {
            personalUse: false,
            secondOpinion: false,
            other: false,
            otherDetails: '',
        },
        expirationDate: '',
        signature: '',
        date: '',
    });
    const handleDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        return `${year}-${month}-${day}`;
    };


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setStudyId(sessionStorage.getItem("studyId"));
        setStudyDate(sessionStorage.getItem("studyDate"));
        setPatient(JSON.parse(sessionStorage.getItem("userObject")));
        setProject(sessionStorage.getItem("project"));

        sessionStorage.removeItem("studyId");
        sessionStorage.removeItem("studyDate");
    }, [location.search]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            purpose: { ...formData.purpose, [name]: checked },
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formElement = document.querySelector(".auth-form-container");

        // Clone the form container
        const clonedForm = formElement.cloneNode(true);

        // Remove buttons from the cloned form
        const buttons = clonedForm.querySelectorAll("button");
        buttons.forEach(button => button.remove());

        // Append the cloned form to the body (temporarily)
        clonedForm.style.position = "absolute";
        clonedForm.style.top = "-9999px"; // Hide it offscreen
        document.body.appendChild(clonedForm);

        try {
            // Render the cloned form
            const canvas = await html2canvas(clonedForm, {
                scale: 3,
                useCORS: true,
            });

            // Remove the cloned form from the DOM
            document.body.removeChild(clonedForm);

            // Generate PDF
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            const pdfBlob = pdf.output("blob");

            // Prepare FormData for file upload
            const formData = new FormData();
            formData.append("file", pdfBlob, "AuthorizationForm");

            // Upload the file
            const fileUploadResponse = await axios.post(`/patient/upload_form_with_encryption/${patient.email}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "project": project,
                },
            });

            if (fileUploadResponse.status === 200 || fileUploadResponse.status === 201) {
                console.log("Form uploaded successfully");

                if (!patient || !studyId || !project) {
                    console.error("Required data missing: patient, studyId, or project");
                    setIsErrorModalOpen(true);
                    return;
                }

                const requestData = {
                    firstname: patient.first_name,
                    lastname: patient.last_name,
                    email: patient.email,
                    studyId: studyId,
                    studyDate: studyDate,
                    project: project,
                    physician_name: patient.physician_name,
                    provider_id: patient.provider_id,
                    mrn_number: patient.mrn_number,
                    patient_id: patient.patient_id,
                };

                try {
                    const pendingRequestResponse = await axios.post('/admin/update_pending_requests_admin/', requestData, {
                        headers: {
                            "Content-Type": "application/json",
                            "project": project,
                        },
                    });

                    if (pendingRequestResponse.status === 200) {
                        console.log("Pending request added successfully");
                        setIsSuccessModalOpen(true);
                    }
                } catch (error) {
                    const errorMessageFromBackend = error.response?.data?.error || "An unexpected error occurred.";
                    console.error("Error submitting pending request:", errorMessageFromBackend);
                    setErrorMessage(errorMessageFromBackend);
                    setIsErrorModalOpen(true);
                }
            } else {
                throw new Error("File upload failed.");
            }
        } catch (error) {
            console.error("Error uploading form:", error);
            setErrorMessage("Failed to upload the form as a PDF.");
            setIsErrorModalOpen(true);
        }
    };

    const handleCancel = () => {
        setFormData({
            scanDate: '',
            purpose: {
                personalUse: false,
                secondOpinion: false,
                other: false,
                otherDetails: '',
            },
            expirationDate: '',
            signature: '',
            date: '',
        });
    };

    const closeSuccessModal = () => {
        setIsSuccessModalOpen(false);
    };

    const closeErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    return (
        <>

            <NavbarAuthForm />
            <div className="auth-form-container">
                <h2>Request for MRI Scan Authorization Form</h2>
                <p>
                    This form authorizes the release of your MRI scan results and related medical information.
                    It outlines your rights regarding the release of your health information and the legal
                    considerations involved. Please review the form carefully before signing to ensure you
                    understand the terms and conditions.
                </p>

                <form onSubmit={handleSubmit}>
                    {/* MRI Scan Details */}
                    <div className="form-section">
                        <label>MRI Scan date:</label>
                        <p>Please enter the data of the scan.</p>
                        <input
                            type="date"
                            name="scanDate"
                            value={studyDate ? handleDate(studyDate) : ""}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Purpose of Disclosure */}
                    <div className="form-section">
                        <label>Purpose of Disclosure:</label>
                        <p>Please select the purpose of Disclosure from on of the following:</p>
                        <div className="podLabels">
                            <label>
                                <input
                                    type="checkbox"
                                    name="personalUse"
                                    checked={formData.purpose.personalUse}
                                    onChange={handleCheckboxChange}
                                />
                                Personal Use
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="secondOpinion"
                                    checked={formData.purpose.secondOpinion}
                                    onChange={handleCheckboxChange}
                                />
                                Second Opinion
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="other"
                                    checked={formData.purpose.other}
                                    onChange={handleCheckboxChange}
                                />
                                Other
                            </label>
                            {formData.purpose.other && (
                                <input
                                    type="text"
                                    name="otherDetails"
                                    placeholder="Please enter details if you select other"
                                    value={formData.purpose.otherDetails}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        purpose: { ...formData.purpose, otherDetails: e.target.value }
                                    })}
                                />
                            )}
                        </div>
                    </div>

                    {/* Expiration Date */}
                    <div className="form-section">
                        <label>Expiration Date:</label>
                        <p>This authorization shall become effective immediately and shall remain in effect for one (1) year from the date signed unless otherwise specified here: </p>
                        <input
                            type="date"
                            name="expirationDate"
                            value={formData.expirationDate}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Authorization Text */}
                    <div className="form-section">
                        <label>Authorization:</label>
                        <p>
                            My revocation will be effective upon receipt, but will have no impact on uses or disclosures made while my authorization was valid. I have the right to receive a copy of this authorization and to inspect and obtain a copy of my health information for as long as the information is maintained by the hospital. I understand that the hospital will not receive compensation for the use or disclosure of my health information. Furthermore, I understand that state law prohibits the recipients of my health information from making further disclosures unless the recipient obtains another authorization from me or unless the disclosure is required or permitted by law.
                        </p>
                        <p>
                            I acknowledge that I have read and understand the information provided in this authorization
                            form. By signing below, I agree to the release of my health information as specified above.
                        </p>
                    </div>

                    {/* Signature and Date */}
                    {/* Signature and Date */}
                    <div className="form-section">
                        <div className="signature-date-container">
                            <div className="signature-container">
                                <label>Signature:</label>
                                <input
                                    type="text"
                                    name="signature"
                                    placeholder="Sign here"
                                    value={formData.signature}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="date-container">
                                <label>Date:</label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="form-actions-buttons">
                        <div className="button-container">
                            <button className='scan-auth-button' type="submit">Submit</button>
                            <button className='scan-auth-button' type="button" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </form>

                {/* Success Modal */}
                {isSuccessModalOpen && (
                    <div className="confirmation-modal-overlay">
                        <div className="confirmation-modal-content">
                            <div className="confirmation-modal-header">
                                <img src={successIcon} alt="Success" className="check-image" />
                            </div>
                            <h2>Success!</h2>
                            <p>Your request was submitted successfully.PLease allow us 2-3 buisness days to approve your request.</p>
                            <button onClick={closeSuccessModal}>Okay</button>
                        </div>
                    </div>
                )}

                {/* Error Modal */}
                {isErrorModalOpen && (
                    <div className="error-modal-overlay">
                        <div className="error-modal-content">
                            <div className="error-modal-header">
                                <img src={errorIcon} alt="Error" className="cross-image" />
                            </div>
                            <h2>Oh no!</h2>
                            <p>{errorMessage}</p>
                            <button className='error-ok-button' onClick={closeErrorModal}>Okay</button>
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </>
    );
};

export default PatientAuthorizationForm;
