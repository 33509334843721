import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OperatorPatientReimbursement.css";
import { BsTrash3 } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { TfiSave } from "react-icons/tfi";
import { TbArrowBackUp } from "react-icons/tb";
import "./LoadingSpinner.css"
import useIdleTimer from "../../useIdleTimer"

const OperatorPatientReimbursement = ({ patient }) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [category, setCategory] = useState("Transportation");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editAmount, setEditAmount] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editComment, setEditComment] = useState("");
  const [editCategory, setEditCategory] = useState("Transportation");
  const [editStatus, setEditStatus] = useState("Submitted");
  const [documents, setDocuments] = useState([]);
  const [editStates, setEditStates] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const fileInputRef = React.createRef();
  const [error, setError] = useState({
    amount: "",
    description: "",
    files: "",
  });
  const [errors, setErrors] = useState(null);
  const source = axios.CancelToken.source();
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubmittedRequests();
  }, [patient, accessToken, refreshToken, isLoggedIn]);

  // useEffect(() => {
  //   console.log("Submitted Requests: ", submittedRequests);
  // }, [submittedRequests]);

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleDocumentSelect = (documentName) => {
    setSelectedDocument(documentName);
  };

  const handleDoubleClick = (documentName) => {
    setSelectedDocument(documentName);
    downloadDocument();
  };

  const downloadDocument = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);

    if (selectedDoc) {
      try {
        const response = await axios.get(
          `/operator/download_reimbursement_document/${selectedDoc.file_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], { type: response.data.type });

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = selectedDoc.file_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/operator/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            await downloadDocument();
          } else {
            console.error("Refresh failed at reimbursement", error);
            handleLogout();
          }
        } else {
          console.error("Error downloading file at reimbursement", error);
        }
      }
    } else {
      setError("Please select a document");
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      setErrors(
        "Invalid file type. Please upload a PDF, PNG, JPEG, JPG, or SVG file."
      );
      setSelectedFiles(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    if (file.size > 1 * 1024 * 1024) {
      setErrors("File size exceeds 1MB limit.");
      return;
    }

    setSelectedFiles(file);
    setErrors("");
  };

  const handleEditAmountChange = (event) => {
    setEditAmount(event.target.value);
  };

  const handleEditDescriptionChange = (event) => {
    setEditDescription(event.target.value);
  };

  const handleEditCategoryChange = (event) => {
    setEditCategory(event.target.value);
  };

  const handleEditStatusChange = (event) => {
    setEditStatus(event.target.value);
  };

  const handleEditCommentChange = (event) => {
    setEditComment(event.target.value);
  };

  const handleSubmit = async () => {
    if (!amount || !description || selectedFiles.length === 0) {
      setError({
        amount: !amount ? "Amount is required" : "",
        description: !description ? "Description is required" : "",
        files: selectedFiles.length === 0 ? "File is required" : "",
      });
      return;
    }
    try {
      const token = getToken();
      const formData = new FormData();

      const amount_value = parseFloat(amount).toFixed(2);
      formData.append("amount", amount_value);
      formData.append("description", description);
      formData.append("comment", comment);
      formData.append("category", category);
      formData.append("status", "Submitted");
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());

      for (const file of selectedFiles) {
        formData.append("files", file);
      }

      const response = await axios.post(
        "/operator/add_reimbursements/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setAmount("");
        setDescription("");
        setCategory("Transportation");
        setSelectedFiles(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        fetchSubmittedRequests();
      } else {
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          setIsLoggedIn(true);
          await handleSubmit();
        } else {
          setIsLoggedIn(false);
          console.error("Refresh failed, logging out", error);
          handleLogout();
        }
      } else {
        setIsLoggedIn(false);
      }
    }
  };

  const fetchSubmittedRequests = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `/operator/fetch_reimbursements/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const reimbursements = response.data.map((reimbursement) => ({
          ...reimbursement,
          timestamp: new Date(reimbursement.date).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
        }));
        setSubmittedRequests(reimbursements);
        const documentsData = response.data.map((doc) => ({
          file_name: doc.file_name,
          file_id: doc.file_id,
          file_data: doc.file_data,
        }));
        setDocuments(documentsData);
        // submittedRequests.map((request) => {
        // });
      } else {
        console.error(
          "Failed to fetch reimbursement requests. Status:",
          response
        );
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          setIsLoggedIn(true);
          await fetchSubmittedRequests();
        } else {
          console.error("Refresh token failed at reimbursement");
          setIsLoggedIn(false);
          handleLogout();
        }
        setIsLoggedIn(false);
      }
    }
    finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setAmount("");
    setDescription("");
    setComment("");
    setCategory("Transportation");
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const calculateTotalAmount = () => {
    const totalAmount = submittedRequests.reduce(
      (total, request) => total + parseFloat(request.amount),
      0
    );

    if (isNaN(totalAmount) || typeof totalAmount !== "number") {
      console.error("Total amount is not a valid number:", totalAmount);
      return 0;
    }

    return totalAmount.toFixed(2);
  };

  const calculateTotalAmountDue = () => {
    const totalAmountDue = submittedRequests.reduce(
      (total, request) => total + parseFloat(request.paid_amount),
      0
    );

    if (isNaN(totalAmountDue) || typeof totalAmountDue !== "number") {
      console.error("Total amount due is not a valid number:", totalAmountDue);
      return 0;
    }

    return totalAmountDue.toFixed(2);
  };

  const handleEdit = (id) => {
    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [id]: true,
    }));

    const selectedEntry = submittedRequests.find(
      (request) => request.id === id
    );
    setEditAmount(selectedEntry.amount);
    setEditDescription(selectedEntry.description);
    setEditComment(selectedEntry.comment);
    setEditCategory(selectedEntry.category);
    setEditStatus(selectedEntry.status);
  };

  const handleDelete = async (id) => {
    try {
      const token = getToken();
      const response = await axios.delete(
        `/operator/delete_reimbursements/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchSubmittedRequests();
      } else {
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  const handleCancelEdit = (id) => {
    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [id]: false,
    }));
  };

  const handleSaveChanges = async (id) => {
    try {
      const token = getToken();
      const formData = new FormData();
      const amount_value = parseFloat(editAmount).toFixed(2);
      if (editStatus === "Paid") {
        formData.append("paid_amount", amount_value);
      }

      formData.append("amount", amount_value);
      formData.append("description", editDescription);
      formData.append("category", editCategory);
      formData.append("status", editStatus);
      formData.append("comment", editComment);
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());

      const response = await axios.post(
        `/operator/update_reimbursements/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        fetchSubmittedRequests();
        setEditStates((prevEditStates) => ({
          ...prevEditStates,
          [id]: false,
        }));
      } else {
        console.error("Server responded with non-OK status");
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          setIsLoggedIn(true);
          await handleSaveChanges(id);
        } else {
          setIsLoggedIn(false);
          console.error("Refresh failed, logging out", error);
          handleLogout();
        }
      } else {
        console.error("Error updating entry:", error);
        setIsLoggedIn(false);
      }
    }
  };

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <div className="patient-reimbursement-container">
      <div className="reimbursement-container-form">
        <input
          type="text"
          name="to_email"
          value={amount}
          onChange={handleAmountChange}
          placeholder={error.amount ? error.amount : "Amount ($)*"}
          required
          style={{ borderColor: error.amount ? "red" : "" }}
        />

        <input
          id="description"
          type="text"
          value={description}
          onChange={handleDescriptionChange}
          placeholder={error.description ? error.description : "Description*"}
          required
          style={{ borderColor: error.description ? "red" : "" }}
        />

        <textarea
          id="comment"
          value={comment}
          onChange={handleCommentChange}
          placeholder="Additional Comments"
        />

        <select
          id="category"
          name="category"
          value={category}
          onChange={handleCategoryChange}
        >
          <option value="Transportation">Transportation</option>
          <option value="Hotel">Hotel</option>
          <option value="Food">Food</option>
          <option value="Other">Other</option>
        </select>

        <input
          type="file"
          name="new_attachments"
          ref={fileInputRef}
          id="files"
          onChange={handleFileChange}
          required
        />
        {error.files && (
          <span className="reimbursement-error-message">{error.files}</span>
        )}
        {errors && <p className="error">{errors}</p>}
      </div>

      <div className="button-reimbursement">
        <button onClick={handleSubmit} className="submit-reimbursement">
          Submit
        </button>
        <button onClick={handleCancel} className="cancel-reimbursement">
          Cancel
        </button>
      </div>

      {submittedRequests.length > 0 ? (
        <div className="total-reimbursement">
          <div>
            <h3 className="title-reimbursement">Submitted Requests</h3>
          </div>
          <div className="total-2-reimbursement">
            <div className="total-1">
              <h3>Total Amount ($):</h3>
              <h4>${calculateTotalAmount()}</h4>
            </div>
            <div className="total-2">
              <h3>Total Amount Due ($):</h3>
              <h4>
                $
                {(calculateTotalAmount() - calculateTotalAmountDue()).toFixed(
                  2
                )}
              </h4>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3>No previous history</h3>
        </div>
      )}

      <div className="reimbursement-responsive-table">
        {loading && (
          <div className="loading-spinner-overlay">
            {/* <span className="loading-text">Loading Documents</span> */}
            <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {submittedRequests.length > 0 && (
          <ul className="reimbursement-responsive-table">
            <li className="reimbursement-table-header">
              <div className="reimbursement-col reimbursement-col-1">Amount</div>
              <div className="reimbursement-col reimbursement-col-2">
                Description
              </div>
              <div className="reimbursement-col reimbursement-col-3">
                Category
              </div>
              <div className="reimbursement-col reimbursement-col-4">
                Document
              </div>
              <div className="reimbursement-col reimbursement-col-5">Status</div>
              <div className="reimbursement-col reimbursement-col-6">Comment</div>
              <div className="reimbursement-col reimbursement-col-7">
                Timestamp
              </div>
              <div className="reimbursement-col reimbursement-col-8">Actions</div>
            </li>
            {submittedRequests.map((request) => (

              <li className="reimbursement-table-row">
                <div
                  className="reimbursement-col reimbursement-col-1"
                  data-label="Amount"
                >
                  {editStates[request.id] ? (
                    <input
                      type="text"
                      value={editAmount}
                      onChange={handleEditAmountChange}
                      className="input-table-reimbursement"
                    />
                  ) : (
                    request.amount
                  )}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-2"
                  data-label="Description"
                >
                  {editStates[request.id] ? (
                    <textarea
                      value={editDescription}
                      onChange={handleEditDescriptionChange}
                    />
                  ) : (
                    request.description
                  )}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-3"
                  data-label="Category"
                >
                  {editStates[request.id] ? (
                    <select
                      value={editCategory}
                      onChange={handleEditCategoryChange}
                    >
                      <option value="Transportation">Transportation</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Food">Food</option>
                      <option value="Other">Other</option>
                    </select>
                  ) : (
                    request.category
                  )}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-4"
                  data-label="Document"
                >
                  <span
                    onClick={() => handleDocumentSelect(request.file_name)}
                    onDoubleClick={() => handleDoubleClick(request.file_name)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {request.file_name}
                  </span>
                </div>
                <div
                  className="reimbursement-col reimbursement-col-5"
                  data-label="Status"
                >
                  {editStates[request.id] ? (
                    <select value={editStatus} onChange={handleEditStatusChange}>
                      <option value="Submitted">Submitted</option>
                      <option value="Pending Approval">Pending Approval</option>
                      <option value="On Hold">On Hold</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Processing">Processing</option>
                      <option value="Paid">Paid</option>
                    </select>
                  ) : (
                    request.status
                  )}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-6"
                  data-label="Comment"
                >
                  {editStates[request.id] ? (
                    <textarea
                      value={editComment}
                      onChange={handleEditCommentChange}
                    />
                  ) : (
                    request.comment
                  )}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-7"
                  data-label="Timestamp"
                >
                  {request.timestamp}
                </div>
                <div
                  className="reimbursement-col reimbursement-col-8"
                  data-label="Actions"
                >
                  {editStates[request.id] ? (
                    <div className="button-action">
                      <button onClick={() => handleSaveChanges(request.id)}>
                        <TfiSave />
                      </button>
                      <button onClick={() => handleCancelEdit(request.id)}>
                        <TbArrowBackUp />
                      </button>
                    </div>
                  ) : (
                    <div className="button-action">
                      <button onClick={() => handleEdit(request.id)}>
                        <FaUserEdit />
                      </button>
                      <button onClick={() => handleDelete(request.id)}>
                        <BsTrash3 />
                      </button>
                    </div>
                  )}
                </div>
              </li>

            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default OperatorPatientReimbursement;
