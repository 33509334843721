import React from "react";

import MindyHello from "./MindyHello";
import LandingContent from "./LandingContent";
import NavBarNew from "./NavBarNew";
import HeroSection from "./HeroSection";
import "./LandingPageHomeNew.css";
import logo from "../../Images/MRI2-removebg-preview.png";
import CurvedLine from "./CurvedLine";
import WhoWeServe from "./WhoWeServe";
import Footer from "./footer";

const LandingPageHomeNew = () => {
  // const handleRedirect = () => {
  //   window.open("https://neuro42.ai/", "_blank");
  // };
  const handleRedirectProvider = () => {
    window.open("http://localhost:3000/physicians", "_blank");
  };
  const handleRedirectPatient = () => {
    window.open("http://localhost:3000/patients", "_blank");
  };
  return (
    <div className="main-page">
      <NavBarNew />
      <HeroSection />

      <div className="shapes">
        <div className="shape rectangle"></div>
        <div className="shape circle"></div>
      </div>

      <div class="styled-line-main">
        <div class="line1-main"></div>
        <div class="line2-main"></div>
        <div class="line3-main"></div>
      </div>

      <div class="info-section">
        <div class="info-content">
          <h2>
            Access Your MRI Scans Anytime – Simplifying Care for Patients and
            Providers
          </h2>
          <p>
            Welcome to neuro42 Health, the ultimate digital solution for
            managing and viewing portable MRI scans. Our platform ensures
            seamless access to your medical imaging, empowering patients and
            providers with secure, fast, and accurate tools for diagnostics and
            care. Experience the future of healthcare with a portal designed to
            enhance efficiency, reduce operational costs, and improve patient
            outcomes.
          </p>
          <div className="second-section-learn-more">
            <button
              className="learn-more-button"
              onClick={handleRedirectProvider}
            >
              Discover Provider Benefits
            </button>
            <button
              className="learn-more-button"
              onClick={handleRedirectPatient}
            >
              Discover Patient Benefits
            </button>
          </div>
        </div>
        <div class="info-image">
          <img src={logo} alt="MRI Device" />
        </div>
      </div>

      <div className="shapes-2">
        <div className="rectangle2">
          <div class="telehealth-header">
            <div class="styled-line-TeleHealth">
              <div class="line1-TeleHealth"></div>
              <div class="line2-TeleHealth"></div>
              <div class="line3-TeleHealth"></div>
            </div>
            <h2>Health Portal Benefits</h2>
            <p>
              Transform the way you deliver care with our comprehensive health
              portal. Experience the future of healthcare today.
            </p>
          </div>
        </div>
      </div>
      <div className="curved-line-container">
        <CurvedLine />
      </div>
      <div className="WhoWeServe-container">
        <WhoWeServe />
      </div>

      <Footer />
    </div>
  );
};

export default LandingPageHomeNew;
