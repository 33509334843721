import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Modal from "react-modal";
import axios from "axios";
import NavbarNew from "../Navbar/NavbarNew";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import "./OperatorPatientFilesNew.css";
import useIdleTimer from "../../useIdleTimer"; // Ensure this is correctly imported or implemented
import dateIcon from "../Operator/SVGs/sort.svg";
import Sidebar from "./components/Sidebar";
import SidebarPatient from "./components/SidebarPatient";
import questionMark from "../Images/Vector.png";
import UploadIcon from "../Images/Page-1.png";
import UploadIcon2 from "../Images/upload.png";
import UploadIcon3 from "../Images/upload_white.png";
import physician_thumbsup_image from "../Images/simple-line-icons_check.png";
import "./LoadingSpinner.css";

Modal.setAppElement('#root'); // Needed for accessibility

const OperatorPatientFilesNew = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const role = sessionStorage.getItem("role")
  const patient = role === "Patient"
    ? storedUserObject
    : JSON.parse(sessionStorage.getItem("Patient")) || {};
  // const [patient, setPatient] = useState(storedPatient);


  const username = storedUserObject.first_name;
  const project = sessionStorage.getItem("project");

  const [selectedFile, setSelectedFile] = useState(null);
  const [isSortedAsc, setIsSortedAsc] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = useRef(null); // Changed to useRef for functional components
  const [textColor, setTextColor] = useState("#409E8D");
  const [backgroundColor, setBackgroundColor] = useState("#C5ECE5");
  const [icon, setIcon] = useState(UploadIcon);

  const handleMouseEnter = () => {
    setIcon(UploadIcon3);
    setTextColor("#FFFFFF");
    setBackgroundColor("#409E8D");
  };

  const handleMouseLeave = () => {
    setIcon(UploadIcon);
    setTextColor("#409E8D");
    setBackgroundColor("#C5ECE5");
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    height: "4vh",
    justifyContent: "center",
    width: "auto",
    minWidth: "10vw",
    padding: "1px 5px",
    fontSize: "0.9vw",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "0%",
    marginTop: "1.25%",
    background: backgroundColor,
    mixBlendMode: "normal",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2vw",
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "2vw",
    color: textColor,
    border: "none",
    transition: "color 0.3s ease",
  };

  const iconStyle = {
    width: "2vh",
    height: "2vh",
    marginLeft: "0.5vw",
    transition: "opacity 0.3s ease",
  };

  const sort = () => {
    setIsSortedAsc(!isSortedAsc);
    const sortedDocuments = [...documents].sort((a, b) => {
      const dateA = new Date(a.upload_date);
      const dateB = new Date(b.upload_date);
      return isSortedAsc ? dateB - dateA : dateA - dateB;
    });
    setDocuments(sortedDocuments);
  };

  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/physicians";
  };

  useIdleTimer(handleIdle, 1.2e6); // 20 minutes timeout

  // useEffect(() => {
  //   // const storedPatient = JSON.parse(sessionStorage.getItem("patient"));
  //   // if (storedPatient !== patient) {
  //   //   setPatient(storedPatient);
  //   // }
  //   if (patient) {
  //     fetchDocuments();
  //   }
  // }, [patient, currentPage, pageSize]);
  useEffect(() => {
    if (patient && patient.email) {
      fetchDocuments();
    }
  }, []);


  const getToken = () => {
    return sessionStorage.getItem(
      role === "Admin" ? "admin_token" :
        role === "Operator" || role === "Physician" ? "operator_token" :
          role === "Patient" ? "patient_token" : null
    );
  };


  const fetchDocuments = async (page = currentPage, pageSize = 10) => {
    setLoading(true);
    try {
      const token = getToken();
      if (!patient) {
        setError("No patient data found");
        setLoading(false);
        return;
      }
      const endpoint =
        role === "Patient"
          ? `/patient/retrieve_documents/${patient.email}`
          : `/operator/retrieve_documents/${patient.email}`;

      if (token) {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
          params: {
            page,
            page_size: pageSize,
          },
        });

        if (response.status === 200) {
          const { documents, total_pages } = response.data;

          const documentsWithTimestamp = documents.map((doc) => ({
            ...doc,
            date: new Date(doc.upload_date).toLocaleDateString("en-CA"),
            time: new Date(doc.upload_date).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true, // To get AM/PM
            }),
          }));

          setDocuments(documentsWithTimestamp);
          setTotalPages(total_pages);
        } else {
          setError("Error fetching documents");
        }
      } else {
        setError("Error fetching documents");
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setLoading(false); // End loading when fetch completes
    }
  };

  const handleFetchError = async (error) => {
    if (error.response?.status === 401) {
      const refreshResponse = await axios.post("/operator/refresh/", {}, { withCredentials: true });
      if (refreshResponse.status === 200) {
        sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
        fetchDocuments(); // Retry after refresh
      } else {
        console.log("Refresh failed");
        handleLogout();
      }
    } else {
      setError("Error fetching documents");
      console.error("Error fetching documents", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/svg+xml"];
    if (!allowedTypes.includes(file.type)) {
      setError("Invalid file type. Please upload a PDF, PNG, JPEG, or SVG file.");
      setSelectedFile(null);
      fileInputRef.current.value = "";
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError("File size exceeds 5MB limit.");
      return;
    }

    setSelectedFile(file);
    setError(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (!file) return;

    const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/svg+xml", "application/zip"];
    if (!allowedTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a PDF, PNG, JPEG, or SVG file.");
      setSelectedFile(null);
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      alert("File size exceeds 5MB limit.");
      return;
    }

    setSelectedFile(file);
  };


  const uploadFile = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());

      try {
        const token = getToken();

        const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "project": project
        };

        const endpoint =
          role === "Patient"
            ? `/patient/upload_document/${patient.email}`
            : `/operator/upload_document/${patient.email}`;


        await axios.post(
          endpoint,
          formData,
          {
            headers
          }
        );
        setSelectedFile(null);
        setUploading(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const reader = new FileReader();
        reader.onprogress = (event) => {
          const { loaded, total } = event;
          const progressPercent = (loaded / total) * 100;
          setProgress(progressPercent);
        };

        reader.onload = () => {
          setTimeout(() => {
            setUploading(false);
            setProgress(0);
            setSelectedFile(null);
            setError("");
            setIsSuccessModalOpen(true);
          }, 2000);
        };

        reader.readAsDataURL(selectedFile);
        fetchDocuments();
      } catch (error) {
        if (error.response?.status === 401) {
          const refreshResponse = await axios.post("/operator/refresh/", {}, { withCredentials: true });
          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            await uploadFile();
          } else {
            console.error("Refresh failed");
            handleLogout();
          }
        } else {
          setError("Error uploading file");
        }
      } finally {
        setUploading(false);
      }
    } else {
      setError("Please select a file");
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleDocumentSelect = (documentName) => {
    setSelectedDocument(documentName);
  };

  const handleDoubleClick = (documentName) => {
    setSelectedDocument(documentName);
    downloadDocument();
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);  // Reset to page 1 when page size changes
  };

  const downloadDocument = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);
    if (selectedDoc) {
      try {

        const endpoint =
          role === "Patient"
            ? `/patient/download_document/${selectedDoc.file_id}`
            : `/operator/download_document/${selectedDoc.file_id}`;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            project
          },
          responseType: "blob",
        });
        const blob = new Blob([response.data], { type: response.data.type });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = selectedDoc.file_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        if (error.response?.status === 401) {
          const refreshResponse = await axios.post("/operator/refresh/", {}, { withCredentials: true });
          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            await downloadDocument();
          } else {
            console.error("Refresh failed");
            handleLogout();
          }
        } else {
          setError("Error downloading file");
        }
      }
    } else {
      setError("Please select a document");
    }
  };

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        {role === "Patient" ? <SidebarPatient /> : <Sidebar />}
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line-file-upload">
              <div className="title-container-file-upload">
                <h2 className="inner-page-title-file-upload">File List</h2>
                <div className="tooltip-container">
                  <img src={questionMark} alt="info-icon" className="info-icon" />
                  <span className="tooltip-text">View your files here</span>
                </div>
              </div>
              {/* <button className="upload-file" onClick={openModal}>
                Upload
                <img
                  src={UploadIcon}
                  alt="UploadIcon"
                  className="upload-icon"
                />
              </button> */}

              <button
                className="add-patient-button-new"
                onClick={openModal}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={buttonStyle}
              >
                Upload
                <img
                  src={icon}
                  alt="UploadIcon"
                  className="upload-icon"
                  style={iconStyle}
                />
              </button>
            </div>
          </div>

          {/* File Upload Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="file-upload-modal"
            overlayClassName="modal-overlay"
          >
            <div className="modal-header-upload-file ">
              <h2>Upload your file</h2>
              <button className="close-button" onClick={closeModal}>
                &times;
              </button>
            </div>

            <div className={`upload-area ${isDragging ? "dragging" : ""}`} // Conditionally apply dragging styles
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}>
              <div className="drag-drop-area">
                <img src={UploadIcon2} alt="upload" className="icon-upload" />
                <p>Drag and Drop to upload file</p>
                <p style={{ fontStyle: "italic", color: "orange", fontSize: "1.5vh" }}>(The file size should not exceed 5MB. Accepted file formats: PDF, PNG, JPEG, and SVG.)</p>
                <p>or</p>
                <label className="browse-file">
                  Browse File
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </label>
              </div>
            </div>

            {selectedFile && (
              <div className="file-name">
                {selectedFile.name}
              </div>
            )}

            <button onClick={uploadFile} className="confirm-upload" disabled={uploading}>
              {uploading ? `Uploading... ${progress}%` : "Upload"}
            </button>
          </Modal>

          {/* Success Modal */}
          <Modal
            isOpen={isSuccessModalOpen}
            onRequestClose={closeSuccessModal}
            className="success-modal"
            overlayClassName="modal-overlay"
          >
            <div className="confirmation-modal-overlay">
              <div className="confirmation-modal-content">
                <div className="confirmation-modal-header">
                  <img
                    src={physician_thumbsup_image}
                    alt="Character"
                    className="check-image"
                  />
                </div>
                <h2>Great!</h2>
                <p>File uploaded successfully!</p>
                <button onClick={closeSuccessModal}>Okay</button>
              </div>
            </div>
          </Modal>

          <div className="scan-table-container-file-list">
            <div className="scan-table-header-file-list">
              <div className="header-cell-file-list " onClick={sort}>
                <img
                  src={dateIcon}
                  alt="Sort Date"
                  className={`sort-icon ${isSortedAsc ? "asc" : "desc"}`}
                />
                Date
              </div>
              <div className="header-cell-file-list ">Time</div>
              <div className="header-cell-file-list ">File Name</div>
              <div className="header-cell-file-list ">File Type</div>
            </div>
            <div className={`${documents.length === 0 ? "empty-state" : "table-body"}`}>
              {documents.map((doc) => (
                <div
                  className="user-row-table-list"
                  key={doc.file_name}
                  onClick={() => handleDocumentSelect(doc.file_name)}
                  onDoubleClick={() => handleDoubleClick(doc.file_name)}
                  title="Double click to download"
                >
                  <div className="user-cell-table-list">{doc.date}</div>
                  <div className="user-cell-table-list">{doc.time}</div>
                  <div className="user-cell-table-list">{doc.file_name}</div>
                  <div className="user-cell-table-list">{doc.file_type}</div>

                </div>
              ))}
            </div>
            {loading && (
              <div className="loading-spinner-overlay">
                <div className="bouncing-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            <div className="pagination-container">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  siblingCount={0}
                  boundaryCount={1}
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "#999999",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F0F6F5 !important",
                      color: "#999999",
                      fontSize: "1vw",
                    },
                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#F0F6F5",
                    },
                  }}
                />
              </Stack>

              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                size="small"
                style={{ minWidth: 60 }}
                sx={{
                  minHeight: 5,
                  borderRadius: "10px",
                  backgroundColor: "#f0f0f0",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                  ".MuiSelect-select": {
                    padding: "8px 10px",
                    fontSize: "1vw",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                }}
              >
                <MenuItem value={10}>10 per page</MenuItem>
                <MenuItem value={25}>25 per page</MenuItem>
                <MenuItem value={50}>50 per page</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OperatorPatientFilesNew;
