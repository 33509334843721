import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OperatorPatientFiles.css";
import useIdleTimer from "../../useIdleTimer"

import "./LoadingSpinner.css"
const OperatorPatientFiles = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const parsedPatient = sessionStorage.getItem("Patient");
  const patient = JSON.parse(parsedPatient);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(true);

  const project = sessionStorage.getItem("project");

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout


  useEffect(() => {
    fetchDocuments();
  }, []);
  const fileInputRef = React.createRef();
  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const fetchDocuments = async () => {
    try {
      const token = getToken();

      if (token) {
        try {
          const response = await axios.get(
            `/operator/retrieve_documents/${patient.email}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                project
              },
            }
          );

          if (response.status === 200) {
            // Add a timestamp to each document
            const documentsWithTimestamp = response.data.map((doc) => {
              return {
                ...doc,
                timestamp: new Date(doc.upload_date).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }),
              };
            });
            setDocuments(documentsWithTimestamp);

          } else {
            setError("Error fetching documents");
          }
        } catch (error) {
          if (error.request.status === 401) {
            const refreshResponse = await axios.post(
              "/operator/refresh/", {}, { withCredentials: true }
            );

            if (refreshResponse.status === 200) {
              sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
              await fetchDocuments();
            } else {
              console.log("Refresh failed");
              handleLogout();
            }
          } else {
            setError("Error fetching documents", error);
          }
        }
        finally {
          setLoading(false);
        }
      } else {
        setError("Error fetching documents");
      }
    } catch (error) {
      setError("Error fetching documents");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      setError(
        "Invalid file type. Please upload a PDF, PNG, JPEG, or SVG file."
      );
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError("File size exceeds 5MB limit.");
      return;
    }

    setSelectedFile(file);
    setError("");
  };

  const uploadFile = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());
      console.log(formData)

      try {
        const token = getToken();
        // const headers = new Headers();
        // headers.append("Authorization", `Bearer ${token}`);
        // headers.append("Content-Type", "multipart/form-data");
        // headers.append("project", project);

        const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "project": project // Add your custom project header here
        };

        await axios.post(
          `/operator/upload_document/${patient.email}`,
          formData,
          {
            headers
          }
        );
        setSelectedFile(null);
        setUploading(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const reader = new FileReader();
        reader.onprogress = (event) => {
          const { loaded, total } = event;
          const progressPercent = (loaded / total) * 100;
          setProgress(progressPercent);
        };

        reader.onload = () => {
          setTimeout(() => {
            setUploading(false);
            setProgress(0);
            setSelectedFile(null);
            setError("");
            alert("File uploaded successfully!");
          }, 2000);
        };

        reader.readAsDataURL(selectedFile);
        fetchDocuments();
      } catch (error) {
        if (error.request.status === 401) {
          // Token expired, attempt to refresh
          const refreshResponse = await axios.post(
            "/operator/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          } else {
            console.error("Refresh failed", error);
            handleLogout();
          }
        } else {
          setError("Error uploading file");
        }
      }
    } else {
      setError("Please select a file");
    }
  };

  const handleDocumentSelect = (documentName) => {
    setSelectedDocument(documentName);
  };

  const handleDoubleClick = (documentName) => {
    setSelectedDocument(documentName);
    if (documentName === "AuthorizationForm") {
      downloadDecryptedForm();
    } else {
      downloadDocument();
    }
  };

  const downloadDocument = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);

    if (selectedDoc) {
      try {
        const response = await axios.get(
          `/operator/download_document/${selectedDoc.file_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], { type: response.data.type });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = selectedDoc.file_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/operator/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            await downloadDocument();
          } else {
            console.error("Refresh failed", error);
            handleLogout();
          }
        } else {
          console.error("Error downloading file", error);
        }
      }
    } else {
      setError("Please select a document");
    }
  };

  const downloadDecryptedForm = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);

    if (!selectedDoc) {
      setError("Please select a document");
      return;
    }

    try {
      // Make a GET request to fetch the decrypted document
      const response = await axios.get(
        `/patient/download_decrypted_form/${selectedDoc.file_id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );

      // Create a blob and trigger download
      const blob = new Blob([response.data], { type: response.data.type });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = selectedDoc.file_name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      if (error.request.status === 401) {
        // Handle token refresh if the request is unauthorized
        try {
          const refreshResponse = await axios.post(
            "/patient/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
            await downloadDecryptedForm(); // Retry the download after refreshing token
          } else {
            console.error("Refresh failed", error);
            handleLogout();
          }
        } catch (refreshError) {
          console.error("Error refreshing token", refreshError);
          handleLogout();
        }
      } else {
        console.error("Error downloading file", error);
      }
    }
  };


  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div className="patient-file-container">
      <input type="file" ref={fileInputRef} onChange={handleFileChange} />
      <button
        className="upload-button"
        onClick={uploadFile}
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Upload"}
      </button>
      {progress > 0 && progress < 100 && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      <div className="file-responsive-table">
        {loading && (
          <div className="loading-spinner-overlay">
            {/* <span className="loading-text">Loading Documents</span> */}
            <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {documents.length > 0 ? (
          <ul className="document-table">
            <li className="file-table-header">
              <div className="file-col file-col-1">Document Name</div>
              <div className="file-col file-col-2">Upload Date</div>
            </li>
            {documents.map((doc) => (
              <li className={"file-table-row"} key={doc.file_name}>
                <div className="file-col file-col-1" data-label="Document Name">
                  <span
                    onClick={() => handleDocumentSelect(doc.file_name)}
                    onDoubleClick={() => handleDoubleClick(doc.file_name)}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {doc.file_name}
                  </span>
                </div>
                <div className="file-col file-col-2" data-label="Upload Date">
                  {doc.timestamp}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No documents uploaded</p>
        )}
      </div>
    </div>
  );
};

export default OperatorPatientFiles;
