import React, { useState, useEffect } from "react";
import axios from "axios";
import { googleformresponses } from "../../constants.js";
import "./OperatorFeedback.css";
import useIdleTimer from "../../useIdleTimer";
import { useNavigate } from "react-router-dom";



function OperatorFeedback() {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/physicians';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  useEffect(() => {
    handleGetPatients();
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await axios.get("/operator/verify_token/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
          } else {
          }
        } catch (error) {
          if (error.request.status === 401) {
            const refreshResponse = await axios.post(
              "/operator/refresh/",
              {},
              { withCredentials: true }
            );

            if (refreshResponse.status === 200) {
              sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
            } else {
              handleLogout();
            }
          }
          console.error("Error in verifing token at feedbacklist", error);
        }
      }
      else {
        navigate("/physicians")
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
  };

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleGetPatients = async () => {
    try {
      const token = getToken();

      const response = await axios.get("/operator/get_all_patient_feedbacks/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setPatients(response.data.data);
      } else {
        console.error("Failed to fetch patients. Status: " + response.status);
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/", {}, { withCredentials: true });

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("operator_token", refreshResponse.data.access_token);
          await handleGetPatients();
        } else {
          console.error("Refresh failed at feedbacklist", error);
          handleLogout();
        }
      } else {
        console.error("Error getting patient's feedback");
      }
    }
  };

  return (
    <div className="feedback-list">
      <a href={googleformresponses} target="_blank" rel="noopener noreferrer">
        Patient Feedback Responses
      </a>
      <div>
        <ul className="feedback-responsive-table">
          <li className="feedback-table-header">
            <div className="feedback-col feedback-col-1">Timestamp</div>
            <div className="feedback-col feedback-col-2">Name</div>
            <div className="feedback-col feedback-col-3">Email Address</div>
          </li>
          {patients.map((doc) => (
            <li className={"feedback-table-row"} key={doc.file_name}>
              <div
                className="feedback-col feedback-col-1"
                data-label="Timestamp"
              >
                {doc.timestamp}
              </div>
              <div className="feedback-col feedback-col-2" data-label="Name">
                {doc.name}
              </div>
              <div
                className="feedback-col feedback-col-3"
                data-label="Email Address"
              >
                {doc.email}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default OperatorFeedback;
