import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PatientUploadFile.css";
import useIdleTimer from "../../useIdleTimer"

const PatientUploadFile = ({ patient }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [progress, setProgress] = useState(null);

  const fileInputRef = React.createRef();
  const getToken = () => {
    const token = sessionStorage.getItem("patient_token");
    return token;
  };

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/patients';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  const fetchDocuments = async (email) => {
    try {
      const token = getToken();
      const response = await axios.get(`/patient/retrieve_documents/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const documentsWithTimestamp = response.data.map((doc) => {
          console.log("upload_date: ", doc.upload_date);
          return {
            ...doc,
            timestamp: new Date(doc.upload_date).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
          };
        });
        setDocuments(documentsWithTimestamp);
      } else {
        setError("Error fetching documents");
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/patient/refresh/", {}, { withCredentials: true }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
          await fetchDocuments(email);
        }
      }
    }
  };
  useEffect(() => {
    // Fetch previously uploaded documents for the current patient
    fetchDocuments(patient.email);
  }, [fetchDocuments, patient.email]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      setError(
        "Invalid file type. Please upload a PDF, PNG, JPEG, or SVG file."
      );
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError("File size exceeds 5MB limit.");
      return;
    }

    setSelectedFile(file);
    setError("");
  };

  const uploadFile = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("email", patient.email);
      formData.append("upload_date", new Date().toISOString());

      try {
        const token = getToken();
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", "multipart/form-data");

        await axios.post(
          `/patient/upload_document/${patient.email}`,
          formData,
          {
            headers,
          }
        );
        setSelectedFile(null);
        setUploading(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const reader = new FileReader();
        reader.onprogress = (event) => {
          const { loaded, total } = event;
          const progressPercent = (loaded / total) * 100;
          setProgress(progressPercent);
        };

        reader.onload = () => {
          setTimeout(() => {
            setUploading(false);
            setProgress(0);
            setSelectedFile(null);
            setError("");
            alert("File uploaded successfully!");
          }, 2000);
        };

        reader.readAsDataURL(selectedFile);
        fetchDocuments(patient.email);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/patient/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
            await uploadFile();
          }
        }
      }
    } else {
      setError("Please select a file");
    }
  };

  const handleDocumentSelect = (documentName) => {
    setSelectedDocument(documentName);
  };

  const handleDoubleClick = (documentName) => {
    setSelectedDocument(documentName);
    downloadDocument();
  };

  const downloadDocument = async () => {
    const selectedDoc = documents.find((d) => d.file_name === selectedDocument);

    if (selectedDoc) {
      try {
        const response = await axios.get(
          `/patient/download_document/${selectedDoc.file_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], { type: response.data.type });
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = selectedDoc.file_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/patient/refresh/", {}, { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("patient_token", refreshResponse.data.access_token);
            await downloadDocument();
          } else {
            handleLogout();
          }
        }
      }
    } else {
      setError("Please select a document");
    }
  };

  const handleLogout = async () => {
    await axios.post("/patient/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div className="patient-file-container-2">
      <input type="file" ref={fileInputRef} onChange={handleFileChange} />
      <button
        className="upload-button"
        onClick={uploadFile}
        disabled={uploading}
      >
        {uploading ? "Uploading..." : "Upload"}
      </button>
      {progress > 0 && progress < 100 && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      {documents.length > 0 ? (
        <ul className="file-responsive-table">
          <li className="file-table-header">
            <div className="file-col file-col-1">Document Name</div>
            <div className="file-col file-col-2">Upload Date</div>
          </li>
          {documents.map((doc) => (
            <li className={"file-table-row"} key={doc.file_name}>
              <div className="file-col file-col-1" data-label="Document Name">
                <span
                  onClick={() => handleDocumentSelect(doc.file_name)}
                  onDoubleClick={() => handleDoubleClick(doc.file_name)}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {doc.file_name}
                </span>
              </div>
              <div className="file-col file-col-2" data-label="Upload Date">
                {doc.timestamp}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No documents uploaded</p>
      )}
    </div>
  );
};

export default PatientUploadFile;
