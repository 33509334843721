import React from "react";
import "./footer.css";
import mindyHello from "../../Images/logo.png";
import instagram from "../../Images/image 6.png";
import linkedin from "../../Images/image 5.png";
import facebook from "../../Images/image 4.png";
import twitter from "../../Images/image 3.png";

const Footer = () => {
  return (
    <footer class="footer-main">
      <div class="footer-container">
        <div class="footer-top">
          <div class="footer-logo-social">
            <img src={mindyHello} alt="Logo" class="footer-logo" />
            <div class="footer-social">
              <a href="#">
                <img src={twitter} alt="Twitter" />
              </a>
              <a href="#">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="#">
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a href="#">
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>

          <div class="footer-divider"></div>
          <div class="footer-nav">
            <ul>
              <li>
                <a href="#">Physicians</a>
              </li>
              <li>
                <a href="#">Patients</a>
              </li>
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Sign In</a>
              </li>
            </ul>
          </div>
          <div class="footer-contact">
            <p>Contact Us</p>
            <p>
              (480) 241-3211
              <br />
              hello@neuro42.com
            </p>
          </div>
          <div class="footer-address">
            <p>
              2 Bryant St, Suite 240
              <br />
              San Francisco, CA 94105
            </p>
          </div>
        </div>
        <div class="footer-bottom">
          <p>©2024 neuro42 Health All rights reserved.</p>
          <ul>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/termsandconditions">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
