import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Images/fulllogo.png";
import "./AdminAddOperator.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIdleTimer from "../../useIdleTimer";
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { Modal, Box, Typography, Button } from '@mui/material';

const AdminAddOperator = () => {
  const [newOperator, setNewOperator] = useState({
    first_name: "",
    last_name: "",
    password: "",
  });
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState([]);
  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
  const [passwordStrength, setPasswordStrength] = useState({
    " (8)-minlength": false,
    " (1)-lowercase": false,
    " (1)-uppercase": false,
    " (1)-digit": false,
    " (1)-specialChar": false,
  });

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate('/physicians'); // Redirect to login page
  };
  // const [showPopup, setShowPopup] = useState(false);
  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/adminPage';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse)
      console.log('Google login success, codeResponse');
    },
    onError: (error) => console.log("Login Failed:", error)
  });

  const authenticateAndFetchProfile = async (user, navigate) => {


    if (!user) return;



    try {
      const addOperatorResponse = await axios.post(`/admin/api/authenticate/${token}`, {
        token: user.access_token,
      });

      // if (authResponse.status === 200) {
      //   console.log('Backend verification success:');
      //   const authtoken = authResponse.data.access_token;
      //   sessionStorage.setItem("operator_token", authtoken);

      //   const operatorData = {
      //     first_name: authResponse.first_name,
      //     email: authResponse.email
      //   };



      // const addOperatorResponse = await axios.post(`/admin/create_operator/${token}`, operatorData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      if (addOperatorResponse.status === 201) {
        console.log("Operator added!");
        setOpen(true)
        // setSuccess(true);
        // setError("");

      } else {
        setError("Failed to add operator. Status: " + addOperatorResponse.status);
      }
      // } else {
      //   throw new Error('Authentication failed');
      // }
    } catch (error) {
      console.error('Error during authentication or profile fetch:', error);
      setError('Authentication or profile fetch failed. Please try again.');
    }
  };

  useEffect(() => {

    if (!isInitialLoad) {
      authenticateAndFetchProfile(user, navigate);
    }
    setIsInitialLoad(false);

  }, [user, navigate]);


  const handleAddOperator = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `/admin/create_operator/${token}`,
        newOperator
      );

      if (response.status === 201) {
        console.log("Operator added!");
        setSuccess(true);
        setError("");
        setNewOperator({
          first_name: "",
          last_name: "",
          password: "",
        });
      } else {
        setError("Failed to add operator. Status: " + response.status);
      }
    } catch (error) {
      if (error.response.status === 401) {
        try {
          const refreshResponse = await axios.post("/admin/refresh/", {
          }, { withCredentials: true });

          if (refreshResponse.status === 200) {
            sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
            await handleAddOperator();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }
  };
  const checkPasswordStrength = (password) => {
    const strength = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*()]/.test(password),
    };
    return strength;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOperator((prevState) => ({ ...prevState, [name]: value }));
    setTouchedFields((prevState) => ({ ...prevState, [name]: true }));

    if (name === "password") {
      const strength = checkPasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const handleLogout = async () => {
    await axios.post("/admin/logout/", {
    }, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div className="alineRegisterPageItem">
      <div className="operator-invite-logoimg">
        <img src={logo} alt="neuro42" />
      </div>
      <div>
        <h2 className="register-page-content">
          Welcome to neuro42. Register as an Operator
        </h2>
        <p className="register-instructions">
          Please fill in the information below to register as an operator:
        </p>
        <p className="password-requirements">
          (Password must be at least 8 characters long and contain at least one
          lowercase letter, one uppercase letter, one digit, and one special
          character)
        </p>
      </div>
      <form onSubmit={handleAddOperator} className="add-operator-form">
        <input
          className="admin-operator-list-input"
          type="text"
          placeholder="First Name*"
          value={newOperator.first_name}
          onChange={(e) =>
            setNewOperator({ ...newOperator, first_name: e.target.value })
          }
          required
        />
        <input
          className="admin-operator-list-input"
          type="text"
          placeholder="Last Name*"
          value={newOperator.last_name}
          onChange={(e) =>
            setNewOperator({ ...newOperator, last_name: e.target.value })
          }
          required
        />
        <div className="pass-wrapper">
          <input
            placeholder="Password*"
            name="password"
            type={showPassword ? "text" : "password"}
            value={newOperator.password}
            onChange={handleInputChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            required
          />
          <i className="eye-icon" onClick={togglePasswordVisiblity}>
            {showPassword ? eye : eyeSlash}
          </i>
        </div>
        {isPasswordFocused &&
          Object.entries(passwordStrength).map(([key, value]) => (
            <p key={key} style={{ color: value ? "green" : "red" }}>
              {value ? "✓" : "✗"}:{key}
            </p>
          ))}
        <div className="pass-wrapper">
          <input
            className="operator-patient-list-input"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password*"
            name="confirmPassword"
            value={newOperator.confirmPassword}
            onChange={handleInputChange}
            onFocus={handleConfirmPasswordFocus}
            onBlur={handleConfirmPasswordBlur}
            required
          />
          <i className="eye-icon" onClick={toggleConfirmPasswordVisiblity}>
            {showConfirmPassword ? eye : eyeSlash}
          </i>
        </div>
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          newOperator.password === newOperator.confirmPassword ? (
          <p style={{ color: "green" }}>Passwords match</p>
        ) : null}
        {isConfirmPasswordFocused &&
          touchedFields.confirmPassword &&
          newOperator.password !== newOperator.confirmPassword ? (
          <p className="password-match-error">Passwords do not match</p>
        ) : null}
        <button type="submit" className="add-operator-button">
          Register
        </button>
        <div className="shadow-2xl">
          <button
            type="button"
            className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
            onClick={() => login()}
          >
            <FcGoogle className="mr-4" />
            Sign in with Google
          </button>
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, textAlign: 'center' }}>
          <Typography id="simple-modal-title" variant="h6" component="h2">
            Operator added!
          </Typography>
          <Typography id="simple-modal-description" sx={{ mt: 2 }}>
            The operator has been added successfully.
            Please allow 15 minutes for the scans to become accessible.
          </Typography>
          <Button onClick={handleClose}
            variant="contained"
            sx={{ mt: 2, backgroundColor: '#b0ffed', color: 'grey', '&:hover': { backgroundColor: '#a0e6db' } }}>
            Okay
          </Button>
        </Box>
      </Modal>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>Registration successful!</p>}
    </div>
  );
};

export default AdminAddOperator;
