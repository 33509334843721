import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav } from "react-bootstrap";
import { FaBars } from "react-icons/fa";

import OperatorFeedback from "./OperatorFeedback";
import OperatorPatientList from "./OperatorPatientList";
import { useNavigate, useParams } from "react-router-dom";
import "./Operator.styles.css";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { PiUserList } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import useIdleTimer from "../../useIdleTimer";
import log from "../../logger";

import InternalFooter from "../Footer/InternalFooter";

function Operator() {
  const [operatorProfile, setOperatorProfile] = useState({});
  const [loginOperator, setLoginOperator] = useState({});
  const [selectedContent, setSelectedContent] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { next } = useParams();

  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth < 767);
  };

  const getToken = () => {
    const token = sessionStorage.getItem("operator_token");
    return token;
  };

  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/physicians";
  };

  useIdleTimer(handleIdle, 1.2e6); // 20 minutes timeout

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const token = getToken();

        if (token) {
          try {
            const response = await axios.get("/operator/verify_token/", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 200) {
              await handleGetOperatorProfile();
            }
          } catch (error) {
            if (error.request.status === 401) {
              // Token expired, attempt to refresh
              const refreshResponse = await axios.post(
                "/operator/refresh/",
                {},
                { withCredentials: true }
              );

              if (refreshResponse.status === 200) {
                sessionStorage.setItem(
                  "operator_token",
                  refreshResponse.data.access_token
                );
                await handleGetOperatorProfile();
              } else {
                // Refresh failed, handle logout or redirect to login
                handleLogout();
              }
            }
          }
        }
      } catch (error) {
        console.error("Error in token validity(Operator)", error);
      }
    };

    const handleGetOperatorProfile = async () => {
      const token = sessionStorage.getItem("operator_token");

      if (!token) {
        console.error("Token not found");
        navigate("/physicians/");
        return;
      }

      try {
        const response = await axios.post(
          "/operator/get_operator_profile/",
          { user_id: loginOperator.user_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setOperatorProfile(response.data.operator);
        } else {
          console.error("Failed to fetch operator profile.");
        }
      } catch (error) {
        if (error.request.status === 401) {
          const refreshResponse = await axios.post(
            "/operator/refresh/",
            {},
            { withCredentials: true }
          );

          if (refreshResponse.status === 200) {
            sessionStorage.getItem(
              "operator_token",
              refreshResponse.data.access_token
            );

            await handleGetOperatorProfile();
          } else {
            console.error("Refresh failed:(Operator), logging out");
            handleLogout();
          }
        } else {
          console.error("Error fetching operator profile:", error);
        }
      }
    };

    checkTokenValidity();
    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [loginOperator]);

  const handleLogout = async () => {
    await axios.post("/operator/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    localStorage.clear();
    log.debug("Logging out from Operator");
    // Update the state or perform additional actions if needed
    // setIsLoggedIn(false);
    setIsOpen(false);
    navigate("/physicians/");
  };

  const menuItems = [
    { key: "dashboard", label: "Dashboard" },
    { key: "feedback", label: "Feedback" },
    { key: "patient-list", label: "Patient List" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (next) {
      switch (next) {
        case "feedback":
          setSelectedContent("feedback");
          break;
        case "patient-list":
          setSelectedContent("patient-list");
          break;
        default:
          setSelectedContent("dashboard");
      }
    } else {
      setSelectedContent("dashboard");
    }
  }, [next]);

  const handleMenuItemClick = (content) => {
    navigate(`/operator/${content}`);
    setIsOpen(false);
  };

  return (
    <div className="operator-main">
      <React.Fragment>
        {isMobile ? (
          <Navbar className="mobile-operator-nav">
            <div className="flex-operator-nav">
              <FaBars className="menu-icon" onClick={toggleMenu} />
              {operatorProfile && (
                <p className="profile-data-nav">
                  Hi,{" "}
                  {`${operatorProfile.first_name} ${operatorProfile.last_name}`}
                </p>
              )}
            </div>
            <Navbar.Collapse className="vertical-operator-nav">
              <Nav className={`navbar-buttons ${isOpen ? "show" : ""}`}>
                {menuItems.map((item) => (
                  <Nav.Link
                    key={item.key}
                    active={selectedContent === item.key}
                    onClick={() => {
                      handleMenuItemClick(item.key);
                      setIsOpen(false);
                    }}
                  >
                    {item.label}
                  </Nav.Link>
                ))}
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <Navbar className="operator-nav">
            <Nav className="mr-auto navbar-buttons">
              {menuItems.map((item) => (
                <Nav.Link
                  key={item.key}
                  active={selectedContent === item.key}
                  onClick={() => handleMenuItemClick(item.key)}
                >
                  <div className="nav-item">
                    {item.key === "dashboard" && (
                      <MdOutlineDashboardCustomize />
                    )}
                    {item.key === "feedback" && <VscFeedback />}
                    {item.key === "patient-list" && <PiUserList />}
                    {item.label}
                  </div>
                </Nav.Link>
              ))}
            </Nav>

            <Nav className="navbar-buttons-logout">
              <div className="operator-profile">
                {operatorProfile && (
                  <p className="profile-data-nav">
                    Hi,{" "}
                    {`${operatorProfile.first_name} ${operatorProfile.last_name}`}
                  </p>
                )}
              </div>
              <div className="white-line"></div>
              <Nav.Link onClick={handleLogout}>
                <div className="nav-item">
                  <BiLogOutCircle />
                  Logout
                </div>
              </Nav.Link>
            </Nav>
          </Navbar>
        )}
        <div className="operator-content">
          {/* {selectedContent === "dashboard" && <OperatorDashboard />} */}
          {selectedContent === "feedback" && <OperatorFeedback />}
          {selectedContent === "patient-list" && <OperatorPatientList />}
        </div>
        <InternalFooter />
      </React.Fragment>
    </div>
  );
}

export default Operator;
