import React from "react";
import "./HeroSectionPhysician.css";

import mindyHello from "../../Images/6010766-uhd_3840_2160_25fps.mp4";

const HeroSectionPhysician = () => {
  return (
    <div className="hero-section">
      <video className="background-video" autoPlay loop muted>
        <source src={mindyHello} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="gradient-overlay"></div>
      <div className="content-overlay">
        <h1>Empowering Your Practice with Advanced Imaging Solutions </h1>
        <p>
          Explore neuro42’s state-of-the-art services designed to elevate
          patient outcomes and expand research opportunities in your practice.
        </p>
      </div>
    </div>
  );
};

export default HeroSectionPhysician;
