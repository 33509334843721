import React, { useState } from "react";
import "./WhoWeServe.css";
import hospitalImage from "../../Images/Rectangle 28.png";
import hospitalImage2 from "../../Images/Rectangle 28 (1).png";
import hospitalImage3 from "../../Images/Rectangle 29.png";

const WhoWeServe = () => {
  const [activeIndex, setActiveIndex] = useState(1); // Start with the second card as the active one

  const carouselItems = [
    {
      title: "Hospitals",
      description:
        "Our portable MRI solution provides hospitals with versatile imaging capabilities, ensuring efficient patient care and diagnostic accuracy.",
      imageUrl: hospitalImage,
    },
    {
      title: "Imaging Centers",
      description:
        "Our portable MRI solutions offer imaging centers advanced capabilities for patient diagnostics and care.",
      imageUrl: hospitalImage2,
    },
    {
      title: "Research Facilities",
      description:
        "Supporting cutting-edge research with flexible and reliable MRI imaging solutions.",
      imageUrl: hospitalImage3,
    },
    {
      title: "Clinics",
      description:
        "Bringing cutting-edge MRI technology to clinics for improved patient care.",
      imageUrl: hospitalImage,
    },
  ];

  const getVisibleItems = () => {
    const items = [];
    for (let i = -1; i <= 1; i++) {
      items.push(
        carouselItems[
          (activeIndex + i + carouselItems.length) % carouselItems.length
        ]
      );
    }
    return items;
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + carouselItems.length) % carouselItems.length
    );
  };

  const visibleItems = getVisibleItems();

  return (
    <div className="who-we-serve-container">
      <div className="who-we-serve-header">
        <h2 className="who-we-serve-title">Who We Serve</h2>
        <p className="who-we-serve-description">
          At neuro42, we are committed to revolutionizing healthcare by making
          our technology accessible in diverse settings. Join us in transforming
          the future of medical imaging with our innovative portable MRI
          solutions.
        </p>
      </div>

      <div className="carousel-container">
        <div class="carousel-highlight"></div>
        <button className="carousel-arrow prev" onClick={handlePrev}>
          &#10094; {/* Left arrow */}
        </button>

        <div className="carousel-track">
          {visibleItems.map((item, index) => (
            <div
              key={index}
              className={`carousel-slide ${index === 1 ? "active" : ""}`}
            >
              <div className="carousel-card">
                <img src={item.imageUrl} alt={item.title} />
                <div className="carousel-overlay"></div>
                <div className="carousel-label">{item.title}</div>
                <div className="carousel-card-content">
                  <p className="carousel-description">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button className="carousel-arrow next" onClick={handleNext}>
          &#10095; {/* Right arrow */}
        </button>
      </div>

      <div className="carousel-dots">
        {carouselItems.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
          ></span>
        ))}
      </div>
      <div className="circle-decoration"></div>
      <div className="rectangle-decoration"></div>
      <div class="line-container">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>

      <div class="line-container-2">
        <div class="line1-2"></div>
        <div class="line2-2"></div>
        <div class="line3-2"></div>
      </div>
      <div class="line-container-3">
        <div class="line1-3"></div>
        <div class="line2-3"></div>
        <div class="line3-3"></div>
      </div>
    </div>
  );
};

export default WhoWeServe;
