export default{
    subject: 'Reminder – neuro42 MR Study Session',
    message: `Hello Mr./ Ms. XYZ, 

This is a reminder for your upcoming MR scan session at neuro42. 

Date: 

Time: 

Location: 2 Bryant St, San Francisco, California 94105.  

If you need help with directions, kindly refer to our confirmation email or call us at (628) 231-8123.  

To reschedule or cancel this appointment, please call us at (628) 231-8123 or reply to this email.  

We look forward to seeing you. 

Best regards, 

XYZ 
Study Coordinator 
neuro42, Inc.  
(628) 231-8123 | study@neuro42.com 
www.neuro42.ai 

This email and any attachments thereto may contain private, confidential, and privileged material for the sole use of the intended recipient.  Any review, copying, or distribution of this email (or any attachments thereto) by others is strictly prohibited.  If you are not the intended recipient, please contact the sender immediately and permanently delete the original and any copies of this email and any attachments thereto. `,
}