import React from "react";
import "./LandingContent.css";
import first from "../../Images/MRI2.png";
import card1 from "../../Images/Hospital1.jpg";
import card2 from "../../Images/ImagingCenter1.jpg";
import card3 from "../../Images/IndependentPractice1.jpg";

import {
  FaUserFriends,
  FaClipboardCheck,
  FaHeartbeat,
  FaMoneyBillAlt,
  FaLock,
} from "react-icons/fa";
import Footer from "../../Footer/Footer";
const LandingContent = () => {
  const handleLearnMore = () => {
    window.open("https://neuro42.ai/", "_blank");
  };

  return (
    <React.Fragment>
      <div className="landing-content">
        <div className="landing-content-box-1">
          <h2>Precision for Patients, Confidence for Physicians</h2>
          <div className="box-1">
            <img src={first} alt="neuro42" />
            <div className="box-1-content">
              <p>
                Experience the dual advantages of precision and portability with
                our cutting-edge Portal MRI technology. Enjoy faster scans and
                clearer images, providing both patients and physicians with
                comfort, convenience, and confidence in diagnoses and treatment
                plans. Step into the future of MRI innovation with us.
              </p>
              <button onClick={handleLearnMore}>
                <span>Learn more</span>
                <span>+</span>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="landing-content-box-2">
          <h2>Who we serve</h2>
          <div className="box-2-line">
            <div className="box-2-card">
              <div className="box-2-image-container">
                <img src={card1} alt="Hospitals" />
              </div>
              <h3>Hospitals</h3>
              <p>
                Our portable MRI solution provides hospitals with versatile
                imaging capabilities, ensuring efficient patient care and
                diagnostic accuracy.
              </p>
            </div>
            <div className="box-2-card">
              <div className="box-2-image-container">
                <img src={card2} alt="ImagingCenter" />
              </div>
              <h3>Imaging Center</h3>
              <p>
                Elevate your imaging center's capabilities with our portable MRI
                technology, offering flexibility and precision for a wide range
                of imaging needs.
              </p>
            </div>
            <div className="box-2-card">
              <div className="box-2-image-container">
                <img src={card3} alt="IndependentPractice" />
              </div>
              <h3>Independent Practice</h3>
              <p>
                Empower your independent practice with our portable MRI
                solution, enabling on-demand imaging services for enhanced
                patient care and diagnosis.
              </p>
            </div>
          </div>
          {/* <div className="box-2-line">
            
            <div className="box-2-card">
              <div className="box-2-image-container">
                <img src={card4} alt="StrokeCenter" />
              </div>
              <h3>Stroke Center</h3>
              <p>
                Enhance your stroke center's diagnostic capabilities with our
                portable MRI technology, enabling timely and accurate
                assessments for improved patient outcomes.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="telehealth-benefits">
        <>
          <h2>Telehealth Portal Benefits</h2>
          <h4>
            Transform the way you deliver care with our comprehensive telehealth
            portal. Experience the future of healthcare today.
          </h4>
        </>
        <div className="grid-container">
          <div className="telehealth-benefit">
            <FaUserFriends className="icon" />
            <span>Accessible Care</span>
          </div>
          <div className="telehealth-benefit">
            <FaClipboardCheck className="icon" />
            <span>Efficient Coordination</span>
          </div>
          <div className="telehealth-benefit">
            <FaUserFriends className="icon" />
            <span>Enhanced Accessibility</span>
          </div>
          <div className="telehealth-benefit">
            <FaHeartbeat className="icon" />
            <span>Monitoring Support</span>
          </div>
          <div className="telehealth-benefit">
            <FaMoneyBillAlt className="icon" />
            <span>Patient Engagement</span>
          </div>
          <div className="telehealth-benefit">
            <FaLock className="icon" />
            <span>Secure Compliance</span>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default LandingContent;
