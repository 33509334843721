import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminDashboard.css";
import useIdleTimer from "../../useIdleTimer";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const [operatorNumber, setOperatorNumber] = useState(null);
  const navigate = useNavigate();

  const handleIdle = () => {

    console.log('User is idle, logging out...');
    window.location.href = '/adminPage';
  };

  useIdleTimer(handleIdle, 1.2e+6); // 20 minutes timeout

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("admin_token");
        if (!token) {
          navigate("/adminPage");
        }
        const response = await axios.get("/admin/get_operator_number/");
        setOperatorNumber(response.data.operator_number);
      } catch (error) {
        if (error.response.status === 401) {
          try {
            const refreshResponse = await axios.post("/admin/refresh/", {
            }, { withCredentials: true });

            if (refreshResponse.status === 200) {
              sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
              await fetchData();
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error("Error refreshing token:", error);
          }
        }
      }
    };
    fetchData();
  }, []);

  const handleLogout = async () => {
    await axios.post("/admin/logout/", {
    }, { withCredentials: true });
    sessionStorage.clear();
  };

  return (
    <div className="dashboard-container">
      {operatorNumber !== null && (
        <React.Fragment>
          <div className="dashboard-first-box">
            <div className="infoBox">
              <p className="operatorNumber">{operatorNumber}</p>
              <p className="infoText">Total Number of Operators</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AdminDashboard;
