import React, { useState } from "react";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import brainImage from "../Images/mindy_waving_physician 2.png";
import mindyHello from "../Images/logo.png";
import "./OperatorPatientRegister.css";

const OperatorPatientRegister = () => {
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetchEmailAndAuthenticate(codeResponse);
      setUser(codeResponse);
      console.log("Google login success, codeResponse:", codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const fetchEmailAndAuthenticate = async (user) => {
    if (!user) return;

    try {
      const authResponse = await axios.post("/common/api/verify", {
        token: user.access_token,
      });

      if (authResponse.status === 200) {
        const project = authResponse.data.project;
        const role = authResponse.data.role;
        sessionStorage.setItem("project", project);

        await authenticateAndFetchProfile(user);
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication or profile fetch:", error);
      setError("Authentication or profile fetch failed. Please try again.");
    }
  };

  const authenticateAndFetchProfile = async (user) => {
    if (!user) return;
    const project = sessionStorage.getItem("project");

    try {
      const authResponse = await axios.post(
        "/patient/api/authenticate",
        { token: user.access_token },
        { headers: { project: project } }
      );

      if (authResponse.status === 200) {
        const token = authResponse.data.access_token;
        sessionStorage.setItem("patient_token", token);
        console.log("Patient Data", authResponse.data);

        const profileResponse = await axios.post(
          "/patient/register_patient_google/",
          { email: authResponse.data.patient.email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              project: project,
            },
          }
        );

        if (profileResponse.status === 200) {
          const userObject = profileResponse.data;
          sessionStorage.setItem("userObject", JSON.stringify(userObject));
          navigate(`/`, { state: { userObject } });
        } else {
          throw new Error("Failed to fetch operator profile");
        }
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication or profile fetch:", error);
      setError("Authentication or profile fetch failed. Please try again.");
    }
  };

  return (
    <div className="page-container">
      <Link to="/">
        <div className="logo-patient-register">
          <img src={mindyHello} alt="mindyhello" className="logo-image" />
        </div>
      </Link>

      <div className="content-wrapper">
        <img src={brainImage} alt="Brain character" className="brain-image" />

        <div className="content-container">
          <div className="card">
            <h1>Welcome</h1>
            {/* <h2>You are Invited to register as an operator!</h2> */}
            <ol className="animated-paragraph">
              <li>
                You are invited to register on our platform using Google
                Sign-Up.
              </li>
              <li>Simply click the "Sign Up with Google" button.</li>
              <li>
                Select your Google account, or create one if you don't already
                have it.
              </li>
              <li>
                Allow the necessary permissions for a seamless registration
                experience.
              </li>
            </ol>
            <div className="terms-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                I have read and agree to the{" "}
                <Link
                  to="/termsandconditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </Link>
              </label>
            </div>

            <button
              className="google-signin-button"
              disabled={!isChecked}
              onClick={() => login()}
            >
              <img
                src="https://developers.google.com/identity/images/g-logo.png"
                alt="Google Logo"
                className="google-logo"
              />
              Sign Up with Google
            </button>
          </div>
        </div>
      </div>
      {/* Add two rectangles */}
      <div className=".rectangle-patient-register top-right"></div>
      <div className=".rectangle-patient-register bottom-right"></div>
      <div className=".rectangle-patient-register bottom-circle"></div>
    </div>
  );
};

export default OperatorPatientRegister;
