import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import PatientReimbursement from "./PatientReimbursement";
import PatientProfile from "./PatientProfile";
import PatientUploadFile from "./PatientUploadFile";
import PatientAppointment from "./PatientAppointment";
import "./Patient.styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { ImProfile } from "react-icons/im";
import { SlCalender } from "react-icons/sl";
import { LuFileSearch2 } from "react-icons/lu";
import { RiRefund2Line } from "react-icons/ri";
import { BiLogOutCircle } from "react-icons/bi";

import InternalFooter from "../Footer/InternalFooter";

function PatientLogin({ toggleForm }) {
  const [patient, setPatient] = useState({});
  const [error, setError] = useState("");
  const [selectedContent, setSelectedContent] = useState("profile");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const source = axios.CancelToken.source();
  const { next } = useParams();

  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth < 767);
  };
  useEffect(() => {
    const email = sessionStorage.getItem("user_email");
    handleGetPatient(email);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const handleLogout = async () => {
    await axios.post("/patient/logout/", {}, { withCredentials: true });
    sessionStorage.clear();
    setIsOpen(false);
    navigate("/patients/");
  };

  const getToken = () => {
    const token = sessionStorage.getItem("patient_token");
    return token;
  };

  const handleGetPatient = async (email) => {
    try {
      const token = getToken();
      const response = await axios.get(`/patient/get_patient/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log(response.data);
        setPatient(response.data);
      } else {
        setError("Failed to fetch patient." + response.status);
      }
    } catch (error) {
      if (error.request.status === 401) {
        const refreshResponse = await axios.post(
          "/operator/refresh/",
          {},
          {
            withCredentials: true,
          }
        );

        if (refreshResponse.status === 200) {
          sessionStorage.setItem(
            "patient_token",
            refreshResponse.data.access_token
          );
        } else {
          handleLogout();
        }
      }
    }
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const menuItems = [
    { key: "profile", label: "Profile" },
    { key: "appointment", label: "Appointment" },
    { key: "uploadfile", label: "Upload File" },
    { key: "reimbursement", label: "Reimbursement" },
  ];
  useEffect(() => {
    if (next) {
      switch (next) {
        case "appointment":
          setSelectedContent("appointment");
          break;
        case "uploadfile":
          setSelectedContent("uploadfile");
          break;
        case "reimbursement":
          setSelectedContent("reimbursement");
          break;
        default:
          setSelectedContent("profile");
      }
    } else {
      setSelectedContent("profile");
    }
  }, [next]);
  const handleMenuItemClick = (content) => {
    navigate(`/patient/${content}`);
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Navbar className="mobile-operator-nav">
          <div className="flex-operator-nav">
            <FaBars className="menu-icon" onClick={toggleMenu} />
          </div>
          <Navbar.Collapse className="vertical-operator-nav">
            <Nav className={`navbar-buttons ${isOpen ? "show" : ""}`}>
              {menuItems.map((item) => (
                <Nav.Link
                  key={item.key}
                  active={selectedContent === item.key}
                  onClick={() => {
                    handleMenuItemClick(item.key);
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </Nav.Link>
              ))}
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <Navbar className="operator-nav">
          <Nav className="mr-auto navbar-buttons">
            {menuItems.map((item) => (
              <Nav.Link
                key={item.key}
                active={selectedContent === item.key}
                onClick={() => handleMenuItemClick(item.key)}
              >
                <div className="nav-item">
                  {item.key === "profile" && <ImProfile />}
                  {item.key === "appointment" && <SlCalender />}
                  {item.key === "uploadfile" && <LuFileSearch2 />}
                  {item.key === "reimbursement" && <RiRefund2Line />}
                  {item.label}
                </div>
              </Nav.Link>
            ))}
          </Nav>

          <Nav className="navbar-buttons-logout">
            <div className="operator-profile"></div>
            <div className="white-line"></div>
            <Nav.Link onClick={handleLogout}>
              <div className="nav-item">
                <BiLogOutCircle />
                Logout
              </div>
            </Nav.Link>
          </Nav>
        </Navbar>
      )}

      <div className="content">
        {selectedContent === "profile" && <PatientProfile patient={patient} />}
        {selectedContent === "uploadfile" && (
          <PatientUploadFile patient={patient} />
        )}
        {selectedContent === "appointment" && (
          <PatientAppointment patient={patient} />
        )}
        {selectedContent === "reimbursement" && (
          <PatientReimbursement patient={patient} />
        )}
      </div>
      <InternalFooter />
    </React.Fragment>
  );
}

export default PatientLogin;
